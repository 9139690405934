import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import moment from 'moment';
import { Moment, MomentInput } from 'moment';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { CarrierPipe } from 'app/history/pipes/carrier.pipe';
import { ServicesPipe } from 'app/history/pipes/services.pipe';
import { ClaimStatusPipe } from 'app/history/pipes/claim-status.pipe'
import { AppState } from '../../app.state';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { Package } from '../../ship/models/package.model';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { NgxResponsiveTableConfig } from '../../shared/components/ngx-responsive-table/models/ngx-responsive-table-config';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { TranslateService } from '@ngx-translate/core';
import { ShipmentHistoryService } from 'app/history/services/shipment-history.service';
import { PaginationInstance } from 'ngx-pagination';
import { UserService } from '../../shared/services/user/user.service';
import { User } from '../../shared/services/user/models/user.model';
import { SharedService } from '../../core/services/shared.service';
import * as _ from 'lodash';
import { SiteConfig } from '../../../config/site-config';
import { EmailShippingLabelService } from 'app/drop-shipment/services/email-shipping-label.service';


@Component({
  selector: 'upsc-claims-dashboard',
  templateUrl: './claims-dashboard.component.html',
  styleUrls: ['./claims-dashboard.component.scss']
})
export class ClaimsDashboardComponent implements OnInit {
  claimStatusList = [
    {
      code1: 1,
      valeur1: 'Claim Received',
    },
    {
      code1: 2,
      valeur1: 'Missing Documents ',
    },
    {
      code1: 3,
      valeur1: 'Investigation Pending',
    },
    {
      code1: 4,
      valeur1: 'Denied',
    },
    {
      code1: 5,
      valeur1: 'Approved for Payment',
    },
    {
      code1: 6,
      valeur1: 'Paid',
    },
    {
      code1: 7,
      valeur1: 'Canceled',
    },
    {
      code1: 8,
      valeur1: 'Closed',
    }
  ];
 
  @ViewChild('fileControl1', { static: true }) fileControl1: ElementRef;
  @ViewChild('fileControl2', { static: true }) fileControl2: ElementRef;

  public dateRangeFormGroup: UntypedFormGroup;
  public customDateRangeFormGroup: UntypedFormGroup;
  public searchFormGroup: UntypedFormGroup;
  public amountRangeFormGroup: UntypedFormGroup;
  public claimStatusFormGroup: UntypedFormGroup;
  public user: User;
  public data: any[];
  public displayedShipments: Package[];
  public isSearching = false;
  public searchMode = false;
public statusError = false;
  public amountSubmitted = false;
  public statusSubmitted = false;
  public selectedRange = false;
  public selectedStatus = false;
  public showRangePanel = false;
  public showStatusPanel = true;
  public showAdvancePanel = false;
  private parameterDateFormat = 'YYYY-MM-DD';
  private currentPredefinedDateRange = 7;
  public predefinedDayRanges = [1, 7, 15, 30, 60];
  public searchByOptions = ['Tracking Number', 'Paid Amount', 'Claim Number'];
  public dateRangeAll = 0;
  public dateRangeCustom = -1;
  public tablePageSize = 25;
  public tablePageIndex = 1;
  
  public Maximum = 0;
  public Minimum = 0;
  public digitPattern = /^[0-9]{1,9}(\.[0-9][0-9])?$/;
  public isGreaterAmount = false;
  public userCurrencyCode = 'USD';
  public defaultStartDate = moment().subtract(7, 'days').startOf('day').toDate();
  public defaultEndDate = moment().toDate();
  public formattedStartDate = moment().subtract(7, 'days').startOf('day').format('MM/DD/YYYY');
  public formattedEndDate = moment().format('MM/DD/YYYY');
  public dtOptions: any = {};
  public tableConfig: NgxResponsiveTableConfig;
  public paginate: PaginationInstance;
  private totalItems = 0;
  public dtTrigger: Subject<any> = new Subject<any>();
  private searchShipmentsSubscription: Subscription;
  private getShipmentHistorySubscription: Subscription;
  public formData: FormData = new FormData();
  public uploadedFile1: string;
  public uploadedFile2: string;
  public uploadedFile3: string;
  public uploadedFile4: string;

  public uploadedImage1: string;
  public uploadedImage2: string;
  public uploadedImage3: string;
  public uploadedImage4: string;
  public uploadedImage5: string;
  public uploadedImage6: string;
  assetURL: any = [];
  fileNames: any = [];
  public isInProgress = false;
  clearFileControl = false;
  public uploadShow = false;
  public allowedFileTypes = ['jpg', 'pdf', 'docx', 'xls'];
  fileTypes = '*/*';
  isMultiple = false;
  isDisabled = false;

  public constructor(private formBuilder: UntypedFormBuilder, public shipmentservice: ShipmentHistoryService,
                     private notificationService: NotificationService, private utilityService: UtilityService,
                     private carrierPipe: CarrierPipe,
                     private servicesPipe: ServicesPipe,
                     private claimStatusPipe: ClaimStatusPipe,
                     private decimalPipe: DecimalPipe,
                     private userService: UserService,
                     private sharedService: SharedService,
                     private currencyPipe: CurrencyPipe,
                     private translateService: TranslateService,
                     private emailShippingLabelService: EmailShippingLabelService,
                     private errorHandlerService: ErrorHandlerService,
                     private readonly appState: AppState,
  ) {
    this.user = this.appState.user$();

    // [MV3-1695] Display currency based on the user name.
    switch (true) {
      case this.userService.isDEUser(this.user):
        this.userCurrencyCode = 'EUR';
        break;
      case this.userService.isGBUser(this.user):
        this.userCurrencyCode = 'GBP';
        break;
      default:
        this.userCurrencyCode = 'USD';
        break;
    }
  }

  public ngOnInit() {
    this.dtOptions = SiteConfig.tableConfig;
    this.dateRangeFormGroup = this.formBuilder.group({
      dayCount: [this.currentPredefinedDateRange],
    });
    this.searchFormGroup = this.formBuilder.group({
      searchBy: [],
      keyword: []
    });
    this.amountRangeFormGroup = this.formBuilder.group({
      amountStart: [0.00, [Validators.required, Validators.pattern(this.digitPattern)]],
      amountEnd: [0.00, [Validators.required, Validators.pattern(this.digitPattern)]]
    });
    this.claimStatusFormGroup = this.formBuilder.group({
      claimStatus: [],
    });
    
    this.dateRangeFormGroup.controls.dayCount.valueChanges
      .subscribe(
        (value) => {
          this.currentPredefinedDateRange = +value;
          this.tablePageIndex = 1;

          if (+value === -1) {
            return;
          }

          this.onDateRangeChanged(+value);
        },
      );

    this.customDateRangeFormGroup = this.formBuilder.group({
      startDate: [this.defaultStartDate],
      endDate: [this.defaultEndDate],
    });

    this.customDateRangeFormGroup.controls.startDate.valueChanges
      .subscribe(
        (value) => {
          this.formattedStartDate = moment(value).format('MM/DD/YYYY');

          if (!this.customDateRangeFormGroup.controls.endDate.value) {
            return;
          }

          this.dateRangeFormGroup.controls.dayCount.setValue(-1);
          this.onCustomDateRangeChanged(value, this.customDateRangeFormGroup.controls.endDate.value);
        },
      );

    this.customDateRangeFormGroup.controls.endDate.valueChanges
      .subscribe(
        (value) => {
          this.formattedEndDate = moment(value).format('MM/DD/YYYY');

          if (!this.customDateRangeFormGroup.controls.startDate.value) {
            return;
          }

          this.dateRangeFormGroup.controls.dayCount.setValue(-1);
          this.onCustomDateRangeChanged(this.customDateRangeFormGroup.controls.startDate.value, value);
        },
      );

    this.updatePaginateConfig();
  }

  public showAdvanceSearch(){
    this.showAdvancePanel = !this.showAdvancePanel;
  }
  GetData(index: string, fromDate?: string, toDate?: string) {
    this.utilityService.clearSubscriptions([this.getShipmentHistorySubscription]);

    this.isSearching = true;
    this.searchMode = false;
    this.getShipmentHistorySubscription = this.shipmentservice.getShipmentHistory(index, this.tablePageSize.toString(), fromDate, toDate)
      .subscribe(
        (packages) => {
          this.data = packages;
          this.displayedShipments = <any[]>_.cloneDeep(packages);
          console.log("this.displayedShipments", this.displayedShipments)
          // [MV3-1670] Stop setting filters as they are used for an API request.
          // this.dateRangeFormGroup.controls.dayCount.setValue(this.currentPredefinedDateRange);

          if (!this.data || !this.data.length) {
            console.log("!!!!!!!!this.displayedShipments")
            this.displayedShipments = [];
            this.dtTrigger.next(null);
            this.isSearching = false;

            return;
          }

          if (this.currentPredefinedDateRange === 0) {
            const clonedData = _.cloneDeep(this.data);
            const dateRangeStart = this.utilityService.getMinMoment(clonedData.map(item => item.ShipDate));
            const dateRangeEnd = this.utilityService.getMaxMoment(clonedData.map(item => item.ShipDate));

            this.customDateRangeFormGroup.patchValue(
              {
                startDate: dateRangeStart.toDate(),
                endDate: dateRangeEnd.toDate(),
              },
              { emitEvent: false });

            this.formattedStartDate = dateRangeStart.format('MM/DD/YYYY');
            this.formattedEndDate = dateRangeEnd.format('MM/DD/YYYY');
          }

          this.totalItems = +packages[0].TotalShipments;
          this.tablePageIndex = +index;
          this.updatePaginateConfig();

          this.dtTrigger.next(null);
          this.isSearching = false;
        },
        (error) => {
          this.dtTrigger.next(null);
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(error),
            this.translateService.instant('ErrorLoadingShipmentHistory'),
            NotificationType.ERROR);
          this.isSearching = false;
        });
  }
  public onChange(event: any): void {
    if (this.Maximum < this.Minimum) {
      this.isGreaterAmount = true;
      console.log('Incorrect');      
    }
}
public addDocument(value){
  this.uploadShow = true;
console.log("valuevaluevalue",value)
}

public uploadFiles(files: FileList, id) {
  console.log("files", files)
  console.log("event", id)
  let uploadId = id;
  if (!files || !files.length) {
    return;
  }

  const file: File = files[0];
  if (!this.validateFile(file)) {
    return;
  }

  // this.formData.append('uploadFile', file, file.name);


  switch (uploadId) {
    case 'invoice':
      this.formData.append('uploadFile1', file, file.name);
      this.fileNames.push(file.name)
      this.uploadedFile1 = file.name;
      break;
    case 'photograph':
      this.formData.append('uploadFile2', file, file.name);
      this.fileNames.push(file.name)
      this.uploadedFile2 = file.name;
      break;
    case 'estimate':
      this.formData.append('uploadFile3', file, file.name);
      this.fileNames.push(file.name)
      this.uploadedFile3 = file.name;
      break;
    case 'other':
      this.formData.append('uploadFile4', file, file.name);
      this.fileNames.push(file.name)
      this.uploadedFile4 = file.name;
      break;

  }
  // this.uploadedFile1 = file.name;
  this.uploadAttachment();
}

removeFile(id) {

  switch (id) {
    case 'invoice':
      this.uploadedFile1 = '';
      this.formData = new FormData();
      break;
    case 'photograph':
      this.uploadedFile2 = '';
      this.formData = new FormData();
      break;
    case 'estimate':
      this.uploadedFile3 = '';
      this.formData = new FormData();
      break;
    case 'other':
      this.uploadedFile4 = '';
      this.formData = new FormData();
      break;
    case 'label':
      this.uploadedImage1 = '';
      this.formData = new FormData();
      break;
    case 'sides':
      this.uploadedImage2 = '';
      this.formData = new FormData();
      break;
    case 'Bottom':
      this.uploadedImage3 = '';
      this.formData = new FormData();
      break;
    case 'Damaged':
      this.uploadedImage4 = '';
      this.formData = new FormData();
      break;
    case 'outer':
      this.uploadedImage5 = '';
      this.formData = new FormData();
      break;
    case 'inner':
      this.uploadedImage6 = '';
      this.formData = new FormData();
      break;
  }
}
public onFileSelectionChanged(event, id) {
  console.log("vivekkkkk", event)
  const files = event.target.files;
  let imageId = id
  if (!files || files.length === 0) {
    console.log("files emouyuy", files)
    return;
  }
  console.log('imageid', imageId)
  //this.fileSelectionChanged.emit(files);
  this.uploadImage(files, imageId)
  this.clearFileControl = true;
  this.clearFileSelection();
}
public uploadImage(files: FileList, id) {
  console.log("files", files)
  console.log('id', id)
  let uploadId = id;
  if (!files || !files.length) {
    return;
  }

  const file: File = files[0];
  if (!this.validateFile(file)) {
    return;
  }

  switch (uploadId) {
    case 'label':
      this.formData.append('uploadImage1', file, file.name);
      this.uploadedImage1 = file.name;
      console.log("label+++++++++++1")
      break;
    case 'sides':
      this.formData.append('uploadImage2', file, file.name);
      this.uploadedImage2 = file.name;
      console.log("label+++++++++++++2+")
      break;
    case 'Bottom':
      this.formData.append('uploadImage3', file, file.name);
      this.uploadedImage3 = file.name;
      console.log("label++++++++++++++3")
      break;
    case 'Damaged':
      this.formData.append('uploadImage4', file, file.name);
      this.uploadedImage4 = file.name;
      console.log("label++++++++++++++")
      break;
    case 'outer':
      this.formData.append('uploadImage5', file, file.name);
      this.uploadedImage5 = file.name;
      console.log("label++++++++++++++4")
      break;
    case 'inner':
      this.formData.append('uploadImage6', file, file.name);
      this.uploadedImage6 = file.name;
      console.log("label++++++++++++++5")
      break;
  }

  this.uploadAttachment();
}
private clearFileSelection() {
  if (this.clearFileControl) {
    console.log('this.clearFileControl', this.clearFileControl)
    this.fileControl1.nativeElement.value = '';
    this.fileControl2.nativeElement.value = '';
  }
}
uploadAttachment() {
  this.isInProgress = true;

  //  if (this.uploadedFile1  ) { // Only if there is file to upload
  this.emailShippingLabelService.uploadFiles(this.formData).subscribe(
    (response) => {
      this.assetURL.push(response);
      console.log('this.assetURll-----', this.assetURL)
    }, (error) => {
      this.notificationService.notify(
        this.errorHandlerService.getHttpErrorMessage(error),
        this.translateService.instant('uploadFailed'),
        NotificationType.ERROR);
      this.isInProgress = false;

    });
  //  } 

}
validateFile(file: File): boolean {
  var fileExtension = file.name.split('.').pop();
  if (file.size > 5242880) {
    this.notificationService.notify(this.translateService.instant('dropShipmentEmail.maxFileSize'), this.translateService.instant('dropShipmentEmail.fileSizeExceeded'), NotificationType.ERROR);
  }
  else if (!this.isInArray(this.allowedFileTypes, fileExtension)) {
    this.notificationService.notify(this.translateService.instant('dropShipmentEmail.fileFormatNotAllowed'), this.translateService.instant('invalidFile'), NotificationType.ERROR);
  }
  else return true;
}
isInArray(array, word) {
  return array.indexOf(word.toLowerCase()) > -1;
}
  getRowColor(item) {
    if (item.Status == 1) {
      return "#426DA9";
    } else if (item.Status === 2) {
      return "#0E2554";
    } else if (item.Status === 3) {
      return "#C91A13";
    } else if (item.Status === 4) {
      return "#FEC704";
    } else if (item.Status == 5) {
      return "#757575";
    } else if (item.Status == 6) {
      return "#6E7E1B";
    } else if (item.Status == 7) {
      return "#910048";
    } else if (item.Status == 8) {
      return "#000000";
    }
  }
  getWidth(item) {
    if (item.Status === 1) {
      return "116px";
    } else if (item.Status === 2) {
      return "138px";
    } else if (item.Status === 3) {
      return "136px";
    } else if (item.Status === 4) {
      return "136px";
    } else if (item.Status === 5) {
      return "70px";
    } else if (item.Status === 6) {
      return "53px";
    } else if (item.Status === 7) {
      return "77px";
    } else if (item.Status === 8) {
      return "64px";
    }

  }

  public onAmountRangeSubmit(event, form) {
    event.preventDefault();
    if(this.amountRangeFormGroup?.valid){ 
      this.amountSubmitted = true;
      this.showRangePanel = false;
      this.selectedRange = true;
     }
  
  }
  public onAmountRangeCancel(event) {
    event.preventDefault();
    this.amountRangeFormGroup.reset();
   this.showRangePanel = false;
  }
  public onAmountRangeClear() {
    this.selectedRange = false;
    this.amountSubmitted = false;
    this.amountRangeFormGroup.reset();
  }
  public onStatusClear(){
   this.selectedStatus = false;
    this.showStatusPanel = true;
    this.statusSubmitted = false;
    this.statusError =false;
    this.claimStatusFormGroup.reset();
  }
  public onStatusDone(event, form){
    event.preventDefault();
    if(!this.claimStatusFormGroup?.value.claimStatus){    
      this.statusError =true;
    }
    else{
      this.selectedStatus = true;
      this.showStatusPanel = false;
      this.statusSubmitted = true;
      this.statusError =false;
    }
 
  }
  public onStatusCancel(event){
    this.claimStatusFormGroup.reset();
    this.showStatusPanel = true;
    this.statusSubmitted = false;
  }
 
  public onSearch(event){

  }
  public onReset(event){
    this.amountRangeFormGroup.reset();
    this.claimStatusFormGroup.reset();
    this.onAmountRangeClear();
    this.onStatusClear();
  }
  public onPageChanged(pageNumber: number) {
    this.tablePageIndex = pageNumber;
    if (!this.searchMode)
      this.onDateRangeChanged(this.currentPredefinedDateRange);
    else this.searchShipments(this.searchFormGroup.controls.keyword.value, this.tablePageIndex.toString());
  }
  public openRangePanel() {
    this.showRangePanel = !this.showRangePanel;
    this.amountSubmitted = false;
  }

  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.tablePageIndex,
      itemsPerPage: this.tablePageSize,
    };
  }

  public onCustomDateRangeChanged(startDateString: MomentInput, endDateString: MomentInput) {
    const dateRangeStart = moment(startDateString);
    const dateRangeEnd = moment(endDateString);

    // [MV3-1670] Use the date range filters as API request parameters.
    this.GetData(
      this.tablePageIndex.toString(),
      dateRangeStart.startOf('day').format(this.parameterDateFormat),
      dateRangeEnd.startOf('day').format(this.parameterDateFormat));

    this.dtTrigger.next(null);
  }
  public onDateRangeChanged(dayCount: number) {
    let dateRangeStart = moment().subtract(dayCount, 'days').startOf('day');
    let dateRangeEnd = moment().add(7, 'days').startOf('day');

    switch (dayCount) {
      case -1:
        dateRangeStart = moment(this.customDateRangeFormGroup.controls.startDate.value).startOf('day');
        dateRangeEnd = moment(this.customDateRangeFormGroup.controls.endDate.value).endOf('day');

        break;
      default:
        break;
    }

    if (![-1, 0].includes(dayCount)) {
      this.setCustomDateRangeValues(dateRangeStart, dateRangeEnd, false);
    }

    switch (dayCount) {
      case 0:
        this.GetData(this.tablePageIndex.toString());

        break;
      default:
        this.onCustomDateRangeChanged(dateRangeStart, dateRangeEnd);

        break;
    }
  }
  private setCustomDateRangeValues(dateRangeStart: Moment, dateRangeEnd: Moment, isEmitEvent = false) {
    this.customDateRangeFormGroup.patchValue(
      {
        startDate: dateRangeStart.toDate(),
        endDate: dateRangeEnd.toDate(),
      },
      {
        emitEvent: isEmitEvent,
      });

    this.formattedStartDate = dateRangeStart.format('MM/DD/YYYY');
    this.formattedEndDate = dateRangeEnd.format('MM/DD/YYYY');
  }

  public onSearchSubmit(event, form) {
    event.preventDefault();

    this.searchShipments(form.keyword, '1');
  }

  private searchShipments(keyword: string, index: string) {
    if (!keyword) {
      this.tablePageIndex = 1; // resetting the grid
      this.onDateRangeChanged(this.currentPredefinedDateRange);
      return;
    }

    this.isSearching = true;
    this.searchMode = true;
    this.utilityService.clearSubscriptions([this.searchShipmentsSubscription]);
    this.searchShipmentsSubscription = this.shipmentservice.searchShipments(keyword, index)
      .subscribe(
        shipments => this.handleSearchShipmentsSuccess(shipments, index),
        err => this.handleSearchShipmentsFailure(err),
      );
  }

  private handleSearchShipmentsSuccess(shipments: Package[], index: string) {
    this.data = shipments;
    this.displayedShipments = _.cloneDeep(this.data);

    if (!this.data || !this.data.length) {
      this.dtTrigger.next(null);
      this.isSearching = false;
      this.totalItems = 0;
      this.updatePaginateConfig();

      return;
    }

    this.totalItems = +this.data[0].TotalCount;
    this.tablePageIndex = +index;
    this.updatePaginateConfig();

    this.dtTrigger.next(null);
    this.isSearching = false;
  }

  private handleSearchShipmentsFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      this.translateService.instant('FailedSearchingShipments'),
      NotificationType.ERROR);
    this.isSearching = false;
  }

}
