import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimsComponent } from './claims.component';
import { ClaimFaqComponent } from './claim-faq/claim-faq.component';
import { SharedModule } from 'app/shared/shared.module';
import { AngularMaterialModule } from 'app/shared/angular-material.module';
import { FileClaimComponent } from './file-claim/file-claim.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PackageDetailsComponent } from './file-claim/package-details/package-details.component';
import { Merchandise } from './file-claim/package-details/models/merchandise.model.ts';
import { ReviewClaimsComponent } from './file-claim/review-claims/review-claims.component';
import { ConfirmationDialogComponent } from './file-claim/review-claims/confirmation-dialog/confirmation-dialog.component';
import { ClaimsService } from './services/claims.service';
import { ClaimsDashboardComponent } from './claims-dashboard/claims-dashboard.component';
import { UserLocaleNumberModule } from '../shared/pipes/user-locale-number/user-locale-number.module';
import { claimsStatusModule } from '../shared/pipes/claim-status/claims-status.module';
import { UserCurrencyModule } from '../shared/pipes/user-currency/user-currency.module';
import { UserCurrencyPipe } from '../shared/pipes/user-currency/user-currency.pipe';
import { CustomDatePipe } from 'app/history/report-history/datepipe.pipe';
import { ShipmentStatusPipe } from 'app/history/pipes/shipment-status.pipe';

Merchandise
@NgModule({
  declarations: [ClaimsComponent, ClaimFaqComponent, FileClaimComponent, PackageDetailsComponent,
     ReviewClaimsComponent, ConfirmationDialogComponent, ClaimsDashboardComponent
    ],
  imports: [
    CommonModule,claimsStatusModule,
    SharedModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,UserCurrencyModule,
    UserLocaleNumberModule,
    
  ],
  providers:[Merchandise,ClaimsService, UserCurrencyPipe,CustomDatePipe,ShipmentStatusPipe],
  exports: []
})
export class ClaimsModule { }
