export class ClaimsDetails {
    TrackingNumber = '';
    ShipmentID = '';
    ShipDate = '';
    Lossdate = '';
    Losscause = '';
    InsuredValue = '';
    Carrier = '';
    PackageIssue = '';
    PackageDescription = '';
    RecipientContacted = '';
    PackageCurrentLocation = '';
    IncidentNotes = '';
    Currency = '' ;
    TotalClaimedAmount = '';
}