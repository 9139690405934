<upsc-page-header [pageTitle]="'Claims FAQs'"></upsc-page-header>


<mat-expansion-panel>
    <mat-expansion-panel-header>
    <mat-panel-title>
        How Does the Claim Process Work?
    </mat-panel-title>
   
  </mat-expansion-panel-header>    
    <ul>
    <li>Let’s take the stress out of shipping claims.  Here’s how:</li>
    <li>Log In to The Parcel Pro Platform</li>
    <li>Follow your normal process for logging into the platform, go to “Claims,” then enter your <br>
        tracking number on the search bar, then click on “File a Claim.”</li>
    <li>Provide Some Details About the Package</li>
    <li>Tell us about the package, including the contents and the value of the goods.</li>
    <li>Support Your Claim With Additional Documentation</li>
    <li>Items such as receipts, invoices, and purchase orders can help support your claim. Include <br>
         photos for any damage or missing contents claim.</li>
    <li>Submit your Claim</li>
    <li>Submit your claim and the team will get to work!</li>
    </ul>     
</mat-expansion-panel> 

<mat-expansion-panel>
    <mat-expansion-panel-header>
    <mat-panel-title>
        How long does the claim process take?
    </mat-panel-title>
   
  </mat-expansion-panel-header>    
 
    <ul>
    <li>The process should go quickly if you have submitted your required documentation.  If additional <br>
        information is needed, a member of the team will notify you. Once your claim has been approved for<br>
        payment, it will be paid promptly.</li>
    </ul>     
</mat-expansion-panel>

<mat-expansion-panel>
    <mat-expansion-panel-header>
    <mat-panel-title>
        What documents are needed to support my claim?
    </mat-panel-title>
  
  </mat-expansion-panel-header>  
  <h3>To process your claim timely, you will need to provide:</h3>  
    <ul>
    <li>A copy of your invoice or memo</li>
    <li>Photos of the shipment, packaging, and label (for loss of contents and damages)</li>
    <li>Any supporting documents to help with your claim, some examples could be video footage,<br>
         police report, and more.</li>
    <li>If a missing content claim or damage, the original carton, its contents, and all packaging<br>
         materials must be retained until the claim has been finalized and the adjuster has stated they<br>
         may be disposed</li>              
    </ul>     
</mat-expansion-panel>

<mat-expansion-panel>
    <mat-expansion-panel-header >
    <mat-panel-title>
        Do I need to retain the packaging?
     
    </mat-panel-title>
  </mat-expansion-panel-header>    
    <ul>
    <li>If there is a damage or missing contents claim you must retain the packaging until you are<br>
         advised otherwise by your adjuster. </li>
    </ul>     
</mat-expansion-panel>

<mat-expansion-panel>
    <mat-expansion-panel-header>
    <mat-panel-title>
        When should I report a claim?
    </mat-panel-title>
  </mat-expansion-panel-header>    
    <ul>
    <li>If you experience a loss or damage, you must file a claim as soon as possible to help with the potential<br>
         recovery of the goods.</li>
    </ul>     
</mat-expansion-panel>

<mat-expansion-panel>
    <mat-expansion-panel-header>
    <mat-panel-title>
       How can I check the status of my claim?1
    </mat-panel-title>
  </mat-expansion-panel-header>    
    <ul>
    <li>Log into the Parcel Pro platform and click the “Claims” tab, then click “Claims Status”. Alternatively,<br>
         you can contact our claims support team at 877-225-7625 or via email at <a href="mailto:parcelproclaims@ups.com">parcelproclaims&#64;ups.com</a> </li>
    </ul>     
</mat-expansion-panel>

