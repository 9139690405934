<div class="reset-password">
    <div class="reset-password-wrapper">
        <div class="page-content-items">
            <div class="logo">
                <upsc-logo></upsc-logo>
            </div>
            <h2 *ngIf="!success" >{{'resetPassword.updatePassword'|translate}}</h2>
            <form class="reset-form" [formGroup]="formGroup" (ngSubmit)="onSubmit($event,formGroup.value)" *ngIf="!success" >
                <mat-form-field>
                    <input type="password" required matInput formControlName="password" (focus)="passwordFocus()" (focusout)="passwordFocusOut()"
                        placeholder="{{ 'resetPassword.newPassword' | translate }}">
                    <img src="../../../assets/icons/tick.png" class="valid-password" *ngIf="isPasswordValid" />
                    <span class="validation-message" *ngIf="validationMessages">
                        <span class="heading">{{ 'resetPassword.passwordRequirements.title' | translate }}</span>
                        <ul>
                            <li [style.list-style-image]="alpha">{{ 'resetPassword.passwordRequirements.alphabet' | translate }}</li>
                            <li [style.list-style-image]="numeric">{{ 'resetPassword.passwordRequirements.number' | translate }}</li>
                            <li [style.list-style-image]="length">{{ 'resetPassword.passwordRequirements.length' | translate }}</li>
                            <li [style.list-style-image]="blank">{{ 'resetPassword.passwordRequirements.noBlank' | translate }}</li>
                        </ul>
                    </span>
                    <mat-error *ngIf="formGroup.controls.password.errors">
                        <span *ngIf="formGroup.controls.password.errors?.required">
                            {{ 'resetPassword.passwordRequired' | translate }}
                        </span>                        
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input type="password" required matInput formControlName="confirmPassword" placeholder="{{ 'ConfirmPassword' | translate }}">
                    <img src="../../../assets/icons/tick.png" class="valid-password" *ngIf="isConfirmPasswordValid" />
                    <mat-error *ngIf="formGroup.controls.confirmPassword.errors">
                        <span *ngIf="formGroup.controls.confirmPassword.errors?.required">
                            {{ 'resetPassword.confirmPasswordRequired' | translate }}
                        </span>
                        <span *ngIf="formGroup.controls.confirmPassword.errors?.mustMatch">
                                {{ 'resetPassword.passwordMisMatch' | translate }}
                        </span>
                    </mat-error>
                </mat-form-field>
                <button mat-raised-button type="submit" color="primary">
                    {{'resetPassword.changePassword'|translate}}
                </button>
            </form>
            <div class="success" *ngIf="success" >
                <h2>
                    {{ 'resetPassword.passwordReset' | translate }}
                </h2>
                <button mat-raised-button type="button" color="primary" (click)="BackToLogin()">
                    {{ 'SignIn' | translate }}
                </button>
            </div>
            <div class="trouble">
                <span [innerHtml]="'SignInPage.Trouble' | translate"></span>
                <span [innerHtml]="'SignInPage.TroubleSolution' | translate: { tel: supportTel }"></span>
            </div>
        </div>
    </div>
</div>
