import { Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
@Component({
  selector: 'upsc-claim-faq',
  templateUrl: './claim-faq.component.html',
  styleUrls: ['./claim-faq.component.scss']
})
export class ClaimFaqComponent {

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor() { }

  panelOpenState = false;

}
