<div class="file-claim">
    <div class="file-claim-wrapper">
        <div class="page-content">           
                    <div class="content">                        
                        <h1 class="page-title">Start a Claim</h1>
                        <div class="line-border"></div>
                        <div class="claimStatus">
                            <p>Looking for an existing claim <a href="">View Claim Status</a></p>
                        </div>
                        <h4 class="section">Section 1 of 3</h4>
                        <div class="notePad">
                            <div class="letterPad">
                                <span><img class="icon" src="../../../assets/icons/Note-White-icon.png"
                                        alt="letter pad"></span>
                                <h4>
                                    What you should know?
                                    <button (click)="popup()" class="dropdown-toggle">
                                        <span class="sr-only">Toggle Dropdown</span>
                                    </button>
                                </h4>

                            </div>
                            <div class="notes" *ngIf="showPopup">
                                <ul>
                                    <li>
                                        Need to request a Guaranteed Service Refund for late delivery,<br>
                                        please contact Parcel Pro Customer Service
                                    </li>
                                    <li>
                                        For claims regrading damage or missing contents, retain the<br>
                                        merchandise and original packaging until the claim is resolved
                                    </li>
                                    <li>
                                        Visit our <a routerLink="/claim-faq">FAQ</a> section, where you will find
                                        answers to fequently<br>
                                        asked questions and helpful resources regarding the Parcel Pro<br> Claims
                                        process
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- search box  -->
                        <div class="content-actions">
                            <h4 class="section">Tracking Number *</h4>
                            <div class="search-actions">
                                <form [formGroup]="searchFormGroup"
                                    (ngSubmit)="onSearchSubmit($event, searchFormGroup.value)">
                                    <mat-form-field class="searchBox-content" style="width:350px">
                                        <input matInput type="text" [(ngModel)]="TrackingNumber"
                                            placeholder="Enter last 4 digits or full tracking number"
                                            formControlName="keyword">
                                        <mat-icon *ngIf="TrackingNumber.length == 0" matSuffix
                                            (click)="onSearchSubmit($event, searchFormGroup.value)"
                                            class="search">search</mat-icon>

                                    </mat-form-field>
                                    <span> <img *ngIf="TrackingNumber.length != 0" class="doneIcon"
                                            src="../../../assets/icons/tick.png" alt="tick"></span>
                                </form>
                            </div>
                            <div *ngIf="TrackingNumber.length == 0">
                                <mat-radio-group class="example-radio-group" [(ngModel)]="TrackingNumber">
                                    <mat-radio-button class="example-radio-button" *ngFor="let number of numbers"
                                        [value]="number">
                                        {{number}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="StatusPad">
                                <div class="statusDescription">
                                    <span><img class="tickIcon" src="../../../assets/icons/tick.png" alt="tick"></span>
                                    <h4 class="deliveryStatus">
                                        {{ deliveryStatus }}
                                    </h4>

                                    <h5 class="dateTime">Tuesday, Aug 02 at 12:10 AM at Reception</h5>
                                    <div class="dateTime-line"></div>
                                </div>
                            </div>
                            <div class="DeliveryDetails" style="font-weight: 600;">
                                <div class="DeliveredDetails">
                                    <h5 class="deliveredfont">Delivered To:</h5>
                                    <h4 class="deliveredfont">TAMPA, FL US</h4>
                                </div>
                                <div class="details">
                                    <h5 class="deliveredfont">Received By:</h5>
                                    <h4 class="deliveredfont">Madison</h4>
                                </div>
                            </div>
                        </div>
                        <div class="ClaimantContent">
                            <h3 class="claimInfo">Claimant Contact Info:</h3>
                            <ng-container [ngTemplateOutlet]="ClaimantInfo"></ng-container>
                        </div>

                        <div class="ShipmentDetail">
                            <h3 class="shipDetail">Shipment Detail</h3>
                            <div class="shipmentSection">
                                <h4 class="section">Transportation Carrier</h4>
                                <div class="info-box">
                                    <h5 class="shipinfo">Transportation Carrier *</h5>
                                    <h3 class="shipmentValue">UPS</h3>
                                </div>
                                <h4 class="section">Ship Date</h4>
                                <div class="info-box">
                                    <h5 class="shipinfo">Ship Date *</h5>
                                    <h3 class="shipmentValue">9/8/2022</h3>
                                </div>
                                <h4 class="section">Insured Value</h4>
                                <div class="info-box">
                                    <h5 class="shipinfo">Insured value *</h5>
                                    <h3 class="shipmentValue">$ 90000.00</h3>
                                </div>
                            </div>
                        </div>
                        <div id="shipperInfo" class="ContactInformation">
                            <div class="ShipContactInfo">
                                <h3 class="shipContact">Shipper’s Contact Information </h3>
                                <ng-container [ngTemplateOutlet]="SendersInfo"></ng-container>
                                <!-- <h5 class="contactInfo">Jane Customer</h5>
                <h5 class="contactInfo">Happy Company</h5>
                <h5 class="contactInfo">1867 Western Way</h5>
                <h5 class="contactInfo">Torrance, CA</h5>
                <h5 class="contactInfo">90501, US</h5>
                <h5 class="contactInfo">3134566628</h5>
                <h5 class="contactInfo">test@test.com</h5>                 -->
                            </div>
                            <div id="receiverInfo" class="ReceiverContactInfo">
                                <h3 class="receiveContact">Receiver’s Contact Information </h3>
                                <ng-container [ngTemplateOutlet]="ReceiversInfo"></ng-container>
                                <!-- <h5 class="contactInfo">Jane Customer</h5>
                <h5 class="contactInfo">Happy. Company</h5>
                <h5 class="contactInfo">1867 Western Way</h5>
                <h5 class="contactInfo">Torrance, CA</h5>
                <h5 class="contactInfo">90501, US</h5>
                <h5 class="contactInfo">3134566628</h5>
                <h5 class="contactInfo">test@test.com</h5>                 -->
                            </div>
                        </div>
                    </div>               
        </div>
    </div>
</div>
<upsc-package-details [claimentGroup]="claimentFormValue"
[shippersGroup]="shippersFormValue"
[receiversGroup]="receiversFormValue"
[trackingNumber]="TrackingNumber"
(resetForms)="resetForms()"></upsc-package-details>



<ng-template #ClaimantInfo>
    <div class="upsc-address">
        <form class="ups-form" [formGroup]="claimentformGroup">
            <div class="form-body">
                <div class="form-sections">
                    <div class="form-section">
                        <mat-form-field>
                            <input matInput type="text" name="account number" maxlength="15"
                                placeholder="{{'Account Number'|translate}}" formControlName="accountNumber"
                                autocomplete="off" readonly>
                            <mat-error *ngIf="claimentformGroup.controls.accountNumber?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.accountNumber?.errors?.required">{{'AccountNumberRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="company name" maxlength="35"
                                placeholder="{{'Company Name'|translate}}" formControlName="companyName"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="claimentformGroup.controls.companyName?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.companyName?.errors?.required">{{'CompanyNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="first name" maxlength="15"
                                placeholder="{{'FirstName'|translate}}" formControlName="firstName" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="claimentformGroup.controls.firstName?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.firstName?.errors?.required">{{'FirstNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="last name" maxlength="20"
                                placeholder="{{'LastName'|translate}}" formControlName="lastName" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="claimentformGroup.controls.lastName?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.lastName?.errors?.required">{{'LastNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="street Address" maxlength="35"
                                placeholder="{{'StreetAddress'|translate}}" formControlName="streetAddress"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="claimentformGroup.controls.streetAddress?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.streetAddress?.errors?.required">{{'StreetAddressRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="Suite / Apt" maxlength="35"
                                placeholder="{{'SuiteOrApartment'|translate}}" formControlName="apartmentSuite"
                                autocomplete="off" [readonly]="editable">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select required placeholder="{{'Country' | translate}}" name="country"
                                [readonly]="editable" formControlName="country">

                            </mat-select>
                            <mat-error *ngIf="claimentformGroup.controls.country?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.country?.errors?.required">{{'SelectACountry'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="zip Code" maxlength="15"
                                placeholder="{{'Zipcode'|translate}}" formControlName="zip" [readonly]="editable"
                                autocomplete="off">
                            <mat-error *ngIf="claimentformGroup.controls.zip?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.zip?.errors?.required">{{'ZipCodeRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="city" maxlength="30"
                                placeholder="{{'City'|translate}}" formControlName="city" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="claimentformGroup.controls.city?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.city?.errors?.required">{{'CityRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="state" maxlength="2"
                                placeholder="{{'State'|translate}}" formControlName="state" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="claimentformGroup.controls.state?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.state?.errors?.required">{{'StateProvinceRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="phone" placeholder="{{'Phone'|translate}}"
                                formControlName="telNum" pattern="\d*" maxlength="15" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="claimentformGroup.controls.telNum?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.telNum?.errors?.required">{{'TelephoneNumberRequired'|translate}}</span>
                                <span
                                    *ngIf="claimentformGroup.controls.telNum?.errors?.notInteger">{{'OnlyInteger'|translate:
                                    { field: 'Telephone No' } }}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="email Address"
                                placeholder="{{'EmailAddress'|translate}}" formControlName="email" maxlength="100"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="claimentformGroup.controls.email?.errors">
                                <span
                                    *ngIf="claimentformGroup.controls.email?.errors?.required">{{'validation.emailRequired'|translate}}</span>
                                <span
                                    *ngIf="claimentformGroup.controls.email?.errors?.emailFormat">{{'EmailAddressInvalidFormat'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #SendersInfo>
    <div class="upsc-address">
        <form class="ups-form" [formGroup]="shippersformGroup">
            <div class="form-body">
                <div class="form-sections">
                    <div class="form-section">

                        <mat-form-field>
                            <input matInput required type="text" name="first name" maxlength="15"
                                placeholder="{{'FirstName'|translate}}" formControlName="firstName" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="shippersformGroup.controls.firstName?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.firstName?.errors?.required">{{'FirstNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="last name" maxlength="20"
                                placeholder="{{'LastName'|translate}}" formControlName="lastName" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="shippersformGroup.controls.lastName?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.lastName?.errors?.required">{{'LastNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="company name" maxlength="35"
                                placeholder="{{'Company Name'|translate}}" formControlName="companyName"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="shippersformGroup.controls.companyName?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.companyName?.errors?.required">{{'CompanyNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="street Address" maxlength="35"
                                placeholder="{{'StreetAddress'|translate}}" formControlName="streetAddress"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="shippersformGroup.controls.streetAddress?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.streetAddress?.errors?.required">{{'StreetAddressRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="Suite / Apt" maxlength="35"
                                placeholder="{{'SuiteOrApartment'|translate}}" formControlName="apartmentSuite"
                                autocomplete="off" [readonly]="editable">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select required placeholder="{{'Country' | translate}}" name="country"
                                [readonly]="editable" formControlName="country">

                            </mat-select>
                            <mat-error *ngIf="shippersformGroup.controls.country?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.country?.errors?.required">{{'SelectACountry'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="zip Code" maxlength="15"
                                placeholder="{{'Zipcode'|translate}}" formControlName="zip" [readonly]="editable"
                                autocomplete="off">
                            <mat-error *ngIf="shippersformGroup.controls.zip?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.zip?.errors?.required">{{'ZipCodeRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="city" maxlength="30"
                                placeholder="{{'City'|translate}}" formControlName="city" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="shippersformGroup.controls.city?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.city?.errors?.required">{{'CityRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="state" maxlength="2"
                                placeholder="{{'State'|translate}}" formControlName="state" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="shippersformGroup.controls.state?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.state?.errors?.required">{{'StateProvinceRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="phone" placeholder="{{'Phone'|translate}}"
                                formControlName="telNum" pattern="\d*" maxlength="15" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="shippersformGroup.controls.telNum?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.telNum?.errors?.required">{{'TelephoneNumberRequired'|translate}}</span>
                                <span
                                    *ngIf="shippersformGroup.controls.telNum?.errors?.notInteger">{{'OnlyInteger'|translate:
                                    { field: 'Telephone No' } }}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="email Address"
                                placeholder="{{'EmailAddress'|translate}}" formControlName="email" maxlength="100"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="shippersformGroup.controls.email?.errors">
                                <span
                                    *ngIf="shippersformGroup.controls.email?.errors?.required">{{'validation.emailRequired'|translate}}</span>
                                <span
                                    *ngIf="shippersformGroup.controls.email?.errors?.emailFormat">{{'EmailAddressInvalidFormat'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #ReceiversInfo>
    <div class="upsc-address">
        <form class="ups-form" [formGroup]="receiverformGroup">
            <div class="form-body">
                <div class="form-sections">
                    <div class="form-section">

                        <mat-form-field>
                            <input matInput required type="text" name="first name" maxlength="15"
                                placeholder="{{'FirstName'|translate}}" formControlName="firstName" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="receiverformGroup.controls.firstName?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.firstName?.errors?.required">{{'FirstNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="last name" maxlength="20"
                                placeholder="{{'LastName'|translate}}" formControlName="lastName" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="receiverformGroup.controls.lastName?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.lastName?.errors?.required">{{'LastNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="company name" maxlength="35"
                                placeholder="{{'Company Name'|translate}}" formControlName="companyName"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="receiverformGroup.controls.companyName?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.companyName?.errors?.required">{{'CompanyNameRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="street Address" maxlength="35"
                                placeholder="{{'StreetAddress'|translate}}" formControlName="streetAddress"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="receiverformGroup.controls.streetAddress?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.streetAddress?.errors?.required">{{'StreetAddressRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="Suite / Apt" maxlength="35"
                                placeholder="{{'SuiteOrApartment'|translate}}" formControlName="apartmentSuite"
                                autocomplete="off" [readonly]="editable">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select required placeholder="{{'Country' | translate}}" name="country"
                                [readonly]="editable" formControlName="country">

                            </mat-select>
                            <mat-error *ngIf="receiverformGroup.controls.country?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.country?.errors?.required">{{'SelectACountry'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="zip Code" maxlength="15"
                                placeholder="{{'Zipcode'|translate}}" formControlName="zip" [readonly]="editable"
                                autocomplete="off">
                            <mat-error *ngIf="receiverformGroup.controls.zip?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.zip?.errors?.required">{{'ZipCodeRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="city" maxlength="30"
                                placeholder="{{'City'|translate}}" formControlName="city" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="receiverformGroup.controls.city?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.city?.errors?.required">{{'CityRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="state" maxlength="2"
                                placeholder="{{'State'|translate}}" formControlName="state" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="receiverformGroup.controls.state?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.state?.errors?.required">{{'StateProvinceRequired'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="phone" placeholder="{{'Phone'|translate}}"
                                formControlName="telNum" pattern="\d*" maxlength="15" autocomplete="off"
                                [readonly]="editable">
                            <mat-error *ngIf="receiverformGroup.controls.telNum?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.telNum?.errors?.required">{{'TelephoneNumberRequired'|translate}}</span>
                                <span
                                    *ngIf="receiverformGroup.controls.telNum?.errors?.notInteger">{{'OnlyInteger'|translate:
                                    { field: 'Telephone No' } }}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required type="text" name="email Address"
                                placeholder="{{'EmailAddress'|translate}}" formControlName="email" maxlength="100"
                                autocomplete="off" [readonly]="editable">
                            <mat-error *ngIf="receiverformGroup.controls.email?.errors">
                                <span
                                    *ngIf="receiverformGroup.controls.email?.errors?.required">{{'validation.emailRequired'|translate}}</span>
                                <span
                                    *ngIf="receiverformGroup.controls.email?.errors?.emailFormat">{{'EmailAddressInvalidFormat'|translate}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>