export class ContactInfo {
    FirstName = '';
    LastName = '';
    CompanyName = '';
    StreetAddress = '';
    ApartmentSuite = '';
    City = '';
    State = '';
    Country = '';
    Zip = '';
    TelephoneNo = '';
    Email = '';
}