import { Component, ElementRef, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,UntypedFormControl, Validators } from '@angular/forms';

import { Merchandise } from './models/merchandise.model.ts';
import { DialogService } from '../../../shared/services/dialog/dialog.service';
//import { WorldShipUploadService } from './services/world-ship-upload.service';
import { FileService } from '../../../shared/services/file/file.service';
import { UserService } from '../../../shared/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MatRadioChange } from '@angular/material/radio';
//import { NotificationService } from '../shared/services/notification/notification.service';

import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { EmailShippingLabel } from 'app/drop-shipment/models/email-shipping-label.model';
import { EmailShippingLabelService } from 'app/drop-shipment/services/email-shipping-label.service';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
enum CheckBoxType { Yes, No, NONE };
@Component({
  selector: 'upsc-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent implements OnInit {

  @Output() fileSelectionChanged: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output("resetForms") resetForms: EventEmitter<any> = new EventEmitter();
  
  @ViewChild('fileControl1', { static: true }) fileControl1: ElementRef;
  @ViewChild('fileControl2', { static: true }) fileControl2: ElementRef;
  @Input() claimentGroup: any;
  @Input() shippersGroup: any;
  @Input() receiversGroup:any;  
  @Input() trackingNumber: any;
  public DescriptionformGroup1: UntypedFormGroup;
  public DescriptionformGroup2: UntypedFormGroup;
  public DescriptionformGroup3: UntypedFormGroup;
  public DescriptionformGroup4: UntypedFormGroup;
  public DescriptionformGroup5: UntypedFormGroup;
  public AdditionalInformation: UntypedFormGroup;

  public formData: FormData = new FormData();
  public isInProgress = false;
  public uploadedFile1: string;
  public uploadedFile2: string;
  public uploadedFile3: string;
  public uploadedFile4: string;

  public uploadedImage1: string;
  public uploadedImage2: string;
  public uploadedImage3: string;
  public uploadedImage4: string;
  public uploadedImage5: string;
  public uploadedImage6: string;
  selectedlocation:  number ;  
  selectedContact:  number ; 
  contactValue : string;
 locationValue : string;
  eventEditForm: UntypedFormGroup;
  fileTypes = '*/*';
  isMultiple = false;
  buttonClass = '';
  isDisabled = false;
  clearFileControl = false;

  assetURL: any = [];
  fileNames: any = [];
  public allowedFileTypes = ['jpg', 'pdf', 'docx', 'xls'];

  helpHide: boolean = false;
  formHide: boolean = true;
  noOfDescription: number = 0;
  def: number;
  descriptionSummary1: boolean = false;
  descriptionSummary2: boolean = false;
  descriptionSummary3: boolean = false;
  descriptionSummary4: boolean = false;
  descriptionSummary5: boolean = false;
  showDescriptionform1: boolean = true;
  showDescriptionform2: boolean = false;
  showDescriptionform3: boolean = false;
  showDescriptionform4: boolean = false;
  showDescriptionform5: boolean = false;
  //descriptionData:
  isEdited: boolean = false;
  issueDescription: string = '';
  formId: any = '';
  commodityValue: any;
  selectedValue: string;
  selectedCommodity: any = [];
  arrayData: Merchandise[] = [];
  reseted: boolean = false;
  //information
  outerBoxYes = false;
  outerBoxNo = false;
  check_box_type = CheckBoxType;
  outerBoxChecked: CheckBoxType;
  otherBoxChecked: CheckBoxType;
  innerBoxChecked: CheckBoxType;
  additionalInformationValue: any;
  claimType: string = '';
  //additionalInfo: string = '';
  //additionalInfo1: string = '';
  //additionalInfoval: string = '';
  
  contactInformation: string[] = ['Yes', 'No', 'N/A'];
  locationInformation: string[] = ['Shipper', 'Destination', 'Other/Unknown'];
  claims: string[] = ['Lost', 'Missing Contents', 'Damaged'];
  issueType: string[] = ['Marked as delivered but it wasn’t', 'Delivered to incorrect address', 'In Transit with no delivery scan'];
  constructor(private formBuilder: UntypedFormBuilder, public merchandise: Merchandise,
    public dialogService: DialogService,
    private userService: UserService,
    public emailShippingLabel: EmailShippingLabel,
    private notificationService: NotificationService,

    private emailShippingLabelService: EmailShippingLabelService,
    private translateService: TranslateService,
    private errorHandlerService: ErrorHandlerService,) { }

  ngOnInit(): void {

    this.merchandise = new Merchandise();

    this.DescriptionformGroup1 = this.formBuilder.group({
      commodity: [''],
      description: ['', Validators.required],
      noOfItems: ['', Validators.required],
      model: [''],
      serial: [''],
      invoiceValue: ['', Validators.required],
      claimedAmount: ['', Validators.required],
    });
    this.selectedCommodity = [...this.commodites];
    this.DescriptionformGroup2 = this.formBuilder.group({
      description: ['', Validators.required],
      noOfItems: ['', Validators.required],
      model: [''],
      serial: [''],
      invoiceValue: ['', Validators.required],
      claimedAmount: ['', Validators.required],
    });
    this.DescriptionformGroup3 = this.formBuilder.group({
      description: ['', Validators.required],
      noOfItems: ['', Validators.required],
      model: [''],
      serial: [''],
      invoiceValue: ['', Validators.required],
      claimedAmount: ['', Validators.required],
    });
    this.DescriptionformGroup4 = this.formBuilder.group({
      description: ['', Validators.required],
      noOfItems: ['', Validators.required],
      model: [''],
      serial: [''],
      invoiceValue: ['', Validators.required],
      claimedAmount: ['', Validators.required],
    });
    this.DescriptionformGroup5 = this.formBuilder.group({
      description: ['', Validators.required],
      noOfItems: ['', Validators.required],
      model: [''],
      serial: [''],
      invoiceValue: ['', Validators.required],
      claimedAmount: ['', Validators.required],
    });//AdditionalInformation
    this.AdditionalInformation = this.formBuilder.group({
      outerBoxValue: ['', Validators.required],
      innerBoxValue: ['', Validators.required],
      otherBoxValue: ['', Validators.required],
      insidentStatement: [''],
      currency: ['', Validators.required],
      insured: ['', Validators.required],
      totalAmount: ['', Validators.required],
      
    });
    this.eventEditForm = new UntypedFormGroup({          
      'location': new UntypedFormControl(),
      'contact': new UntypedFormControl()
      });
    this.onValueChanges();
  }
  onValueChanges() {
    this.DescriptionformGroup1.valueChanges.subscribe(() => {
      if (true) {
               
       if(this.DescriptionformGroup1.valid && this.commodityValue ){
        
       }
      }
    })
    this.eventEditForm.valueChanges.subscribe(() =>{
      if(true){
        // console.log('this.selectedContact?????',this.selectedContact)
        // console.log('this.selectedlocation?????',this.selectedlocation)
        // this.contactValue = this.contactInformation[this.selectedContact];
        // this.locationValue = this.locationInformation[this.selectedlocation];
        // console.log('this.contactValue?????',this.contactValue)
        // console.log('this.locationValue?????',this.locationValue)
      }
    })

    this.AdditionalInformation.valueChanges.subscribe(() => {
      if (true) {
        this.additionalInformationValue = this.AdditionalInformation.value
       
      }
    })
  }
  public packageFormsValid() {
    console.log('packagedetails ---formsValid----')
   
    var isPakageFormValid = this.DescriptionformGroup1.valid && this.AdditionalInformation.valid;
    
    var isClaimTypeSelected = this.claimType ? true : false;
    var isCommoditySelected = this.commodityValue ? true : false;  
    

    if((this.outerBoxChecked == CheckBoxType.Yes  && this.AdditionalInformation.valid)||(this.outerBoxChecked == CheckBoxType.No) ){
      console.log('outerBoxChecked---',this.outerBoxChecked)
    }
    console.log("DescriptionformGroup1",this.DescriptionformGroup1.valid);
    console.log("this.AdditionalInformation.valid",this.AdditionalInformation.valid);
    var packageValid = isPakageFormValid && isClaimTypeSelected && isCommoditySelected;
    console.log("isPakageFormValid++++++++",isPakageFormValid)
    console.log("isClaimTypeSelected++++++++",isClaimTypeSelected)
    console.log("isCommoditySelected++++++++",isCommoditySelected)
    console.log("packageValid++++++++",packageValid)
    return packageValid;
  }
  packageReset() {
    this.reseted = true;
    this.claimType = '';
    this.issueDescription = '';
    //this.additionalInfo = '';
    //this.additionalInfo1 = '';
    //this.additionalInfoval = '';
    this.outerBoxChecked = CheckBoxType.NONE;
    this.innerBoxChecked = CheckBoxType.NONE;
    this.otherBoxChecked = CheckBoxType.NONE;
    this.eventEditForm.reset();
    this.def = 0
    this.DescriptionformGroup1.reset();
    this.DescriptionformGroup2.reset();
    this.DescriptionformGroup3.reset();
    this.DescriptionformGroup4.reset();
    this.DescriptionformGroup5.reset();
    this.AdditionalInformation.reset();
    this.uploadedFile1 = '';
    this.uploadedFile1 = '';
    this.uploadedFile2 = '';
    this.uploadedFile3 = '';
    this.uploadedFile4 = '';

    this.uploadedImage1 = '';
    this.uploadedImage2 = '';
    this.uploadedImage3 = '';
    this.uploadedImage4 = '';
    this.uploadedImage5 = '';
    this.uploadedImage6 = '';
    this.assetURL = [];
    this.fileNames = [];
    this.desriptionDetails();
    this.selectedValue = '';
    this.resetForms.emit();
    console.log('vivekkkk package end====================================')
  }
  desriptionDetails() {
    console.log('desriptionDetails hided---------------------')
    this.arrayData = [];
    this.showDescriptionform1 = true;
    this.showDescriptionform2 = false;
    this.showDescriptionform3 = false;
    this.showDescriptionform4 = false;
    this.showDescriptionform5 = false;
    this.descriptionSummary1 = false;
    this.descriptionSummary2 = false;
    this.descriptionSummary3 = false;
    this.descriptionSummary4 = false;
    this.descriptionSummary5 = false;
  }
  // radioButtonChanges(data: MatRadioChange){
  // this.additionalInfo1 = data.value;
  // }
  onKey(value) {
    this.selectedCommodity = this.search(value);
  }
  helpClose(event) {
    this.helpHide = false;
  }
  helpOpen(event) {
    this.helpHide = true;
  }
  search(value: string) {
    let filter = this.commodites.filter(item =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    return [...filter];
  }

  getSubtier(value) {
    this.commodityValue = value.name;
    console.log(value);
  }
  onSearch(event) {
    //API call
  }

  // uploadFile(event) {
  //   let fileList: FileList = event.target.files;
  //   if (fileList.length > 0) {
  //     let file: File = fileList[0];
  //     if (this.validateFile(file)) {
  //       this.formData.append('uploadFile', file, file.name);
  //       this.uploadedFile = file.name;
  //     }
  //   }
  // }
  public onFileSelectionChanged(event, id) {
    console.log("vivekkkkk", event)
    const files = event.target.files;
    let imageId = id
    if (!files || files.length === 0) {
      console.log("files emouyuy", files)
      return;
    }
    console.log('imageid', imageId)
    //this.fileSelectionChanged.emit(files);
    this.uploadImage(files, imageId)
    this.clearFileControl = true;
    this.clearFileSelection();
  }
  public onimageSelectionChanged(event, id) {
    console.log("onimageSelectionChanged", event)
    const files = event.target.files;
    let imageId = id
    if (!files || files.length === 0) {
      console.log("files emouyuy", files)
      return;
    }
    console.log('onimageSelectionChanged image id', imageId)
    //this.fileSelectionChanged.emit(files);
    this.uploadImage(files, imageId)
    this.clearFileControl = true;
    this.clearFileSelection();
  }
  public uploadImage(files: FileList, id) {
    console.log("files", files)
    console.log('id', id)
    let uploadId = id;
    if (!files || !files.length) {
      return;
    }

    const file: File = files[0];
    if (!this.validateFile(file)) {
      return;
    }

    switch (uploadId) {
      case 'label':
        this.formData.append('uploadImage1', file, file.name);
        this.uploadedImage1 = file.name;
        console.log("label+++++++++++1")
        break;
      case 'sides':
        this.formData.append('uploadImage2', file, file.name);
        this.uploadedImage2 = file.name;
        console.log("label+++++++++++++2+")
        break;
      case 'Bottom':
        this.formData.append('uploadImage3', file, file.name);
        this.uploadedImage3 = file.name;
        console.log("label++++++++++++++3")
        break;
      case 'Damaged':
        this.formData.append('uploadImage4', file, file.name);
        this.uploadedImage4 = file.name;
        console.log("label++++++++++++++")
        break;
      case 'outer':
        this.formData.append('uploadImage5', file, file.name);
        this.uploadedImage5 = file.name;
        console.log("label++++++++++++++4")
        break;
      case 'inner':
        this.formData.append('uploadImage6', file, file.name);
        this.uploadedImage6 = file.name;
        console.log("label++++++++++++++5")
        break;
    }

    this.uploadAttachment();
  }

  private clearFileSelection() {
    if (this.clearFileControl) {
      console.log('this.clearFileControl', this.clearFileControl)
      this.fileControl1.nativeElement.value = '';
      this.fileControl2.nativeElement.value = '';
    }
  }
  public uploadFiles(files: FileList, id) {
    console.log("files", files)
    console.log("event", id)
    let uploadId = id;
    if (!files || !files.length) {
      return;
    }

    const file: File = files[0];
    if (!this.validateFile(file)) {
      return;
    }

    // this.formData.append('uploadFile', file, file.name);


    switch (uploadId) {
      case 'invoice':
        this.formData.append('uploadFile1', file, file.name);
        this.fileNames.push(file.name)
        this.uploadedFile1 = file.name;
        break;
      case 'photograph':
        this.formData.append('uploadFile2', file, file.name);
        this.fileNames.push(file.name)
        this.uploadedFile2 = file.name;
        break;
      case 'estimate':
        this.formData.append('uploadFile3', file, file.name);
        this.fileNames.push(file.name)
        this.uploadedFile3 = file.name;
        break;
      case 'other':
        this.formData.append('uploadFile4', file, file.name);
        this.fileNames.push(file.name)
        this.uploadedFile4 = file.name;
        break;

    }
    // this.uploadedFile1 = file.name;
    this.uploadAttachment();
  }

  removeFile(id) {

    switch (id) {
      case 'invoice':
        this.uploadedFile1 = '';
        this.formData = new FormData();
        break;
      case 'photograph':
        this.uploadedFile2 = '';
        this.formData = new FormData();
        break;
      case 'estimate':
        this.uploadedFile3 = '';
        this.formData = new FormData();
        break;
      case 'other':
        this.uploadedFile4 = '';
        this.formData = new FormData();
        break;
      case 'label':
        this.uploadedImage1 = '';
        this.formData = new FormData();
        break;
      case 'sides':
        this.uploadedImage2 = '';
        this.formData = new FormData();
        break;
      case 'Bottom':
        this.uploadedImage3 = '';
        this.formData = new FormData();
        break;
      case 'Damaged':
        this.uploadedImage4 = '';
        this.formData = new FormData();
        break;
      case 'outer':
        this.uploadedImage5 = '';
        this.formData = new FormData();
        break;
      case 'inner':
        this.uploadedImage6 = '';
        this.formData = new FormData();
        break;
    }
  }

  uploadAttachment() {

    this.isInProgress = true;

    //  if (this.uploadedFile1  ) { // Only if there is file to upload
    this.emailShippingLabelService.uploadFiles(this.formData).subscribe(
      (response) => {
        this.assetURL.push(response);
        console.log('this.assetURll-----', this.assetURL)
      }, (error) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(error),
          this.translateService.instant('uploadFailed'),
          NotificationType.ERROR);
        this.isInProgress = false;

      });
    //  } 

  }
  validateFile(file: File): boolean {
    var fileExtension = file.name.split('.').pop();
    if (file.size > 5242880) {
      this.notificationService.notify(this.translateService.instant('dropShipmentEmail.maxFileSize'), this.translateService.instant('dropShipmentEmail.fileSizeExceeded'), NotificationType.ERROR);
    }
    else if (!this.isInArray(this.allowedFileTypes, fileExtension)) {
      this.notificationService.notify(this.translateService.instant('dropShipmentEmail.fileFormatNotAllowed'), this.translateService.instant('invalidFile'), NotificationType.ERROR);
    }
    else return true;
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  outerCheckBox(targetType: CheckBoxType) {

    // If the checkbox was already checked, clear the currentlyChecked variable

    if (this.outerBoxChecked === targetType) {
      this.outerBoxChecked = CheckBoxType.NONE;
      return;
    }

    this.outerBoxChecked = targetType;
    console.log("outerBoxChecked", this.outerBoxChecked)
  }
  innerCheckBox(targetType: CheckBoxType) {

    // If the checkbox was already checked, clear the currentlyChecked variable

    if (this.innerBoxChecked === targetType) {
      this.innerBoxChecked = CheckBoxType.NONE;
      return;
    }

    this.innerBoxChecked = targetType;
    console.log("innerBoxChecked", this.innerBoxChecked)
  }
  otherCheckBox(targetType: CheckBoxType) {

    // If the checkbox was already checked, clear the currentlyChecked variable

    if (this.otherBoxChecked === targetType) {
      this.otherBoxChecked = CheckBoxType.NONE;
      return;
    }

    this.otherBoxChecked = targetType;
    console.log("otherBoxChecked", this.otherBoxChecked)
  }


  confirmDeleteMechandise(deleteId: string) {
    this.dialogService.confirm('Delete Merchandise', 'Are you sure you want to delete this item ?')
      .subscribe(
        (isConfirmed) => {
          if (!isConfirmed) {
            return;
          }
          console.log("confirmDeleteMechandise-----.event")
          this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === deleteId), 1);
          console.log("confirmDeleteMechandise", this.arrayData)
        });
  }




  ondelete(event) {
    console.log("delete-----.event", event)
    console.log("deletevvvv.....", event.target.id)
    let deleteId = event.target.id;
    switch (deleteId) {
      case "deleteDetails2":
        this.DescriptionformGroup2.reset();
        var deleteDescription = "DescriptionformGroup2"
        console.log('deleteDetails2,')
        //console.log("index", this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup2"))
        //this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup2"), 1);
        this.confirmDeleteMechandise(deleteDescription)
        this.showDescriptionform1 = true;
        this.descriptionSummary1 = false;
        this.descriptionSummary2 = false;
        console.log("vivek1", this.arrayData)
        break;
      //     let someArray = [{name:"Kristian", lines:"2,5,10"},
      //          {name:"John", lines:"1,19,26,96"}];
      //          console.log("index",someArray.findIndex(v => v.name === "John"))
      // someArray.splice(someArray.findIndex(v => v.name === "Kristian"), 1);

      // console.log("vivek",someArray)       
      case "deleteDetails3":
        this.DescriptionformGroup3.reset();
        var deleteDescription = "DescriptionformGroup3"
        console.log('deleteDetails3 ,')
        // console.log("index", this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup3"))
        // this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup3"), 1);
        this.confirmDeleteMechandise(deleteDescription)
        this.showDescriptionform2 = true;
        this.descriptionSummary2 = false;
        this.descriptionSummary3 = false;
        console.log("vivek", this.arrayData)
        break;
      case "deleteDetails4":
        this.DescriptionformGroup4.reset();
        var deleteDescription = "DescriptionformGroup4"
        console.log('deleteDetails4,')
        // console.log("index", this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup4"))
        // this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup4"), 1);
        this.confirmDeleteMechandise(deleteDescription)
        this.showDescriptionform3 = true;
        this.descriptionSummary3 = false;
        this.descriptionSummary4 = false;
        console.log("vivek", this.arrayData)
        break;
      case "deleteDetails5":
        this.DescriptionformGroup5.reset();
        var deleteDescription = "DescriptionformGroup5"
        console.log('deleteDetails5,')
        // console.log("index", this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup5"))
        // this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup5"), 1);
        this.confirmDeleteMechandise(deleteDescription)
        this.showDescriptionform4 = true;
        this.descriptionSummary4 = false;
        this.descriptionSummary5 = false;
        console.log("vivek", this.arrayData)
        break;

    }

  }
  edit(event) {
    console.log("vivek.event", event)
    console.log("vivek.....", event.target.id)
    let editId = event.target.id;
    switch (editId) {
      case "editDetails1":
        this.def = 0
        this.selectedValue = this.commodityValue ? this.commodityValue : '';
        console.log("this.selectedValue-----", this.selectedValue)
        this.isEdited = true;
        this.showDescriptionform1 = true;
        this.descriptionSummary1 = false;
        break;
      case "editDetails2":
        this.isEdited = true;
        this.showDescriptionform2 = true;
        this.descriptionSummary2 = false;
        break;
      case "editDetails3":
        this.isEdited = true;
        this.showDescriptionform3 = true;
        this.descriptionSummary3 = false;
        break;
      case "editDetails4":
        this.isEdited = true;
        this.showDescriptionform4 = true;
        this.descriptionSummary4 = false;
        break;
      case "editDetails5":
        this.isEdited = true;
        this.showDescriptionform5 = true;
        this.descriptionSummary5 = false;
        break;

    }

  }

  addItemform1(event) {
    if(!this.DescriptionformGroup1.valid)
    {
      console.log('xxxxxx!this.DescriptionformGroup1.validxxxxxxx')
      return
    }
    if (this.isEdited) {
      this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup1"), 1);
      console.log(' previous arrayData 1,--------', this.arrayData)
    }
    
    let merchandiseId = event.target.id;
    this.noOfDescription++;
    this.merchandise = new Merchandise();
    this.merchandise.merchandiseId = merchandiseId;
    this.merchandise.Commodity = this.commodityValue;
    this.merchandise.CommodityDescription = this.DescriptionformGroup1.controls.description?.value;
    this.merchandise.NoOfItems = this.DescriptionformGroup1.controls.noOfItems?.value;
    this.merchandise.Model = this.DescriptionformGroup1.controls.model?.value;
    this.merchandise.Serial = this.DescriptionformGroup1.controls.serial?.value;
    this.merchandise.Invoice = this.DescriptionformGroup1.controls.invoiceValue?.value;
    this.merchandise.ClaimedAmount = this.DescriptionformGroup1.controls.claimedAmount?.value;
    console.log("this.merchandise1", this.merchandise)
    this.arrayData.push(this.merchandise);

    this.showDescriptionform1 = false;
    this.descriptionSummary1 = true;
    this.showDescriptionform2 = true;
    console.log('this.arrayData ,', this.arrayData)
    console.log(' ,noOfDescription', this.noOfDescription)
    this.isEdited = false;
    //     const node = document.getElementById("myList2").lastChild;
    // const clone = node.cloneNode(true);

    // document.getElementById("myList1").appendChild(clone);

  }
  addItemform2(event) {
    this.noOfDescription++;
    if (this.isEdited) {
      this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup2"), 1);
      console.log(' previous arrayData 2,--------', this.arrayData)
    }
    let merchandiseId = event.target.id;
    this.noOfDescription++;
    this.merchandise = new Merchandise();
    this.merchandise.merchandiseId = merchandiseId;
    this.merchandise.Commodity = '';
    this.merchandise.CommodityDescription = this.DescriptionformGroup2.controls.description?.value;
    this.merchandise.NoOfItems = this.DescriptionformGroup2.controls.noOfItems?.value;
    this.merchandise.Model = this.DescriptionformGroup2.controls.model?.value;
    this.merchandise.Serial = this.DescriptionformGroup2.controls.serial?.value;
    this.merchandise.Invoice = this.DescriptionformGroup2.controls.invoiceValue?.value;
    this.merchandise.ClaimedAmount = this.DescriptionformGroup2.controls.claimedAmount?.value;
    console.log("this.merchandise2", this.merchandise)
    this.arrayData.push(this.merchandise);
    this.showDescriptionform2 = false;
    this.descriptionSummary2 = true;
    this.showDescriptionform3 = true;
    this.isEdited = false;
    console.log('this.arrayData ,', this.arrayData)
  }

  addItemform3(event) {
    this.noOfDescription++;
    if (this.isEdited) {
      this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup3"), 1);
      console.log(' previous arrayData 3,--------', this.arrayData)
    }
    console.log("addItemform3.....", event.target.id)
    let merchandiseId = event.target.id;

    this.merchandise = new Merchandise();
    this.merchandise.merchandiseId = merchandiseId;
    this.merchandise.Commodity = '';
    this.merchandise.CommodityDescription = this.DescriptionformGroup3.controls.description?.value;
    this.merchandise.NoOfItems = this.DescriptionformGroup3.controls.noOfItems?.value;
    this.merchandise.Model = this.DescriptionformGroup3.controls.model?.value;
    this.merchandise.Serial = this.DescriptionformGroup3.controls.serial?.value;
    this.merchandise.Invoice = this.DescriptionformGroup3.controls.invoiceValue?.value;
    this.merchandise.ClaimedAmount = this.DescriptionformGroup3.controls.claimedAmount?.value;
    console.log("this.merchandise23", this.merchandise)
    this.arrayData.push(this.merchandise);
    this.showDescriptionform3 = false;
    this.descriptionSummary3 = true;
    this.showDescriptionform4 = true;
    this.isEdited = false;
    console.log('this.arrayData ,', this.arrayData)
    console.log(' ,noOfDescription', this.noOfDescription)
  }
  addItemform4(event) {
    if (this.isEdited) {
      this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup4"), 1);
      console.log(' previous arrayData 4,--------', this.arrayData)
    }
    let merchandiseId = event.target.id;
    this.noOfDescription++;
    this.merchandise = new Merchandise();
    this.merchandise.merchandiseId = merchandiseId;
    this.merchandise.Commodity = '';
    this.merchandise.CommodityDescription = this.DescriptionformGroup4.controls.description?.value;
    this.merchandise.NoOfItems = this.DescriptionformGroup4.controls.noOfItems?.value;
    this.merchandise.Model = this.DescriptionformGroup4.controls.model?.value;
    this.merchandise.Serial = this.DescriptionformGroup4.controls.serial?.value;
    this.merchandise.Invoice = this.DescriptionformGroup4.controls.invoiceValue?.value;
    this.merchandise.ClaimedAmount = this.DescriptionformGroup4.controls.claimedAmount?.value;
    console.log("this.merchandise4", this.merchandise)
    this.arrayData.push(this.merchandise);
    this.showDescriptionform4 = false;
    this.descriptionSummary4 = true;
    this.showDescriptionform5 = true;
    this.isEdited = false;
    console.log('this.arrayData ,', this.arrayData)
    console.log(' ,noOfDescription', this.noOfDescription)
  }
  addItemform5(event) {
    if (this.isEdited) {
      this.arrayData.splice(this.arrayData.findIndex(v => v.merchandiseId === "DescriptionformGroup5"), 1);
      console.log(' previous arrayData 5,--------', this.arrayData)
    }
    let merchandiseId = event.target.id;
    this.noOfDescription++;
    this.merchandise = new Merchandise();
    this.merchandise.merchandiseId = merchandiseId;
    this.merchandise.Commodity = '';
    this.merchandise.CommodityDescription = this.DescriptionformGroup5.controls.description?.value;
    this.merchandise.NoOfItems = this.DescriptionformGroup5.controls.noOfItems?.value;
    this.merchandise.Model = this.DescriptionformGroup5.controls.model?.value;
    this.merchandise.Serial = this.DescriptionformGroup5.controls.serial?.value;
    this.merchandise.Invoice = this.DescriptionformGroup5.controls.invoiceValue?.value;
    this.merchandise.ClaimedAmount = this.DescriptionformGroup5.controls.claimedAmount?.value;
    console.log("this.merchandise5", this.merchandise)
    this.arrayData.push(this.merchandise);
    this.showDescriptionform5 = false;
    this.descriptionSummary5 = true;
    this.isEdited = false;
    console.log('this.arrayData ,', this.arrayData)
    console.log(' ,noOfDescription', this.noOfDescription)
  }

  commodites: any[] = [
    {
      toptier_agency_id: 15,
      create_date: 1517428376464,
      update_date: 1560547998012,
      toptier_code: "013",
      abbreviation: "DOC",
      name: "cell phones",
      website: "https://www.commerce.gov/",
      mapped_org_name: "COMMERCE, DEPARTMENT OF",
      display_yn: "Y"
    },
    {
      toptier_agency_id: 16,
      create_date: 1517428376787,
      update_date: 1560547999157,
      toptier_code: "014",
      abbreviation: "DOI",
      name: "collection",
      website: "https://www.doi.gov/",
      mapped_org_name: "INTERIOR, DEPARTMENT OF THE",
      display_yn: "Y"
    },
    {
      toptier_agency_id: 17,
      create_date: 1517428377318,
      update_date: 1560548000847,
      toptier_code: "015",
      abbreviation: "DOJ",
      name: "mobiles",
      website: "https://www.justice.gov/",
      mapped_org_name: "JUSTICE, DEPARTMENT OF",
      display_yn: "Y"
    },
    {
      toptier_agency_id: 21,
      create_date: 1517428378114,
      update_date: 1560548001781,
      toptier_code: "019",
      abbreviation: "DOS",
      name: "collection",
      website: "https://www.state.gov/",
      mapped_org_name: "STATE, DEPARTMENT OF",
      display_yn: "Y"
    },
    {
      toptier_agency_id: 22,
      create_date: 1517428379877,
      update_date: 1560548004040,
      toptier_code: "020",
      abbreviation: "TREAS",
      name: "computer chips",
      website: "https://www.treasury.gov/",
      mapped_org_name: "TREASURY, DEPARTMENT OF THE",
      display_yn: "Y"
    },
  ]
}
