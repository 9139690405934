import { Component, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ValidationService } from '../../shared/services/validation/validation.service';
import { SignUpDropdowns } from '../../../config/dropdown-config';
import { SharedService } from '../services/shared.service';
import { SalesPerson } from '../model/sales-person.interface';

@Component({
    selector: 'upsc-business-information',
    templateUrl: './business-information.component.html',
    styleUrls: ['./business-information.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BusinessInformationComponent implements OnInit {

    businessInformationFormGroup: UntypedFormGroup;
    @Output() notifyForm: EventEmitter<any> = new EventEmitter<any>();
    public signUpDropdowns = SignUpDropdowns;
    public otherPrimaryBusiness = false;
    public otherProduct = false;
    private primaryBusiness: string[] = [];
    private products: string[] = [];
    private reference;
    public isSalesNameSelected: boolean = false;
    public selectedSalesPersonName: string = "";
    public salesPersons: SalesPerson[] = [];

    public movementTypesSumError: boolean = false;
    public merchandiseConditionSumError: boolean = false;

    constructor(private formBuilder: UntypedFormBuilder,
        private router: ActivatedRoute,
        private sharedService: SharedService,
        private validationService: ValidationService) { }

    ngOnInit() {
        this.readQueryParams();
        this.initForm();
        this.onValueChanges();
        this.notifyForm.emit(this.businessInformationFormGroup);
    }

    initForm() {
        this.businessInformationFormGroup = this.formBuilder.group({
            businessLocation: ['US', Validators.required],
            howDidYouHear: [''],
            salesName: [''],
            howDidYouHearOther: [''],
            affilication: [this.reference, Validators.required],
            DBA: ['', Validators.compose([Validators.maxLength(50)])],
            companyName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
            firstName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
            lastName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
            businessOwner: ['', Validators.compose([Validators.maxLength(50)])],
            federalTaxID: ['', Validators.compose([Validators.required, Validators.pattern(/^\d{2}\-\d{7}$/)])], 
            yearsInBusiness: [''],
            yearIncorporated: [''],
            numberOfEmployees: [''],
            numberOfLocations: [''],
            typeofBusiness: [''],
            primaryBusiness: ['', Validators.compose([Validators.maxLength(200)])],
            otherPrimaryBusiness: ['', Validators.maxLength(200)],
            products: ['', Validators.compose([Validators.required, Validators.maxLength(200)])],
            otherProduct: ['', Validators.maxLength(200)],
            businessToBusinessPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])],
            businessToConsumerPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])],
            returnPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])],
            newPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])],
            usedPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])]
        }, {
            validators: [
                this.sumValidator(['businessToBusinessPercent', 'businessToConsumerPercent', 'returnPercent'], 100),
                this.sumValidator(['newPercent', 'usedPercent'], 100)
            ]
        });
    }
    
    public sumValidator(controls: string[], requiredSum: number): ValidatorFn {
        return (formGroup: AbstractControl) => {
            const sum = controls
                .map(controlName => Number(formGroup.get(controlName)?.value))
                .reduce((accumulator, value) => accumulator + value, 0);
            return sum === requiredSum ? null : { sumInvalid: true };
        };
    }    

    private readQueryParams() {
        this.router.queryParams.subscribe(params => {
            this.reference = params.ref ? params.ref : '';
        });
    }

    onValueChanges() {
        this.businessInformationFormGroup.valueChanges
            .subscribe(() => {
                this.notifyForm.emit(this.businessInformationFormGroup);
        });
        this.howDidYouHearChanges();
        this.businessLocationChanges();
        this.getSumValidationErrorForMovementTypes();
        this.getSumValidationErrorForMerchandiseCondition();
    }

    private getSumValidationErrorForMovementTypes(): void {
        const controls = ['businessToBusinessPercent', 'businessToConsumerPercent', 'returnPercent'];
        controls.forEach(control => {
            this.businessInformationFormGroup.get(control)?.valueChanges.subscribe(() => {
                const b2b = this.businessInformationFormGroup.get('businessToBusinessPercent')?.value;
                const b2c = this.businessInformationFormGroup.get('businessToConsumerPercent')?.value;
                const returns = this.businessInformationFormGroup.get('returnPercent')?.value;
                this.movementTypesSumError = Number(b2b) + Number(b2c) + Number(returns) !== 100;
            });
        });
    }

    private getSumValidationErrorForMerchandiseCondition(): void {
        const controls = ['newPercent', 'usedPercent'];
        controls.forEach(control => {
            this.businessInformationFormGroup.get(control)?.valueChanges.subscribe(() => {
                const newMerchandiseCondition = this.businessInformationFormGroup.get('newPercent')?.value;
                const usedMerchandiseCondition = this.businessInformationFormGroup.get('usedPercent')?.value;
                this.merchandiseConditionSumError = Number(newMerchandiseCondition) + Number(usedMerchandiseCondition) !== 100;
            });
        });
    }

    private howDidYouHearChanges(): void {
        this.businessInformationFormGroup.controls.howDidYouHear.valueChanges.subscribe((value) => {
            if (value === 'Sales - Name') {
                this.isSalesNameSelected = true;
                this.sharedService.getSalesNameDropdown()
                    .subscribe((response) => {
                        this.extractSalesPersons(response);
                    })
                this.businessInformationFormGroup.controls.salesName.updateValueAndValidity();
            }

            else if (value == 'Other') {
                this.isSalesNameSelected = false;
                this.validationService.setFormControlValidators(this.businessInformationFormGroup.controls.howDidYouHearOther,
                    Validators.compose([Validators.required]));
            }

            else {
                this.isSalesNameSelected = false;
                this.validationService.clearFormControlValidators([
                    this.businessInformationFormGroup.controls.howDidYouHearOther
                ]);
            }
        });
    }

    private businessLocationChanges(): void {
        this.businessInformationFormGroup.controls.businessLocation.valueChanges.subscribe((value) => {
            if (value && (value === 'US' || value === 'CA')) {
                this.validationService.setFormControlValidators(this.businessInformationFormGroup.controls.federalTaxID,
                    Validators.compose([Validators.required, Validators.pattern(/^\d{2}\-\d{7}$/)]));
            }
            else {
                this.validationService.clearFormControlValidators([
                    this.businessInformationFormGroup.controls.federalTaxID
                ]);
            }
            this.filterSalesPersonDropdown(value);
            this.sharedService.setCustomerCountry(value);
        });
    }

    private filterSalesPersonDropdown(location: string) {
        if (location === 'US') {
            this.signUpDropdowns.knowledgeSource = ['Online Search', 'Tradeshows', 'MJSA', 'Customer Referral', 'UPS Referral', 
                                                    'Ad', 'Social Media', 'Vendor', 'Previous/Current Accountholder', 
                                                    'Sales - Name'].sort();
        } 
        else {
            this.isSalesNameSelected = false;
            this.signUpDropdowns.knowledgeSource = ['Online Search', 'Tradeshows', 'MJSA', 'Customer Referral', 'UPS Referral', 
                                                    'Ad', 'Social Media', 'Vendor', 'Previous/Current Accountholder'].sort();
        }
    }

    private extractSalesPersons(data): void {
        this.salesPersons = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i].isActive && data[i].countryCode == "US") {
                this.salesPersons.push(
                    {
                        name: data[i]?.displayName
                    }
                );
            }
        }
    }

    primaryBusinessChecked(event) {
        // MV3-6801: Adding each item as string with ';' delimiter for later parsing when user submits application
        if (event.checked) {
            this.primaryBusiness.push(`${event.source.value};`);
            if (event.source.value == 'Other') {
                this.otherPrimaryBusiness = true;
            };
        }
        else {
            this.primaryBusiness.splice(this.primaryBusiness.indexOf(`${event.source.value};`), 1);
            if (event.source.value == 'Other') {
                this.otherPrimaryBusiness = false;
            }
        }
        this.businessInformationFormGroup.controls.primaryBusiness.setValue(this.primaryBusiness.join(','));
    }

    productChecked(event) {
        // MV3-6800: Adding each item as string with ';' delimiter for later parsing when user submits application
        if (event.checked) {
            this.products.push(`${event.source.value};`);
            if (event.source.value == 'Other') {
                this.otherProduct = true;
                this.otherProductValidation(true);
            }
        } else {
            this.products.splice(this.products.indexOf(`${event.source.value};`), 1);
            if (event.source.value == 'Other') {
                this.otherProduct = false;
                this.otherProductValidation(false);
            }
        }
        this.businessInformationFormGroup.controls.products.setValue(this.products.join(','));
    }

    otherProductValidation(isOther: boolean) { //[MV3-3974] other textbox is mandatory
        if (isOther)
            this.validationService.setFormControlValidators(this.businessInformationFormGroup.controls.otherProduct,
                Validators.compose([Validators.required, Validators.maxLength(200)]));
        else
            this.validationService.setFormControlValidators(this.businessInformationFormGroup.controls.otherProduct,
                Validators.compose([Validators.maxLength(200)]));
    }

    addHyphen(element) {        
        // const value = element.value.replace('-', '');
        // const federalTaxID = value.replace(/(\d{2})/, "$1-");        
        // this.businessInformationFormGroup.controls.federalTaxID.setValue(federalTaxID);
        const value = element.value.replace(/-/g, '');

        if (value.length > 2) {
            const federalTaxID = value.substring (0, 2) + '-'  + value.substring(2);            
            element.value = federalTaxID;
        }
    }
}
