import { Injectable } from '@angular/core';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { Observable } from 'rxjs';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';


@Injectable()
export class ClaimsService {

  constructor(private httpClientService: HttpClientService,
    private apiEndpointService: ApiEndpointService) {
  }
  public submitClaims(data){
    console.log("data", data)

        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('submitClaims'))
            .toString();
            console.log("endpoint", endpoint)
        return this.httpClientService.post<any>(endpoint, data);
  }

  

  // public getAddressBookPage(pageIndex?: string, pageSize?: string) {
  //   const params = {};
  //   if (pageIndex) {
  //     params['page'] = pageIndex;
  //   }

  //   if (pageSize) {
  //     params['per_page'] = pageSize;
  //   }

  //   const endpoint = SafeUrlAssembler()
  //     .template(this.apiEndpointService.getEndpoint('getAddressBookPage'))
  //     .query(params)
  //     .toString();
  //   return this.httpClientService.get<IContact[]>(endpoint);
  // }


 }

