import { Component, OnInit, Input, EventEmitter,  Output,OnChanges, SimpleChanges  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileClaimComponent } from './../../../claims/file-claim/file-claim.component';
import {PackageDetailsComponent} from './../package-details/package-details.component';
import { ConfirmationDialogComponent } from './../../file-claim/review-claims/confirmation-dialog/confirmation-dialog.component'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '../../../shared/models/notification-type';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { Claims } from './../../services/claims.model';
import { Router } from '@angular/router';
import { ClaimsService } from 'app/claims/services/claims.service';

@Component({
  selector: 'upsc-review-claims',
  templateUrl: './review-claims.component.html',
  styleUrls: ['./review-claims.component.scss']
})
export class ReviewClaimsComponent implements OnInit ,OnChanges{
  
  @Input() claimsData: any;
  @Input() shipperData: any;
  @Input() receiversData:any;
  @Input() merchandiseDetails:any;
  @Input() packageContent: any;
  @Input() additionalInfo: any;
  @Input() assetfileNames: any;
  @Input() recipientContacted: any;
  @Input() currentLocation: any;
  @Input() trackingNumberValue: any;
  @Input() contactVal :any;
  @Input() locationVal: any;
  @Output("packageReset") packageReset: EventEmitter<any> = new EventEmitter();
  public formGroup: UntypedFormGroup;
  public Claims: Claims = new Claims();
  seletedContact: string;
  seletedLocation: string;
  claimsValid:boolean = false;
  packageValid:boolean = false;
  disableButton = true;
  claimValue = [];
  contactedInfo: string[] = ['Yes', 'No', 'N/A'];
  locationInfo: string[] = ['Shipper', 'Destination', 'Other/Unknown'];
  claims: string[] = ['Lost', 'Missing Contents', 'Damaged'];
  constructor(public claimsfil:FileClaimComponent, public packageDetail: PackageDetailsComponent
    , private formBuilder: UntypedFormBuilder,private notificationService: NotificationService, private dialog: MatDialog, private router:Router, private claimsservice: ClaimsService) {

  }

  ngOnInit(): void {
    this.claimValue = this.claimsData;
   
    this.formGroup = this.formBuilder.group({
      isTermsAccepted: [false, Validators.compose([Validators.requiredTrue])],
    });  
  }

  ScrollIntoView(elem: string) {
    console.log(elem);
    document.querySelector(elem).scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  onCancel(){
    this.packageReset.emit();
  }
  onSubmit(){
    console.log('------review oncheck-------');
    this.Claims.ClaimDetails.TrackingNumber = this.trackingNumberValue;
    this.Claims.ClaimDetails.ShipmentID = "813D9B3-8C19-4D69-AA3B-000141EABED5";
    this.Claims.ClaimDetails.ShipDate = '2022-12-30';
    this.Claims.ClaimDetails.Lossdate = '2022-12-30';
    this.Claims.ClaimDetails.Losscause = 'loss_Ext';
    this.Claims.ClaimDetails.InsuredValue = '1000.00';
    this.Claims.ClaimDetails.Carrier = 'UPS';
    this.Claims.ClaimDetails.PackageIssue = "Marked as delivered but it wasn't";
    this.Claims.ClaimDetails.PackageDescription = 'UPS Outer Box';
    this.Claims.ClaimDetails.RecipientContacted = 'yes';
    this.Claims.ClaimDetails.PackageCurrentLocation =  'Shipper';
    this.Claims.ClaimDetails.IncidentNotes = 'IncidentNotes lost';
    this.Claims.ClaimDetails.Currency = 'USD';
    this.Claims.ClaimDetails.TotalClaimedAmount = "5000.00";
    this.Claims.Commodites = this.merchandiseDetails;
    this.Claims.ClaimantContactInfo.FirstName = this.claimsData?.firstName;
    this.Claims.ClaimantContactInfo.LastName = this.claimsData?.lastName;
    this.Claims.ClaimantContactInfo.CompanyName = this.claimsData?.companyName;
    this.Claims.ClaimantContactInfo.StreetAddress = this.claimsData?.streetAddress;
    this.Claims.ClaimantContactInfo.ApartmentSuite = this.claimsData?.apartmentSuite
    this.Claims.ClaimantContactInfo.City = this.claimsData?.city;
    this.Claims.ClaimantContactInfo.State = this.claimsData?.state;
    this.Claims.ClaimantContactInfo.Country = this.claimsData?.country;
    this.Claims.ClaimantContactInfo.Zip = this.claimsData?.zip;
    this.Claims.ClaimantContactInfo.TelephoneNo = this.claimsData?.telNum;
    this.Claims.ClaimantContactInfo.Email = this.claimsData?.email;
    this.Claims.ShipperContactInfo.FirstName = this.shipperData?.firstName;
    this.Claims.ShipperContactInfo.LastName = this.shipperData?.lastName;
    this.Claims.ShipperContactInfo.CompanyName = this.shipperData?.companyName;
    this.Claims.ShipperContactInfo.StreetAddress = this.shipperData?.streetAddress;
    this.Claims.ShipperContactInfo.ApartmentSuite = this.shipperData?.apartmentSuite
    this.Claims.ShipperContactInfo.City = this.shipperData?.city;
    this.Claims.ShipperContactInfo.State = this.shipperData?.state;
    this.Claims.ShipperContactInfo.Country = this.shipperData?.country;
    this.Claims.ShipperContactInfo.Zip = this.shipperData?.zip;
    this.Claims.ShipperContactInfo.TelephoneNo = this.shipperData?.telNum;
    this.Claims.ShipperContactInfo.Email = this.shipperData?.email;
    this.Claims.RecipientContactInfo.FirstName = this.receiversData?.firstName;
    this.Claims.RecipientContactInfo.LastName = this.receiversData?.lastName;
    this.Claims.RecipientContactInfo.CompanyName = this.receiversData?.companyName;
    this.Claims.RecipientContactInfo.StreetAddress = this.receiversData?.streetAddress;
    this.Claims.RecipientContactInfo.ApartmentSuite = this.receiversData?.apartmentSuite
    this.Claims.RecipientContactInfo.City = this.receiversData?.city;
    this.Claims.RecipientContactInfo.State = this.receiversData?.state;
    this.Claims.RecipientContactInfo.Country = this.receiversData?.country;
    this.Claims.RecipientContactInfo.Zip = this.receiversData?.zip;
    this.Claims.RecipientContactInfo.TelephoneNo = this.receiversData?.telNum;
    this.Claims.RecipientContactInfo.Email = this.receiversData?.email;
    this.claimsservice.submitClaims(this.Claims)
    .subscribe(
      (response) => {
        console.log('submitted');
        this.openConfirmationDialog();
      },
      (err) => {
          console.log(err);
          this.notificationService.notify(err.error.Message, 'Submit Failed', NotificationType.ERROR);
      })
    
    
  }
  public openConfirmationDialog() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
    };
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    this.router.events
 .subscribe(() => {
   dialogRef.close();
 });
    
  }
  public ngOnChanges(changes: SimpleChanges) {
    console.log('vivek review111')
 this.claimsValid = this.claimsfil.formvalid();
 console.log('$$$$$$$$$$$$this.claimsValid ', this.claimsValid )
   
   this.packageValid = this.packageDetail.packageFormsValid();
   console.log('$$$$$$$$$$$$ this.packageValid ', this.packageValid )
 console.log('this.currentLocation',this.currentLocation )
console.log('this.recipientContacted',this.recipientContacted )
   
if(this.claimsValid &&  this.packageValid)

this.disableButton = false;
   
  }
}
