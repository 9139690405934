import { Injectable } from "@angular/core";
@Injectable()
export class Merchandise {
    merchandiseId?: string = '';
    Commodity?: string = '';
    CommodityDescription?: string = '';
    NoOfItems?: string = '';
    Model?: string = '';
    Serial?: string = '';
    Invoice?: string = '';
    ClaimedAmount?: string = '';    
}

