import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { DwRewardshipComponent } from './account/digital-wallet/dw-rewardship/dw-rewardship.component';
import { DwRewardshipResolver } from './account/digital-wallet/dw-rewardship/dw-rewardship.resolver';
import { ManageLocationsComponent } from './account/manage-locations/manage-locations.component';
import { ManageUsersComponent } from './account/manage-users/manage-users.component';
import { RewardshipComponent } from './account/rewardship/rewardship.component';
import { RewardshipResolver } from './account/rewardship/rewardship.resolver';
import { UserSettingsComponent } from './account/user-settings/user-settings.component';
import { AddressBookComponent } from './address-book/address-book/address-book.component';
import { AddCcBounceComponent } from './billing/add-credit-card/add-cc-bounce/add-cc-bounce.component';
import { AddCreditCardComponent } from './billing/add-credit-card/add-credit-card.component';
import { CreditHistoryComponent } from './billing/credit-history/credit-history.component';
import { ManageCreditCardComponent } from './billing/manage-credit-card/manage-credit-card.component';
import { PaymentHistoryComponent } from './billing/payment-history/payment-history.component';
import { ClaimFaqComponent } from './claims/claim-faq/claim-faq.component';
import { ClaimsDashboardComponent } from './claims/claims-dashboard/claims-dashboard.component';
import { FileClaimComponent } from './claims/file-claim/file-claim.component';
import { UserRouteGuard } from './core/services/route-guards/user-route.guard';
import { CostEstimatorComponent } from './cost-estimator/cost-estimator.component';
import { DDAddressConfidenceToolComponent } from './delivery-defense/dd-address-confidence-tool/dd-address-confidence-tool.component';
import { DdManageSubscriptionComponent } from './delivery-defense/dd-manage-subscription/dd-manage-subscription.component';
import { DDScoreHistoryComponent } from './delivery-defense/dd-score-history/dd-score-history.component';
import { DeliveryDefenseFaqComponent } from './delivery-defense/delivery-defense-faq/delivery-defense-faq.component';
import { DeliveryDefenseSignUpComponent } from './delivery-defense/delivery-defense-sign-up/delivery-defense-sign-up.component';
import { BillingHistoryComponent } from './history/billing-history/billing-history.component';
import { BirComponent } from './history/bir/bir.component';
import { PackageInvestigationComponent } from './history/package-investigation/package-investigation.component';
import { RecentShipmentComponent } from './history/recent-shipment/recent-shipment.component';
import { ReportHistoryComponent } from './history/report-history/report-history.component';
import { SavedQuotesComponent } from './history/saved-quotes/saved-quotes.component';
import { SchedulePickupComponent } from './history/schedule-pickup/schedule-pickup.component';
import { ShipmentHistoryComponent } from './history/shipment-history/shipment-history.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login/login.component';
import { LoginResolver } from './login/login/login.resolver';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { LogoutComponent } from './logout/logout.component';
import { ReportShipmentsUploadComponent } from './report-shipments-upload/report-shipments-upload.component';
import { AuthGuardService } from './shared/services/auth-guard/auth-guard.service';
import { UserResolver } from './shared/services/user/user-resolver.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TermsConditionsComponent } from './terms/terms-conditions/terms-conditions.component';

export const routes: Routes = [
    { path: '', resolve: { prefetch: LoginResolver }, component: LoginComponent },
    { path: 'm', loadChildren: () => import('app/mobile-view/mobile-view.module').then(m => m.MobileViewModule) },
    { path: 'login', resolve: { prefetch: LoginResolver }, pathMatch: 'full', component: LoginComponent },
    {
        path: 'login/as/:token',
        pathMatch: 'full',
        loadChildren: () => import('app/mimic/mimic.module').then(m => m.MimicModule),
    },
    { path: 'logout', component: LogoutComponent },
    { path: 'sign-up', pathMatch: 'full', component: SignUpComponent },
    {
        path: 'dashboard', component: LayoutComponent,
        data: {
            name: 'Dashboard',
        },
        canActivate: [UserRouteGuard],
        loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'dw-dashboard',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'account-closed',
        data: {
            name: 'AccountClosed',
            showSidebar: false,
        },
        loadChildren: () => import('app/account-closed/account-closed.module').then(m => m.AccountClosedModule),
    },
    {
        path: 'account-suspended',
        data: {
            name: 'AccountSuspended',
        },
        loadChildren: () => import('app/account-suspended/account-suspended.module').then(m => m.AccountSuspendedModule),
    },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'change-password/:token', component: ResetPasswordComponent },
    {
        path: 'shipment-history', component: LayoutComponent,
        data: {
            name: 'ShipmentHistory',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: ShipmentHistoryComponent }],
    },
    {
        path: 'track-shipments',
        data: {
            name: 'TrackShipments',
        },
        loadChildren: () => import('app/track-shipments/track-shipments.module').then(m => m.TrackShipmentsModule),
    },
    {
        path: 'billing-history', component: LayoutComponent,
        data: {
            name: 'BillingHistory',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: BillingHistoryComponent }],
    },
    {
        path: 'recent-shipments',
        component: LayoutComponent,
        data: {
            name: 'RecentShipment',
        },
        canActivate: [UserRouteGuard],
        children: [{ path: '', component: RecentShipmentComponent }],
    },
    {
        path: 'pending-shipments',
        data: {
            name: 'RecentShipment',
        },
        loadChildren: () => import('app/pending-shipments/pending-shipments.module').then(m => m.PendingShipmentsModule),
    },
    {
        path: 'report-shipments',
        data: {
            name: 'ShipReport',
        },
        component: LayoutComponent,
        canActivate: [UserRouteGuard], children: [{ path: '', component: ReportHistoryComponent }],
        // loadChildren: () => import('app/report-shipments/report-shipments.module').then(m => m.ReportShipmentsModule),
    },
    {
        path: 'report-shipments-upload',
        data: {
            name: 'ShipReport',
        },
        // component: LayoutComponent,
        // canActivate: [UserRouteGuard], children: [{ path: '', component: ReportShipmentsUploadComponent }],
        resolve: { user: UserResolver },
        loadChildren: () => import('app/report-shipments/report-shipments.module').then(m => m.ReportShipmentsModule),
    },
    {
        path: 'report-history', component: LayoutComponent,
        data: {
            name: 'ReportHistory',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: ReportHistoryComponent }],
    },
    {
        path: 'schedule-pickups', component: LayoutComponent,
        data: {
            name: 'SchedulePickup',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: SchedulePickupComponent }],
    },
    {
        path: 'saved-quotes', component: LayoutComponent,
        data: {
            name: 'SavedQuotes',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: SavedQuotesComponent }],
    },
    {
        path: 'manage-locations', component: LayoutComponent,
        data: {
            name: 'ManageLocations',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: ManageLocationsComponent }],
    },
    {
        path: 'address-book', component: LayoutComponent,
        data: {
            name: 'AddressBook',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: AddressBookComponent }],
    },
    {
        path: 'claim-faq', component: LayoutComponent,
        data: {
            name: 'ClaimFAQ',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: ClaimFaqComponent }],
    },
    {
        path: 'file-claim', component: LayoutComponent,
        data: {
            name: 'FileClaim',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: FileClaimComponent }],
    },
    {
        path: 'claims-dashboard', component: LayoutComponent,
        data: {
            name: 'ClaimsDashboard',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: ClaimsDashboardComponent }],
    },
    {
        path: 'delivery-defense-faq', component: LayoutComponent,
        data: {
            name: 'DeliveryDefenseFAQ',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: DeliveryDefenseFaqComponent }],
    },
    {
        path: 'delivery-defense-sign-up', component: LayoutComponent,
        data: {
            name: 'DeliveryDefenseSignUp',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: DeliveryDefenseSignUpComponent }],
    },
    {
        path: 'manage-subscription', component: LayoutComponent,
        data: {
            name: 'ManageSubscriptions',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: DdManageSubscriptionComponent }],
    },
    {
        path: 'score-history', component: LayoutComponent,
        data: {
            name: 'ScoreHistory',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: DDScoreHistoryComponent }],
    },
    {
        path: 'address-confidence-tool', component: LayoutComponent,
        data: {
            name: 'AddressConfidenceTool',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: DDAddressConfidenceToolComponent }],
    },
    {
        path: 'user-settings', component: LayoutComponent,
        data: {
            name: 'UserSettings',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: UserSettingsComponent }],
    },
    {
        path: 'package-investigation', component: LayoutComponent,
        data: {
            name: 'PackageInvestigation',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: PackageInvestigationComponent }],
    },
    {
        path: 'company-profile',
        loadChildren: () => import('app/account/company-profile/company-profile.module').then(m => m.CompanyProfileModule),
    },
    {
        path: 'payment-history', component: LayoutComponent,
        data: {
            name: 'PaymentHistory',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: PaymentHistoryComponent }],
    },
    {
        path: 'credit-history', component: LayoutComponent,
        data: {
            name: 'CreditHistory',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: CreditHistoryComponent }],
    },
    {
        path: 'manage-credit-card', component: LayoutComponent,
        data: {
            name: 'ManageCreditCard',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: ManageCreditCardComponent }],
    },
    {
        path: 'add-credit-card', component: LayoutComponent,
        data: {
            name: 'AddCreditCard',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: AddCreditCardComponent }],
    },
    {
        path: 'rewardship', component: LayoutComponent,
        data: {
            name: 'RewardShip',
        },
        canActivate: [UserRouteGuard],
        children: [{ path: '', resolve: { prefetch: RewardshipResolver }, component: RewardshipComponent }],
    },
    {
        path: 'dw-rewardship', component: LayoutComponent,
        data: {
            name: 'RewardShip',
        },
        canActivate: [UserRouteGuard],
        children: [{ path: '', resolve: { prefetch: DwRewardshipResolver }, component: DwRewardshipComponent }],
    },
    {
        path: 'add-credit-card-bounce', component: AddCcBounceComponent,
        data: {
            name: 'AddCreditCardBounce',
        },
        canActivate: [AuthGuardService],
    },
    {
        path: 'world-ship-upload',
        data: {
            name: 'WorldShipUpload',
        },
        canActivate: [UserRouteGuard],
        loadChildren: () => import('app/world-ship-upload/world-ship-upload.module').then(m => m.WorldShipUploadModule),
    },
    {
        path: 'support',
        data: {
            name: 'Support',
        },
        canActivate: [UserRouteGuard],
        loadChildren: () => import('app/support/support.module').then(m => m.SupportModule),
    },
    {
        path: 'reports',
        data: {
            name: 'EndOfDayReports',
        },
        canActivate: [UserRouteGuard],
        loadChildren: () => import('app/reports/reports.module').then(m => m.ReportsModule),
    },
    {
        path: 'terms',
        data: {
            name: 'Terms',
        },
        canActivate: [UserRouteGuard],
        loadChildren: () => import('app/terms/terms.module').then(m => m.TermsModule),
    },
    {
        path: 'terms/terms-conditions',
        data: {
            name: 'TermsUnsigned',
        },
        canActivate: [UserRouteGuard],
        children: [{ path: '', component: TermsConditionsComponent }],
    },
    {
        path: 'ship',
        data: {
            name: 'Ship',
        },
        canActivate: [UserRouteGuard],
        loadChildren: () => import('app/ship/ship.module').then(m => m.ShipModule),
    },
    {
        path: 'bir', component: LayoutComponent,
        data: {
            name: 'BIR',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: BirComponent }],
    },
    {
        path: 'manage-users', component: LayoutComponent,
        data: {
            name: 'ManageUsers',
        },
        canActivate: [UserRouteGuard], children: [{ path: '', component: ManageUsersComponent }],
    },
    {
        path: 'cost-estimator', component: LayoutComponent,
        data: {
            name: 'CostEstimator',
        },
        canActivate: [UserRouteGuard],
        children: [{ path: '', component: CostEstimatorComponent, data: { title: 'Cost Estimator' } }],
    },
    {
        path: 'shipments',
        data: {
            name: 'Shipments',
        },
        canActivate: [UserRouteGuard],
        loadChildren: () => import('app/shipment-details/shipment-details.module').then(m => m.ShipmentDetailsModule),
    },
    {
        path: 'notify-templates',
        data: {
            name: 'NotifyTemplates',
        },
        canActivate: [UserRouteGuard],
        loadChildren: () => import('app/notify-templates/notify-templates.module').then(m => m.NotifyTemplatesModule),
    },
    {
        path: 'contactus',
        data: {
            name: 'ContactUs',
        },
        loadChildren: () => import('app/contact-us/contact-us.module').then(m => m.ContactUsModule),
    },
    {
        path: 'contact-us',
        data: {
            name: 'Contact-Us',
        },
        loadChildren: () => import('app/contact-us/contactus/contact-us-original.module').then(m => m.ContactUsOriginalModule),
    },
    {
        path: 'access-denied',
        loadChildren: () => import('app/errors/access-denied/access-denied.module').then(m => m.AccessDeniedModule),
    },
    {
        path: 'maintenance',
        loadChildren: () => import('app/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    },
    {
        path: 'e-commerce',
        loadChildren: () => import('app/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
    },
    { path: '404', loadChildren: () => import('app/errors/error404/error404.module').then(m => m.Error404Module) },
    { path: '500', loadChildren: () => import('app/errors/error500/error500.module').then(m => m.Error500Module) },
    { path: '**', redirectTo: '404' },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})

export class AppRoutingModule {
}
