import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claimsStatus'
})
export class ClaimsStatusPipe implements PipeTransform {
  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }  
  transform(value: any): any {
    switch (value) {
      case 0:
        return 'Claim Received';
      case 1:
        return 'Approved for Payment';
      case 2:
        return 'Missing Documents';
      case 3:
        return 'Investigation Pending';
      case 4:
        return 'Approved for Payment';
      case 5:
        return 'Denied';
      case 6:
        return 'Paid'; 
        case 7:
        return 'Canceled';
      case 8:
        return 'Closed';
      default:
        return value;
    }
  }

}
