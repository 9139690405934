<div class="content">
  <div class="wrapper">
    <div class="package-contents">
      <h1 class="content-header">Review Claim</h1>
      <div class="line-border"></div>
      <div class="contact-info">
        <h3>Claimant Contact Info</h3>
        <div class="contact">
          To make changes to the below information,
          please contact <a href="mailto:parcelproclaims@ups.com"> parcelproclaims&#64;ups.com</a>
        </div>
        <div class="section">
          <div class="Details1">{{claimsData?.accountNumber}} </div>
          <div class="Details">{{claimsData?.firstName}} </div>
          <div class="Details">{{claimsData?.lastName}} </div>
          <div class="Details">{{claimsData?.streetAddress}} </div>
          <div class="Details">{{claimsData?.city }} {{claimsData?.state}} </div>
          <div class="Details">{{claimsData?.zip}}{{claimsData?.country}} </div>
          <div class="Details">{{claimsData?.telNum}} </div>
          <div class="Details">{{claimsData?.email}} </div>
        </div>
      </div>

      <div class="separator"></div>
      <div class="shipment-details">
        <h3 class="shipmentDetails-Label">Shipment Detail</h3>
        <div class="content row">
          <div class="colomn col-sm-3">
            <div class="Section row">
              <h3 class="sectionLabel">Tracking Number / Bill of Lading
              </h3>
              <h3 class="sectionVal">{{trackingNumberValue}}</h3>
            </div>
            <div class="Section row">
              <h3 class="sectionLabel">Ship Date
              </h3>
              <h3 class="sectionVal">01/2/2023</h3>
            </div>
          </div>
          <div class="colomn col-sm-2">
            <div class="Section row">
              <h3 class="sectionLabel">Transportation Carrier
              </h3>
              <h3 class="sectionVal">GKH</h3>
            </div>
            <div class="Section row">
              <h3 class="sectionLabel">Insured Amount
              </h3>
              <h3 class="sectionVal">$75669</h3>
            </div>
          </div>
          <div class="colomn col-sm-3">
            <div class="action">
              <div class="valueSection">
                <h3 class="sectionLabel">Shipper’s Contact Information</h3>
              </div>
              <div class="valueSection">
                <h5 (click)="ScrollIntoView('#shipperInfo')">Edit</h5>
              </div>
            </div>

            <div class="Details">Happy Customer</div>
            <div class="Details">Jane Customer</div>
            <div class="Details">1867 Western way</div>
            <!-- <div class="Details">Torrance, CA</div>
            <div class="Details">90501, US</div>
            <div class="Details">12345566756</div>
            <div class="Details">kkjhkjkj@gmailc.om</div> -->
          </div>
          <div class="colomn col-sm-3">
            <div class="action">
              <div class="valueSection">
                <h3 class="sectionLabel">Recipient’s Contact Information</h3>
              </div>
              <div class="valueSection">
                <h5 (click)="ScrollIntoView('#receiverInfo')">Edit</h5>
              </div>
            </div>


            <div class="Details">Happy Customer</div>
            <div class="Details">Jane Customer</div>
            <div class="Details">1867 Western way</div>
            <!-- <div class="Details">Torrance, CA</div>
            <div class="Details">90501, US</div>
            <div class="Details">12345566756</div>
            <div class="Details">kkjhkjkj@gmailc.om</div> -->
          </div>
        </div>
      </div>
      <div class="separator"></div>
      <div class="addDetail">

        <!-- <button (click)="scroll($event.target)">Editd1</button> -->
        <div class="action">
          <div class="valueSection">
            <h3 class="addDetail-Label">Claim Detail</h3>
          </div>
          <div class="valueSection">
            <h5 (click)="ScrollIntoView('#claimDetail')">Edit</h5>
          </div>
        </div>

        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="claimSection">Claim Type</h3>
          </div>
          <div class="valueSection">
            <div class="detailVal">jj</div>
          </div>
        </div>

        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="claimSection">What best describes the <br> issue with the package?</h3>
          </div>
          <div class="valueSection">
            <div class="detailVal">Lostkjj</div>
          </div>
        </div>
      </div>
      <div class="separator"></div>

      <div class="packageAction">
        <div class="valueSection">
          <h3 class="addDetail-Label">Package Contents</h3>
        </div>
        <div class="valueSection">
          <h5 (click)="ScrollIntoView('#packageContents')">Edit</h5>
        </div>
      </div>



      <div class="package" *ngFor="let item of packageContent; let indexOfelement=index;">
        <div class="description-label">
          <h2 class="label">Merchandise </h2>
        </div>
        <div *ngIf="indexOfelement == 0" class="section">
          <div class="valueSection">
            <h3 class="label">Commodity</h3>
          </div>
          <div class="valueSection">
            <div class="value">{{item.Commodity}}</div>
          </div>
        </div>
        <div class="section">
          <div class="valueSection">
            <h3 class="label">Commodity Description</h3>
          </div>
          <div class="valueSection">
            <div class="value">{{item.CommodityDescription}}</div>
          </div>
        </div>
        <div class="section">
          <div class="valueSection">
            <h3 class="label">Number of Items</h3>
          </div>
          <div class="valueSection">
            <div class="value">{{item.NoOfItems}}</div>
          </div>
        </div>
        <div class="section">
          <div class="valueSection">
            <h3 class="label">Item/Model #</h3>
          </div>
          <div class="valueSection">
            <div class="value">{{item.Model}}</div>
          </div>
        </div>
        <div class="section">
          <div class="valueSection">
            <h3 class="label">Serail #</h3>
          </div>
          <div class="valueSection">
            <div class="value">{{item.Serial}}</div>
          </div>
        </div>
        <div class="section">
          <div class="valueSection">
            <h3 class="label">Invoice Value</h3>
          </div>
          <div class="valueSection">
            <div class="value">{{item.Invoice}}</div>
          </div>
        </div>
        <div class="section">
          <div class="valueSection">
            <h3 class="label">Claimed Amount</h3>
          </div>
          <div class="valueSection">
            <div class="value">{{item.ClaimedAmount}}</div>
          </div>
        </div>
      </div>

      <div class="separator"></div>

      <div class="addDetail">
        <div class="action">
          <div class="valueSection">
            <h3 class="addDetail-Label">Description of the Packaging</h3>
          </div>
          <div class="valueSection">
            <h5 (click)="ScrollIntoView('#descriptionPack')">Edit</h5>
          </div>
        </div>


        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="claimSection">Outer Box</h3>
          </div>
          <div class="valueSection">
            <div class="detailVal">{{additionalInfo?.outerBoxValue}}</div>
          </div>
        </div>

        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="claimSection">Inner Box</h3>
          </div>
          <div class="valueSection">
            <div class="detailVal">{{additionalInfo?.innerBoxValue}}</div>
          </div>
        </div>
        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="claimSection">Other</h3>
          </div>
          <div class="valueSection">
            <div class="detailVal">{{additionalInfo?.otherBoxValue}}</div>
          </div>
        </div>
      </div>

      <div class="separator"></div>
      <div class="addDetail">
        <div class="action">
          <div class="valueSection">
            <h3 class="addDetail-Label">Additional Required Information</h3>
          </div>
          <div class="valueSection">
            <h5 (click)="ScrollIntoView('#additionalInfor')">Edit</h5>
          </div>
        </div>

        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="addSection">If package has NOT been <br>
              received has the recipient <br>
              contacted?</h3>
          </div>
          <div class="valueSection">
            <div *ngIf="recipientContacted == 0" class="addDetailVal">Yes</div>
            <div *ngIf="recipientContacted == 1" class="addDetailVal">No</div>
            <div *ngIf="recipientContacted == 2" class="addDetailVal">N/A</div>
          </div>

        </div>
        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="addSection">Current Location of the Package</h3>
          </div>

          <div class="valueSection">
            <div *ngIf="currentLocation == 0" class="addDetailVal">Shipper</div>
            <div *ngIf="currentLocation == 1" class="addDetailVal">Destination</div>
            <div *ngIf="currentLocation == 2" class="addDetailVal">Other/Unknown</div>

          </div>
        </div>
        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="addSection">Detailed description of the incident</h3>
          </div>

          <div class="valueSection">
            <div class="addDetailVal">{{additionalInfo?.insidentStatement}}</div>
          </div>
        </div>
      </div>

      <div class="separator"></div>
      <div class="addDetail">
        <div class="action">
          <div class="valueSection">
            <h3 class="addDetail-Label">How much is your Claim?</h3>
          </div>
          <div class="valueSection">
            <h5 (click)="ScrollIntoView('#currencyClaim')">Edit</h5>
          </div>
        </div>

        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="amountLabel">Currency Code</h3>
          </div>
          <div class="valueSection">
            <div class="amountVal">{{additionalInfo?.currency}}</div>
          </div>
        </div>
        <div></div>
        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="amountLabel">Insured Value</h3>
          </div>
          <div class="valueSection">
            <div class="amountVal">{{additionalInfo?.insured}}</div>
          </div>
        </div>
        <div class="addDetailsection1">
          <div class="valueSection">
            <h3 class="amountLabel">Total Claimed Amount </h3>
          </div>
          <div class="valueSection">
            <div class="amountVal">{{additionalInfo?.totalAmount}}</div>
          </div>
        </div>
      </div>

      <div class="separator"></div>
      <div class="uploadFiles">
        <div class="action">
          <div class="valueSection">
            <h3 class="uploadFilesLabel">Upload Files</h3>
          </div>
          <div class="valueSection">
            <h5 (click)="ScrollIntoView('#uploadedFile')">Edit</h5>
          </div>
        </div>

        <h3 class="subLabel">Files</h3>
        <div class="section" *ngFor="let item of assetfileNames">
          <h3 class="item">{{item}}</h3>
        </div>
      </div>

      <div class="separator"></div>
      <div class="aggreement">
        <form class="ups-form" [formGroup]="formGroup">
          <div class="form-body">
            <div class="terms">
              <div class="checkBox">
                <mat-checkbox id="ConfirmCheck" formControlName="isTermsAccepted"> </mat-checkbox>
              </div>
              <div class="description">
                <h5>Subrogation Agreement</h5>
                <div class="section1">
                  Any person who knowingly and with intent to defraud any insurance company or other person files an
                  insurance claim <br>
                  containing any materially false, incomplete or misleading information and/or conceals information
                  concerning any fact<br>
                  material to the claim thereto commits a fraudulent insurance act, which is a crime and subjects that
                  person to criminal and <br>
                  civil penalties
                </div>
                <div class="section2">
                  You hereby certify that (1) you are the sole owner of this claim and this claim is free and clear of
                  any liens or encumbrances; <br>
                  (2) you have not released any third party from liability for the claim or accepted money from any third
                  party for the claim;(3)<br>
                  you are entitled to enforce the terms of any contract concerning the shipment described in the claim;
                  and (4) you have not<br>
                  released any third party that is or may be liable for the loss / damage to the shipment, including any
                  carrier, forwarder, broker <br>
                  or warehouseman. By accepting any claim payment, (a) you agree that the payment is not precedent for
                  any other or future <br>
                  claim and is without prejudice to any rights, remedies or defenses UPS Capital Insurance Agency, Inc.
                  (“UPSCIA”) has on any <br>
                  other or future claim; (b) you release UPSCIA of all liabilities arising from the shipment and claim;
                  and (c) you assign to <br>
                  UPSCIA any and all of your rights of recovery against all third parties for the loss / damage to the
                  shipment. You agree that <br>
                  UPSCIA has the right to control and pursue recovery against third parties. You agree that UPSCIA may
                  file and completely <br>
                  control any legal action against a third party in your name. You agree to assist and cooperate with
                  UPSCIA’s efforts to <br>
                  subrogate against any third party, including executing and providing witness testimony necessary to
                  file and prosecute a <br>
                  legal action. You agree that any and all funds paid to you from third parties relating to the shipment
                  and claim are the sole <br>
                  property of UPSCIA and UPSCIA has the sole right to determine how all recovered funds are applied
                  under the terms of the <br>
                  policy.
                </div>

                <div class="section3">
                  By selecting this box, I agree to the subrogation terms and certify that all information provided in
                  support of my request for <br>
                  claim paymant and all communications related to my request are true and accurate to the best of my
                  knowledge.
                </div>
              </div>
             
                
             
            </div>
          </div>


        </form>
      </div>

      <div class="button-group">

        <button type="button" (click)="onCancel()" mat-raised-button
          class="file-input-button mat-raised-button alternative-button">
          Cancel
        </button>
        <!-- <button type="button" [disabled]="disableButton" mat-raised-button (click)="onSubmit()" class="page-action">
          Submit
        </button> -->
        <button type="button" mat-raised-button (click)="onSubmit()" class="page-action">
          Submit
        </button>
      </div>




    </div>
  </div>
</div>