import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignUp } from '../model/sign-up.model';
import { ApiEndpointService } from 'app/core/services/api-endpoint.service';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SharedService {

    private _businessAddress = new BehaviorSubject<SignUp>(new SignUp());
    private _country = new BehaviorSubject<string>('US');
    public businessAddress = this._businessAddress.asObservable();
    public Country = this._country.asObservable();

    constructor(private apiEndpointService: ApiEndpointService,
                private httpClient: HttpClient
    ) { }

    setBusinessAddress(signup: SignUp) {
        this._businessAddress.next(signup);
    }

    setCustomerCountry(val: string) {
        this._country.next(val);
    }

    setHyphen(element, country) {
        if (country.toLowerCase() === 'us' || country.toLowerCase() === 'ca') {
            
            const value = element.value.replace(/-/g, '');

            if (value.length > 6) {
                const formattedValue = value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6);
                element.value = formattedValue;
            }
        }
    }

    public getSalesNameDropdown(): Observable<any> {
        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('getSalesPersons'))
            .toString();
        return this.httpClient.get<any>(endpoint);
    }
}
