import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SignUp } from './model/sign-up.model';
import { SharedService } from './services/shared.service';
import { SignUpOriginHandlerService } from './services/sign-up-origin-handler.service';
import { SignUpService } from './services/signup.service';
import { NotificationService } from '../shared/services/notification/notification.service';
import { NotificationType } from '../shared/models/notification-type';
import { GetIPService } from '../shared/services/get-ip/get-ip.service';

@Component({
    selector: 'upsc-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SignUpComponent implements OnInit, AfterViewInit {

    @ViewChild('recaptcha') recaptchaElement: ElementRef;


    public submitted = false;
    public businessInformationFormGroup: UntypedFormGroup;
    public accountInformationFormGroup: UntypedFormGroup;
    public billingInformationFormGroup: UntypedFormGroup;
    public insuranceFormGroup: UntypedFormGroup;
    public allowSubmit = false;
    public isMJSA = false;
    public welcomeMessage: string;

    private signUpParams: Params;
    public isSubmitting: boolean = false;

    public constructor(private formBuilder: UntypedFormBuilder,
                       public signUp: SignUp,
                       private router: Router,
                       private route: ActivatedRoute,
                       private signUpService: SignUpService,
                       private readonly signUpOriginHandlerService: SignUpOriginHandlerService,
                       private notificationService: NotificationService,
                       private getIPService: GetIPService,
                       private sharedService: SharedService,
    ) { }

    public ngOnInit(): void {
        this.getUserIP();
        this.route.queryParams.subscribe(async params => {
            if (!params) {
                return;
            }

            this.signUpParams = params;

            this.isMJSA = params.ref === 'MJSA';
            this.welcomeMessage = params.origin
                ? await this.signUpOriginHandlerService.getWelcomeMessage(params.origin)
                : null;
        });
    }

    ngAfterViewInit() {
        this.addRecaptchaScript();
    }

    addRecaptchaScript() {

        window['grecaptchaCallback'] = () => {
            this.renderReCaptcha();
        };

        (function (d, s, id, obj) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { obj.renderReCaptcha(); return; }
            js = d.createElement(s); js.id = id;
            js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk', this));

    }

    public renderReCaptcha() {
        window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
            'sitekey': '6Ldm1twZAAAAAMH-9hQB_Bw1aIMsExOEkcHOVIyL',
            'callback': (response) => {
                this.allowSubmit = true;
            },
        });
    }

    public submitApplication() {
        if (this.allowSubmit) {
            this.isSubmitting = true;
            this.setOtherValues();

            // MV3-6801: Customer signup API is failing due to incorrect data type for PrimaryBiz
            const primaryBusinessArray = this.businessInformationFormGroup.controls.primaryBusiness.value.split(';,') as string[];
            // Remove ending ';' character from final element in list
            primaryBusinessArray[primaryBusinessArray?.length - 1] = primaryBusinessArray[primaryBusinessArray?.length - 1].slice(0, -1);
            const primaryIndex = primaryBusinessArray.indexOf('Other');
            if (primaryIndex !== -1) {
                const otherValue = this.businessInformationFormGroup.controls.otherPrimaryBusiness.value.trim() !== ''
                    ? `Other: ${this.businessInformationFormGroup.controls.otherPrimaryBusiness.value}`
                    : 'Other';
                primaryBusinessArray[primaryIndex] = otherValue;
                this.businessInformationFormGroup.controls.primaryBusiness.setValue(primaryBusinessArray);
            }
            this.signUp.PrimaryBiz = primaryBusinessArray.slice();

            this.signUpService.registerUser(this.signUp)
                .pipe(
                    catchError((error) => {
                        this.notificationService.notify(error.error.message, 'Error Submitting Application', NotificationType.ERROR);
                        this.isSubmitting = false;
                        return of(null);
                    }),
                )
                .subscribe(response => {
                    if (!response) {
                        return;
                    }

                    this.submitted = true;
                    this.isSubmitting = false;
                });
        } else {
            this.notificationService.notify('Confirm you are not a bot by checking the bot checkbox.', '', NotificationType.ERROR);
        }
    }

    getUserIP() {
        this.getIPService.getUserIP().subscribe((value) => {
            this.signUp.IpAddress = value.ip;
        }, (error) => {
            console.warn(error);
        });
    }

    businessInfoFormChanged(event) {
        this.businessInformationFormGroup = event;
        this.mapBusinessInformation();
    }
    accountInfoFormChanged(event) {
        this.accountInformationFormGroup = event;
        this.mapAccountInformation();
    }
    billingInfoFormChanged(event) {
        this.billingInformationFormGroup = event;
        this.mapBillingInformation();
    }
    insuranceFormChanged(event) {
        this.insuranceFormGroup = event;
        this.mapInsuranceInformation();
    }

    parseBusinessInformationFormGroupAffilicationValue(): string {
        let affilicationValue = this.businessInformationFormGroup.controls.affilication.value;
        if (affilicationValue === "(None) None") {
            affilicationValue = "None";
        }
        return affilicationValue;
    }

    mapBusinessInformation() {
        this.signUp.HowDidYouHear = this.businessInformationFormGroup.controls.howDidYouHear.value == 'Other' ? this.businessInformationFormGroup.controls.howDidYouHearOther.value : this.businessInformationFormGroup.controls.howDidYouHear.value;
        this.signUp.SalesPersonName = this.businessInformationFormGroup.controls.salesName?.value;
        this.signUp.MemberAffiliateProg = this.parseBusinessInformationFormGroupAffilicationValue();
        this.signUp.DBA = this.businessInformationFormGroup.controls.DBA.value;
        this.signUp.FirstName = this.businessInformationFormGroup.controls.firstName.value;
        this.signUp.LastName = this.businessInformationFormGroup.controls.lastName.value;
        this.signUp.CompanyName = this.businessInformationFormGroup.controls.companyName.value;
        this.signUp.FederalTaxID = this.businessInformationFormGroup.controls.federalTaxID.value.replace('-', '');
        this.signUp.YearsInBusiness = this.businessInformationFormGroup.controls.yearsInBusiness.value.trim() == '' ? 0 : this.businessInformationFormGroup.controls.yearsInBusiness.value;
        this.signUp.YearIncorporated = this.businessInformationFormGroup.controls.yearIncorporated.value;
        this.signUp.NumberOfEmployees = this.businessInformationFormGroup.controls.numberOfEmployees.value;
        this.signUp.NumberOfLocations = this.businessInformationFormGroup.controls.numberOfLocations.value;
        this.signUp.TypeOfBiz = this.businessInformationFormGroup.controls.typeofBusiness.value;
        this.signUp.PrimaryBiz = this.businessInformationFormGroup.controls.primaryBusiness.value;
        this.signUp.Products = this.businessInformationFormGroup.controls.products.value;
        this.signUp.Country = this.businessInformationFormGroup.controls.businessLocation.value;
        this.signUp.BusinessOwner = this.businessInformationFormGroup.controls.businessOwner.value;
        this.signUp.BusinessToBusinessPercent = this.businessInformationFormGroup.controls.businessToBusinessPercent.value;
        this.signUp.BusinessToConsumerPercent = this.businessInformationFormGroup.controls.businessToConsumerPercent.value;
        this.signUp.ReturnPercent = this.businessInformationFormGroup.controls.returnPercent.value;
        this.signUp.NewPercent = this.businessInformationFormGroup.controls.newPercent.value;
        this.signUp.UsedPercent = this.businessInformationFormGroup.controls.usedPercent.value;
        this.sharedService.setBusinessAddress(this.signUp);
    }

    mapAccountInformation() {
        this.signUp.Address1 = this.accountInformationFormGroup.controls.streetAddress.value;
        this.signUp.Address2 = this.accountInformationFormGroup.controls.suiteApt.value;
        this.signUp.City = this.accountInformationFormGroup.controls.city.value;
        this.signUp.StateProvince = this.accountInformationFormGroup.controls.stateProvince.value;
        this.signUp.Zip = this.accountInformationFormGroup.controls.zip.value;
        this.signUp.Website = this.accountInformationFormGroup.controls.website.value;
        this.signUp.PhoneNum = this.accountInformationFormGroup.controls.phoneNumber.value;
        this.signUp.FaxNum = this.accountInformationFormGroup.controls.faxNumber?.value;
        this.signUp.Email = this.accountInformationFormGroup.controls.email?.value;
        this.signUp.ContactPref = this.accountInformationFormGroup.controls.contactPreference?.value;
        this.signUp.RequestedService = this.accountInformationFormGroup.controls.requestedService?.value;
        this.sharedService.setBusinessAddress(this.signUp);
    }

    mapBillingInformation() {
        this.signUp.BillingContact = this.billingInformationFormGroup.controls.contactName.value;
        this.signUp.BillingAddress1 = this.billingInformationFormGroup.controls.billingAddress['controls'].streetAddress.value;
        this.signUp.BillingAddress2 = this.billingInformationFormGroup.controls.billingAddress['controls'].suiteApt.value;
        this.signUp.BillingCity = this.billingInformationFormGroup.controls.billingAddress['controls'].city.value;
        this.signUp.BillingState = this.billingInformationFormGroup.controls.billingAddress['controls'].stateProvince.value;
        this.signUp.BillingZip = this.billingInformationFormGroup.controls.billingAddress['controls'].zip.value;
        this.signUp.BillingCountry = this.billingInformationFormGroup.controls.billingAddress['controls'].country.value;
        this.signUp.BillingPhone = this.billingInformationFormGroup.controls.phoneNumber.value;
        this.signUp.PaymentType = this.billingInformationFormGroup.controls.paymentType.value;
    }

    mapInsuranceInformation() {
        this.signUp.ShippingFrequencyPackagesShipped = this.insuranceFormGroup.controls.shippingFrequencyPackagesShipped.value;
        
        this.signUp.ClaimHistory1_Year = this.insuranceFormGroup.controls.claimHistory1Year.value;
        this.signUp.ClaimHistory1_Courier = this.insuranceFormGroup.controls.claimHistory1Courier.value;
        this.signUp.ClaimHistory1_ValueInsured = this.insuranceFormGroup.controls.claimHistory1InsuredValue.value;
        this.signUp.ClaimHistory1_AmtPaidByInsurer = this.insuranceFormGroup.controls.claimHistory1AmountPaidByInsurer.value;
        this.signUp.ClaimHistory2_Year = this.insuranceFormGroup.controls.claimHistory2Year.value;
        this.signUp.ClaimHistory2_Courier = this.insuranceFormGroup.controls.claimHistory2Courier.value;
        this.signUp.ClaimHistory2_ValueInsured = this.insuranceFormGroup.controls.claimHistory2InsuredValue.value;
        this.signUp.ClaimHistory2_AmtPaidByInsurer = this.insuranceFormGroup.controls.claimHistory2AmountPaidByInsurer.value;
        this.signUp.ClaimHistory3_Year = this.insuranceFormGroup.controls.claimHistory3Year.value;
        this.signUp.ClaimHistory3_Courier = this.insuranceFormGroup.controls.claimHistory3Courier.value;
        this.signUp.ClaimHistory3_ValueInsured = this.insuranceFormGroup.controls.claimHistory3InsuredValue.value;
        this.signUp.ClaimHistory3_AmtPaidByInsurer = this.insuranceFormGroup.controls.claimHistory3AmountPaidByInsurer.value;
        this.signUp.BizRef1_Company = this.insuranceFormGroup.controls.BusinessRef1Company.value;
        this.signUp.BizRef1_Contact = this.insuranceFormGroup.controls.BusinessRef1Contact.value;
        this.signUp.BizRef1_YearsKnown = this.insuranceFormGroup.controls.BusinessRef1YearsKnown.value;
        this.signUp.BizRef1_Phone = this.insuranceFormGroup.controls.BusinessRef1Phone.value;
        this.signUp.BizRef2_Company = this.insuranceFormGroup.controls.BusinessRef2Company.value;
        this.signUp.BizRef2_Contact = this.insuranceFormGroup.controls.BusinessRef2Contact.value;
        this.signUp.BizRef2_YearsKnown = this.insuranceFormGroup.controls.BusinessRef2YearsKnown.value;
        this.signUp.BizRef2_Phone = this.insuranceFormGroup.controls.BusinessRef2Phone.value;
        this.signUp.BizRef3_Company = this.insuranceFormGroup.controls.BusinessRef3Company.value;
        this.signUp.BizRef3_Contact = this.insuranceFormGroup.controls.BusinessRef3Contact.value;
        this.signUp.BizRef3_YearsKnown = this.insuranceFormGroup.controls.BusinessRef3YearsKnown.value;
        this.signUp.BizRef3_Phone = this.insuranceFormGroup.controls.BusinessRef3Phone.value;
        this.signUp.VerifiedBy = this.insuranceFormGroup.controls.title.value + ' ' + this.insuranceFormGroup.controls.fullName.value;
    
        this.signUp.AverageValuePerPackage = this.insuranceFormGroup.controls.averageValuePerPackage?.value;
        this.signUp.MaximumValuePerPackage = this.insuranceFormGroup.controls.maximumValuePerPackage?.value;
        this.signUp.MaximumValueFromSingleLocation = this.insuranceFormGroup.controls.maximumValueFromSingleLocation?.value;
        this.signUp.MaximumValueToSingleLocation = this.insuranceFormGroup.controls.maximumValueToSingleLocation?.value;
        this.signUp.TotalAnnualPackagesInsured = this.insuranceFormGroup.controls.totalAnnualPackagesInsured?.value;
        this.signUp.TotalAnnualSales = this.insuranceFormGroup.controls.totalAnnualSales?.value;
        this.signUp.DomesticPercent = this.insuranceFormGroup.controls.domesticPercent?.value;
        this.signUp.InternationalPercent = this.insuranceFormGroup.controls.internationalPercent?.value;
        this.signUp.AirTransportationPercent = this.insuranceFormGroup.controls.airTransportationPercent?.value;
        this.signUp.GroundTransportationPercent = this.insuranceFormGroup.controls.groundTransportationPercent?.value;
        this.signUp.NoClaimsLast24Months = this.insuranceFormGroup.controls.noClaimsLast24Months?.value;
    }

    setOtherValues() {
        const browser = this.getBrowser();
        this.signUp.Browser = browser.name + ' Version:' + browser.version;

        // MV3-6800: Customer signup API is failing due to incorrect data type for Products
        const productsArray = this.businessInformationFormGroup.controls.products.value.split(";,") as string[];
        // Remove ending ';' character from final element in list
        productsArray[productsArray?.length - 1] = productsArray[productsArray?.length - 1].slice(0, -1);
        const productIndex = productsArray.indexOf('Other');
        if (productIndex !== -1) {
            const otherValue = this.businessInformationFormGroup.controls.otherProduct.value.trim() !== ''
                ? `Other: ${this.businessInformationFormGroup.controls.otherProduct.value}`
                : 'Other';
            productsArray[productIndex] = otherValue;
            this.businessInformationFormGroup.controls.products.setValue(productsArray);
        }
        this.signUp.Products = productsArray.slice();

        this.signUp.FederalTaxID = +this.signUp.FederalTaxID;

        this.signUp.reference = this.signUpParams.reference;
        this.signUp.campaignId = this.signUpParams.campaign_id || this.signUpParams.campaignId;
        this.signUp.origin = this.signUpParams.origin;
    }

    public cancelSignup() {
        this.router.navigate(['/login']);
    }

    goHome() {
        window.location.href = 'https://www.parcelpro.com/';
    }

    getBrowser() {
        let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR|Edge\/(\d+)/);
            if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return {
            name: M[0],
            version: M[1],
        };
    }

    public getCurrentYear(): number {
        return new Date().getFullYear();
    }
}
