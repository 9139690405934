import { NgModule } from '@angular/core';
import { ClaimsStatusPipe } from '../claim-status/claims-status.pipe';
@NgModule({
    imports: [],
    declarations: [ClaimsStatusPipe],
    providers: [ClaimsStatusPipe],
    exports: [ClaimsStatusPipe],
  })
  export class claimsStatusModule {
  }
  