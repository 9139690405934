<div #claimPackageDetails class="content">
    <div class="wrapper">
        <div class="package-contents">
            <h1 class="content-header">Package Contents</h1>
            <div class="line-border"></div>
            <div  id="claimDetail" class="claim-type">
                <h2>Claim Type</h2>
                <mat-radio-group class="example-radio-group" [(ngModel)]="claimType">
                    <mat-radio-button class="example-radio-button" *ngFor="let claim of claims" [value]="claim">
                        <span style=" margin-left: 25px;font-weight: 700;"> {{claim}} </span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="claims-info">
                <p>For claims regarding damage or missing contents,<br>
                    retain the merchandise and original packaging until <br> the claim is resolved.</p>
            </div>
            <div *ngIf="claimType == claims[0]" class="claim-lost">
                <h2 class="content-header">What best describes the issue with the package?</h2>
                <mat-radio-group class="example-radio-group" [(ngModel)]="issueDescription">
                    <mat-radio-button class="example-radio-button" *ngFor="let issue of issueType" [value]="issue">
                        <span style=" margin-left: 25px;font-weight: 700;"> {{issue}}</span>
                    </mat-radio-button>
                </mat-radio-group>

            </div>
            <div *ngIf="helpHide" class="help">
                <span><button type="button" class="helpPad" (click)="helpClose($event)" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button></span>
                <h5>Detailed item descriptions are needed for all Parcel Pro claims <br>
                    to expedite processing. Be as specific as possible (product <br>
                    name, brand name, serial number, size, color, etc.) Additional <br>
                    items must be added separately, not to exceed five. If your <br>
                    claim contains more than five items, please contact
                    <br> <a href="mailto:parcelproclaims@ups.com">parcelproclaims&#64;ups.com</a> after submitting your
                    claim.
                </h5>
            </div>

            <div id="DescriptionDeatils" class="merchandise-desc">
                <div class="description-label">
                    <h2 class="sectionCounter">Merchandise Description</h2>
                    <span><img *ngIf="descriptionSummary1" id="editDetails1" (click)="edit($event)" class="edit-icon1"
                            src="/assets/icons/edit.png" alt="edit-icon"></span>
                </div>
                <div>
                    <div *ngIf="noOfDescription == 0 || reseted">
                        <div class="details">
                            <h2 class="content-header detail">Detailed Merchandise Description</h2>
                            <span> <img class="question-circle" (click)="helpOpen($event)"
                                    src="../../../../assets/icons/question-circle.svg" alt="questioncircle" /></span>
                        </div>
                        <div>
                            <h2 class="commodity-header">Select the commodity that accounts for the highest value in
                                your claimed amount</h2>
                        </div>
                    </div>
                    <div id="packageContents">
                        <ng-container [ngTemplateOutlet]="Description1"></ng-container>
                    </div>
                </div>
            </div>
            <div>
                <form class="ups-form" #forms [formGroup]="AdditionalInformation">
                    <h3 class="packingLabel content-header"> Description of the Packing</h3>
                    <div  id="descriptionPack" class="packingSection">
                        <div class="checkboxSection">
                            <div class="example-section ">
                                <div class="YesCheckbox">
                                    <input class="largerCheckbox" type="checkbox" name="test"
                                        [checked]="outerBoxChecked === check_box_type.Yes"
                                        (click)="outerCheckBox(check_box_type.Yes)"><Span class="checkboxVal">Yes
                                    </Span>
                                </div>
                                <span>
                                    <div>
                                        <input class="largerCheckbox" type="checkbox" name="test"
                                            [checked]="outerBoxChecked === check_box_type.No"
                                            (click)="outerCheckBox(check_box_type.No)"><Span class="checkboxVal">No
                                        </Span>
                                    </div>
                                </span>

                            </div>
                            <span>
                                <div class="descriptionLabel">
                                    <h2 class="Label">Outer Box (E.g. UPS Medium, 10x10x10 Brown Box.. etc.)</h2>
                                </div>
                                <span>
                                    <div *ngIf="outerBoxChecked === check_box_type.Yes" class="inputBox">
                                        <!-- <mat-form-field>
                                            <span> <img *ngIf="AdditionalInformation.controls.outerBoxValue.valid"
                                                    class="doneIcon" src="../../../../assets/icons/tick.png" alt="tick"></span>
                                            <input matInput required type="text" maxlength="20" placeholder=""
                                                formControlName="outerBoxValue" autocomplete="off">
                                            <mat-error *ngIf="AdditionalInformation.controls.outerBoxValue?.errors">
                                                <span
                                                    *ngIf="AdditionalInformation.controls.outerBoxValue?.errors?.required">Required</span>
                                            </mat-error>
                                        </mat-form-field> -->
                                        <input type="text" id="fname" formControlName="outerBoxValue">
                                        <span> <img *ngIf="AdditionalInformation.controls.outerBoxValue.valid"
                                                class="ticksIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <h5 style="font-size: 9px;color:red" class="warning"
                                            *ngIf="AdditionalInformation.controls.outerBoxValue.touched && !AdditionalInformation.controls.outerBoxValue.valid">
                                            Required</h5>
                                        <h5 class="maxCharcters">Max Character: 20</h5>
                                    </div>
                                </span>

                            </span>


                        </div>

                    </div>
                    <!-- 2222222 -->
                    <div class="packingSection">
                        <div class="checkboxSection">
                            <div class="example-section ">
                                <div class="YesCheckbox">
                                    <input class="largerCheckbox" type="checkbox" name="test"
                                        [checked]="innerBoxChecked === check_box_type.Yes"
                                        (click)="innerCheckBox(check_box_type.Yes)"><Span class="checkboxVal">Yes
                                    </Span>
                                </div>
                                <span>
                                    <div>
                                        <input class="largerCheckbox" type="checkbox" name="test"
                                            [checked]="innerBoxChecked === check_box_type.No"
                                            (click)="innerCheckBox(check_box_type.No)"><Span class="checkboxVal">No
                                        </Span>
                                    </div>
                                </span>

                            </div>
                            <span>
                                <div class="descriptionLabel">
                                    <h2 class="Label">Inner Box (E.g. UPS Small, 5x5x5 Brown Box.. etc.)</h2>
                                </div>
                                <span>
                                    <div *ngIf="innerBoxChecked === check_box_type.Yes" class="inputBox">
                                        <input type="text" id="fname" formControlName="innerBoxValue">
                                        <span> <img *ngIf="AdditionalInformation.controls.innerBoxValue.valid"
                                                class="ticksIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <h5 style="font-size: 9px;color:red" class="warning"
                                            *ngIf="AdditionalInformation.controls.innerBoxValue.touched && !AdditionalInformation.controls.innerBoxValue.valid">
                                            Required</h5>
                                        <h5 class="maxCharcters">Max Character: 20</h5>
                                    </div>
                                </span>

                            </span>


                        </div>

                    </div>
                    <!-- 3333333 -->
                    <div class="packingSection">
                        <div class="checkboxSection">
                            <div class="example-section ">
                                <div class="YesCheckbox">
                                    <input class="largerCheckbox" type="checkbox" name="test"
                                        [checked]="otherBoxChecked === check_box_type.Yes"
                                        (click)="otherCheckBox(check_box_type.Yes)"><Span class="checkboxVal">Yes
                                    </Span>
                                </div>
                                <span>
                                    <div>
                                        <input class="largerCheckbox" type="checkbox" name="test"
                                            [checked]="otherBoxChecked === check_box_type.No"
                                            (click)="otherCheckBox(check_box_type.No)"><Span class="checkboxVal">No
                                        </Span>
                                    </div>
                                </span>

                            </div>
                            <span>
                                <div class="descriptionLabel">
                                    <h2 class="Label">Other (E.g. Watch Box, Padded Pak, Envelope, Bag..etc.)</h2>
                                </div>
                                <span>
                                    <div *ngIf="otherBoxChecked === check_box_type.Yes" class="inputBox">
                                        <input type="text" id="fname" formControlName="otherBoxValue">
                                        <span> <img *ngIf="AdditionalInformation.controls.otherBoxValue.valid"
                                                class="ticksIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <h5 style="font-size: 9px;color:red" class="warning"
                                            *ngIf="AdditionalInformation.controls.otherBoxValue.touched && !AdditionalInformation.controls.otherBoxValue.valid">
                                            Required</h5>
                                        <h5 class="maxCharcters">Max Character: 20</h5>
                                    </div>
                                </span>

                            </span>


                        </div>

                    </div>
                    <div id="additionalInfor" class="descriptionPacking">
                        <h3 class="packingLabel content-header">Additional Required Information</h3>
                        <div class="descriptionPackingLabel">
                            <h2 class="Label">If package has NOT been received has the recipient contacted?</h2>
                        </div>
                        <span class="infoGroup">
                            <form [formGroup]="eventEditForm" >
                                <mat-radio-group  [(ngModel)]="selectedContact" formControlName="contact">
                                    <mat-radio-button  [value]="0">Yes</mat-radio-button>
                                    <mat-radio-button [value]="1">No</mat-radio-button>
                                    <mat-radio-button [value]="2">N/A</mat-radio-button>                
                                    
                                </mat-radio-group>
                            </form>
                        </span>
                    </div>
                    <div class="descriptionPacking">
                        <h3 class="packingLabel content-header">Additional Required Information</h3>
                        <div class="descriptionPackingLabel1">
                            <h2 class="Label">Where is the current location of the package?</h2>
                        </div>
                        <span class="infoGroup">
                            <form [formGroup]="eventEditForm" >
                            <mat-radio-group  [(ngModel)]="selectedlocation" formControlName="location">
                                <mat-radio-button  [value]="0">Shipper</mat-radio-button>
                                <mat-radio-button [value]="1">Destination</mat-radio-button>
                                <mat-radio-button [value]="2">Other/Unknown</mat-radio-button>                
                                
                            </mat-radio-group>
                        </form>
                            <!-- (change)="radioButtonChanges($event)" -->
                            <!-- <div  id="claimDetail" class="claim-type">
                                <h2>Claim Type</h2>
                                <mat-radio-group class="example-radio-group" [(ngModel)]="claimType">
                                    <mat-radio-button class="example-radio-button" *ngFor="let claim of claims" [value]="claim">
                                        <span style=" margin-left: 25px;font-weight: 700;"> {{claim}} </span>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div> -->
                        </span>
                        
                    </div>
                    <div class="insidentStatement">
                        <h3 class="statementLabel">Please provide a detailed statement of the incident :</h3>
                        <span> <img *ngIf="AdditionalInformation.controls.insidentStatement.valid" class="doneIcon"
                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                        <div class="description">
                            <textarea formControlName="insidentStatement" matInput  rows="5" cols="70">
                        </textarea>
                            <h5 class="maxCharcters">Max Characters: 250</h5>
                        </div>

                    </div>
                    <!-- <label class="commodityDescription">Commodity Description</label>
                    <span> <img *ngIf="DescriptionformGroup1.controls.description.valid" class="doneIcon"
                            src="../../../../assets/icons/tick.png" alt="tick"></span>
                    <div class="description">
                        <textarea formControlName="description" matInput required rows="5" cols="70">
                    </textarea>
                    </div>
                    <span style="font-size: 9px;color:red"
                        *ngIf="DescriptionformGroup1.controls.description.touched && !DescriptionformGroup1.controls.description.valid">Commodity
                        Description Required</span> -->

                    <div id="currencyClaim" class="claimInputs">
                        <h3 class="claimLabel content-header">How much is your claim?</h3>
                        <!-- //AdditionalInformation.controls.otherBoxValue.val -->
                        <div class="row">
                            <div class="column">
                                <mat-form-field>
                                    <span> <img *ngIf="AdditionalInformation.controls.currency.valid" class="doneIcon1"
                                            src="../../../../assets/icons/tick.png" alt="tick"></span>
                                    <input matInput required type="text" name="Currency" placeholder="Choose Currency"
                                        formControlName="currency" autocomplete="off">
                                    <mat-error *ngIf="AdditionalInformation.controls.currency?.errors">
                                        <span *ngIf="AdditionalInformation.controls.currency?.errors?.required">
                                            Currency Required</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="column">
                                <mat-form-field>
                                    <span> <img *ngIf="AdditionalInformation.controls.insured.valid" class="doneIcon1"
                                            src="../../../../assets/icons/tick.png" alt="tick"></span>
                                    <input required matInput type="text" name="Insured Value" maxlength="10"
                                        placeholder="Insured Value" formControlName="insured" autocomplete="off">
                                    <mat-error *ngIf="AdditionalInformation.controls.insured?.errors">
                                        <span *ngIf="AdditionalInformation.controls.insured?.errors?.required">
                                            Insured Value Required</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="column">
                                <mat-form-field>
                                    <span> <img *ngIf="AdditionalInformation.controls.totalAmount.valid" class="doneIcon1"
                                            src="../../../../assets/icons/tick.png" alt="tick"></span>
                                    <input required matInput type="text" name="TotalAmount" maxlength="10"
                                        placeholder="Total Claimed Amount" formControlName="totalAmount"
                                        autocomplete="off">
                                    <mat-error *ngIf="AdditionalInformation.controls.totalAmount?.errors">
                                        <span *ngIf="AdditionalInformation.controls.totalAmount?.errors?.required">
                                            Total Claimed Amount Required</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                    <div id="uploadedFile" class="uploadFiles">
                        <h3 class="uploadHeader">Upload Files</h3>
                        <div class="separator"></div>
                        <h5 class="label">You may upload up to 10 attachments. Each file cannot exceed 5MB. File types
                            such as jpg, pdf, docx, and xls are accepted. <br> Don’t worry if you don’t have all the
                            documents ready right now. Your adjuster will let you know if additional documents are <br>
                            needed.</h5>
                        <h5 class="documentLabel">Documents that are likely to be needed:
                        </h5>

                        <div class="file-selector-wrapper">
                            <span>
                                <h3 class="label">Original Invoice</h3>
                            </span>
                            <upsc-file-selector id="invoice" [label]="'Upload'" [clearFileControl]="true"
                                (fileSelectionChanged)="uploadFiles($event,'invoice')"></upsc-file-selector>
                            <div class="file-name form-action" *ngIf="uploadedFile1">{{ uploadedFile1 }}</div>

                            <span>
                                <img class="delete-icon" *ngIf="uploadedFile1" (click)="removeFile('invoice')"
                                    src="../../../../assets/icons/delete_Icon.png" alt="FedEx">
                            </span>

                        </div>
                        <div class="file-selector-wrapper">

                            <span>
                                <h3 class="label">Photograph</h3>
                            </span>
                            <upsc-file-selector id="photograph" [label]="'Upload'" [clearFileControl]="true"
                                (fileSelectionChanged)="uploadFiles($event,'photograph')"></upsc-file-selector>
                            <div class="file-name form-action" *ngIf="uploadedFile2">{{ uploadedFile2 }}</div>

                            <span>
                                <img class="delete-icon" *ngIf="uploadedFile2" (click)="removeFile('photograph')"
                                    src="../../../../assets/icons/delete_Icon.png" alt="FedEx">
                            </span>

                        </div>

                        <div class="file-selector-wrapper">
                            <span>
                                <h3 class="label">Repair Estimate</h3>
                            </span>
                            <upsc-file-selector id="estimate" [label]="'Upload'" [clearFileControl]="true"
                                (fileSelectionChanged)="uploadFiles($event,'estimate')"></upsc-file-selector>
                            <div class="file-name form-action" *ngIf="uploadedFile3">{{ uploadedFile3 }}</div>

                            <span>
                                <img class="delete-icon" *ngIf="uploadedFile3" (click)="removeFile('estimate')"
                                    src="../../../../assets/icons/delete_Icon.png" alt="FedEx">
                            </span>
                        </div>
                        <div class="file-selector-wrapper">
                            <span>
                                <h3 class="label">Other</h3>
                            </span>
                            <upsc-file-selector id="other" [label]="'Upload'" [clearFileControl]="true"
                                (fileSelectionChanged)="uploadFiles($event,'other')"></upsc-file-selector>
                            <div class="file-name form-action" *ngIf="uploadedFile4">{{ uploadedFile4 }}</div>

                            <span>
                                <img class="delete-icon" *ngIf="uploadedFile4" (click)="removeFile('other')"
                                    src="../../../../assets/icons/delete_Icon.png" alt="delete">
                            </span>
                        </div>
                        <h3 class="photoLabel">Take 6 photos of your package (as shown below)</h3>
                        <div class="row">
                            <div class="col-sm-2.5 box">

                                <div class="image-upload" style="height: 44px;cursor: pointer;">
                                    <label for="file-input1">
                                        <img class="plus" *ngIf="!uploadedImage1" src="../../../../assets/icons/icons8-plus-20.png"
                                            alt="plus">                                            
                                    </label>
                                    <img class="minus" *ngIf="uploadedImage1" (click)="removeFile('label')" src="../../../../assets/icons/icons8-minus-20.png"
                                    alt="minus">
                                    <input id="file-input1" type="file" #fileControl1
                                 accept="{{ fileTypes }}" (change)="onFileSelectionChanged($event, 'label')">
                                </div>
                                
                                <img class="item" src="../../../../assets/icons/label_1.png" alt="label">
                                <div class="imageLabel">Shipping label with <br>tracking number<br> ( jpg or pdf)</div>
                                <span class="attached" style="margin-top: 43px;">{{uploadedImage1}}</span>
                            </div>

                            <div class="col-sm-2.5 box">
                                <div class="image-upload" style="height: 44px;cursor: pointer;">
                                    <label for="file-input2">
                                        <img class="plus" *ngIf="!uploadedImage2" src="../../../../assets/icons/icons8-plus-20.png"
                                            alt="plus">                                            
                                    </label>
                                    <img class="minus" *ngIf="uploadedImage2" (click)="removeFile('sides')" src="../../../../assets/icons/icons8-minus-20.png"
                                            alt="minus">
                                    <input id="file-input2" type="file" #fileControl2 
                                accept="{{ fileTypes }}"  (change)="onFileSelectionChanged($event, 'sides')">
                                </div>
                               
                                <img class="item" src="../../../../assets/icons/Samll_Box.png" alt="Samll_Box">
                                <div class="imageLabel">Top and 2 sides of<br>
                                    the box</div>
                                <span class="attached">{{uploadedImage2}}</span>
                            </div>

                            <div class="col-sm-2.5 box">
                                <div class="image-upload" style="height: 44px;cursor: pointer;">
                                    <label for="file-input3">
                                        <img class="plus" *ngIf="!uploadedImage3" src="../../../../assets/icons/icons8-plus-20.png"
                                            alt="plus">
                                    </label>
                                    <img class="minus" *ngIf="uploadedImage3" (click)="removeFile('Bottom')" src="../../../../assets/icons/icons8-minus-20.png"
                                    alt="minus">
                                    <input id="file-input3" type="file" #fileControl 
                                accept="{{ fileTypes }}" (change)="onFileSelectionChanged($event, 'Bottom')">
                                </div>                                
                                <img class="item" src="../../../../assets/icons/Samll_Box.png" alt="Samll_Box">
                                <div class="imageLabel">Bottom and the opposite<br>
                                    sides of the box</div>
                                <span class="attached">{{uploadedImage3}}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-2.5 box">
                                <div class="image-upload" style="height: 44px;cursor: pointer;">
                                    <label for="file-input4">
                                        <img class="plus" *ngIf="!uploadedImage4" src="../../../../assets/icons/icons8-plus-20.png"
                                            alt="plus">
                                    </label>
                                    <img class="minus" *ngIf="uploadedImage4" (click)="removeFile('Damaged')" src="../../../../assets/icons/icons8-minus-20.png"
                                    alt="minus">
                                    <input id="file-input4" type="file" #fileControl 
                                accept="{{ fileTypes }}" (change)="onFileSelectionChanged($event, 'Damaged')">
                                </div>
                                
                                <img class="item" src="../../../../assets/icons/Open_Box_Icon-60.png" alt="label">
                                <div class="imageLabel">Damaged item outside<br> of the box </div>
                                <span class="attached">{{uploadedImage4}}</span>
                            </div>

                            <div class="col-sm-2.5 box">
                                <div class="image-upload" style="height: 44px;cursor: pointer;">
                                    <label for="file-input5">
                                        <img class="plus" *ngIf="!uploadedImage5" src="../../../../assets/icons/icons8-plus-20.png"
                                            alt="plus">
                                    </label>
                                    <img class="minus" *ngIf="uploadedImage5" (click)="removeFile('outer')" src="../../../../assets/icons/icons8-minus-20.png"
                                    alt="minus">
                                    <input id="file-input5" type="file" #fileControl 
                                accept="{{ fileTypes }}" (change)="onFileSelectionChanged($event, 'outer')">
                                </div>
                                
                                <img class="item" src="../../../../assets/icons/Tape-resize.png" alt="Samll_Box">
                                <div class="imageLabel">Packing Materials <br>
                                    OUTER box</div>
                                <span class="attached">{{uploadedImage5}}</span>
                            </div>

                            <div class="col-sm-2.5 box">
                                <div class="image-upload" style="height: 44px;cursor: pointer;">
                                    <label for="file-input">
                                        <img class="plus" *ngIf="!uploadedImage6" src="../../../../assets/icons/icons8-plus-20.png"
                                            alt="plus">
                                    </label>
                                    <img class="minus" *ngIf="uploadedImage6" (click)="removeFile('inner')" src="../../../../assets/icons/icons8-minus-20.png"
                                    alt="minus">
                                    <input id="file-input" type="file" #fileControl [attr.multiple]="isMultiple ? '' : null"
                                accept="{{ fileTypes }}" [disabled]="isDisabled ? '' : null" (change)="onFileSelectionChanged($event, 'inner')">
                                </div>
                              
                                <img class="item" src="../../../../assets/icons/Tape-resize.png" alt="Samll_Box">
                                <div class="imageLabel">Packing Materials<br>
                                    INNER box</div>
                                <span class="attached">{{uploadedImage6}}</span>
                            </div>
                        </div>


                    </div>

                </form>
            </div>

        </div>
    </div>
</div>

<upsc-review-claims [claimsData]="claimentGroup" 
[packageContent]="arrayData"
[shipperData]="shippersGroup"
[receiversData]="receiversGroup"
[merchandiseDetails]="arrayData"
 [additionalInfo]="additionalInformationValue"
 [recipientContacted]="selectedContact"
 [currentLocation]="selectedlocation"
 [locationVal] = "locationValue"
 [contactVal] = "contactValue"

  [assetfileNames]="fileNames"
  [trackingNumberValue]="trackingNumber" (packageReset)="packageReset()" ></upsc-review-claims>




<ng-template #Description1>
    <div class="upsc-address">
        <!---------- desciption 1 ---------->
        <div *ngIf="showDescriptionform1">
            <form class="ups-form" [formGroup]="DescriptionformGroup1">
                <div class="form-body">
                    <div class="form-sections">
                        <div class="form-section">
                            <div class="commodity-option">
                                <span>
                                    <mat-form-field class="searchcommodity">
                                        <mat-select [(value)]="def" [disableOptionCentering]="true"
                                            placeholder="Search for  Commodity"
                                            (selectionChange)="getSubtier($event.value)">
                                            <input style="width:100%; padding:12px 20px"
                                                (keyup)="onKey($event.target.value)">
                                            <mat-option [value]="0">{{selectedValue}}</mat-option>
                                            <mat-option *ngFor="let item of selectedCommodity"
                                                [value]="item">{{item.name}}</mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix class="tickIcon" (click)="onSearch($event)"
                                            class="search">search</mat-icon>
                                    </mat-form-field>
                                </span>

                            </div>
                            <label class="commodityDescription">Commodity Description</label>
                            <span> <img *ngIf="DescriptionformGroup1.controls.description.valid" class="doneIcon"
                                    src="../../../../assets/icons/tick.png" alt="tick"></span>
                            <div class="description">
                                <textarea formControlName="description" matInput required rows="5" cols="70">
                            </textarea>
                            </div>
                            <span style="font-size: 9px;color:red"
                                *ngIf="DescriptionformGroup1.controls.description.touched && !DescriptionformGroup1.controls.description.valid">Commodity
                                Description Required</span>

                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup1.controls.noOfItems.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Number of Items " maxlength="3"
                                            placeholder="Number of Items " formControlName="noOfItems"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup1.controls.noOfItems?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup1.controls.noOfItems?.errors?.required">Number
                                                of Items Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Character: 3</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup1.value.model != '' && DescriptionformGroup1.value.model != null " class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input  matInput type="text" name="Item Model" maxlength="10"
                                            placeholder="Item/Model#" formControlName="model" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Character: 20</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup1.value.serial != ''  && DescriptionformGroup1.value.serial != null" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Serial" maxlength="10" placeholder="Serial#"
                                            formControlName="serial" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                            </div>
                            <div class="secondRow">
                                <div class="columns" style="margin-left: 100px;">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup1.controls.invoiceValue.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Invoice value" maxlength="10"
                                            placeholder="Invoice value" formControlName="invoiceValue"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup1.controls.invoiceValue?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup1.controls.invoiceValue?.errors?.required">Invoice
                                                value Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="columns">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup1.controls.claimedAmount.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="claimed Amount" maxlength="10"
                                            placeholder="Claimed Amount" formControlName="claimedAmount"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup1.controls.claimedAmount?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup1.controls.claimedAmount?.errors?.required">Claimed
                                                Amount Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>


                            <button id="DescriptionformGroup1" class="addButton" (click)="addItemform1($event)">
                                Add another item to this claim
                                <span><img class="addIcon" src="../../../../assets/icons/add_circle.png"
                                        alt="add Icon"></span>
                            </button>
                        </div>
                    </div>
                </div>
                
            </form>
        </div>
        <div class="summary" *ngIf="descriptionSummary1">

            <h3>Commodity : {{commodityValue}}</h3>
            <h3>Commodity Description : {{DescriptionformGroup1.value.description}}</h3>
            <h3>Number of items : {{DescriptionformGroup1.value.noOfItems}}</h3>
            <h3>Item/Model # : {{DescriptionformGroup1.value.model}}</h3>
            <h3>Serial # : {{DescriptionformGroup1.value.serial}}</h3>
            <h3>Invoice Value : {{DescriptionformGroup1.value.invoiceValue}}</h3>
            <h3>Claimed Amount : {{DescriptionformGroup1.value.claimedAmount}}</h3>

        </div>
        <!---------- desciption 2 ---------->
        <div *ngIf="showDescriptionform2">
            <form class="ups-form" [formGroup]="DescriptionformGroup2">
                <div class="form-body">
                    <div class="form-sections">
                        <div class="form-section">
                            <div class="description-label">
                                <h2 class="sectionCounter">Merchandise Description</h2>
                            </div>
                            <div>
                                <div class="details">
                                    <h2 class="content-header detail">Detailed Merchandise Description</h2>
                                    <span> <img class="question-circle" (click)="helpOpen($event)"
                                            src="../../../../assets/icons/question-circle.svg"
                                            alt="questioncircle" /></span>

                                </div>
                            </div>
                            <label class="commodityDescription">Commodity Description</label>
                            <span> <img *ngIf="DescriptionformGroup2.controls.description.valid" class="doneIcon"
                                    src="../../../../assets/icons/tick.png" alt="tick"></span>
                            <div class="description">
                                <textarea formControlName="description" matInput required rows="5" cols="70">
                            </textarea>
                            </div>
                            <span style="font-size: 9px;color:red"
                                *ngIf="DescriptionformGroup2.controls.description.touched && !DescriptionformGroup1.controls.description.valid">Commodity
                                Description Required</span>

                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup2.controls.noOfItems.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Number of Items " maxlength="3"
                                            placeholder="Number of Items " formControlName="noOfItems"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup2.controls.noOfItems?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup2.controls.noOfItems?.errors?.required">Number
                                                of Items Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 3</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup2.value.model != ''" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Item Model" maxlength="10"
                                            placeholder="Item/Model#" formControlName="model" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup2.value.serial != ''" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Serial" maxlength="10" placeholder="Serial#"
                                            formControlName="serial" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                            </div>
                            <div class="secondRow">
                                <div class="columns" style="margin-left: 100px;">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup2.controls.invoiceValue.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Invoice value" maxlength="10"
                                            placeholder="Invoice value" formControlName="invoiceValue"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup2.controls.invoiceValue?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup2.controls.invoiceValue?.errors?.required">Invoice
                                                value Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="columns">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup2.controls.claimedAmount.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="claimed Amount" maxlength="10"
                                            placeholder="Claimed Amount" formControlName="claimedAmount"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup2.controls.claimedAmount?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup2.controls.claimedAmount?.errors?.required">Claimed
                                                Amount Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <button id="DescriptionformGroup2" class="addButton" (click)="addItemform2($event)">
                                Add another item to this claim
                                <span><img class="addIcon" src="../../../../assets/icons/add_circle.png"
                                        alt="add Icon"></span>
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
        <div class="summary" *ngIf="descriptionSummary2">
            <div class="description-label">
                <h2 class="sectionCounter">Merchandise Description</h2>
                <div class="actionIcons">
                    <span><img id="editDetails2" (click)="edit($event)" class="edit-icon" src="/assets/icons/edit.png"
                            alt="edit-icon"></span>
                    <span> <mat-icon id="deleteDetails2" (click)="ondelete($event)"
                            class="delete-icon">delete_forever</mat-icon></span>
                </div>
            </div>
            <h3>Commodity Description: {{DescriptionformGroup2.value.description}}</h3>
            <h3>Number of items : {{DescriptionformGroup2.value.noOfItems}}</h3>
            <h3>Item/Model # : {{DescriptionformGroup2.value.model}}</h3>
            <h3>Serial # : {{DescriptionformGroup2.value.serial}}</h3>
            <h3>Invoice Value : {{DescriptionformGroup2.value.invoiceValue}}</h3>
            <h3>Claimed Amount: {{DescriptionformGroup2.value.claimedAmount}}</h3>

        </div>

        <!---------- desciption 3 ---------->
        <div *ngIf="showDescriptionform3">
            <form class="ups-form" [formGroup]="DescriptionformGroup3">
                <div class="form-body">
                    <div class="form-sections">
                        <div class="form-section">
                            <div class="description-label">
                                <h2 class="sectionCounter">Merchandise Description</h2>

                            </div>
                            <div>
                                <div class="details">
                                    <h2 class="content-header detail">Detailed Merchandise Description</h2>
                                    <span> <img class="question-circle" (click)="helpOpen($event)"
                                            src="../../../../assets/icons/question-circle.svg"
                                            alt="questioncircle" /></span>

                                </div>
                            </div>
                            <label class="commodityDescription">Commodity Description</label>
                            <span> <img *ngIf="DescriptionformGroup3.controls.description.valid" class="doneIcon"
                                    src="../../../../assets/icons/tick.png" alt="tick"></span>
                            <div class="description">
                                <textarea formControlName="description" matInput required rows="5" cols="70">
                            </textarea>
                            </div>
                            <span style="font-size: 9px;color:red"
                                *ngIf="DescriptionformGroup3.controls.description.touched && !DescriptionformGroup1.controls.description.valid">Commodity
                                Description Required</span>

                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup3.controls.noOfItems.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Number of Items " maxlength="3"
                                            placeholder="Number of Items " formControlName="noOfItems"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup3.controls.noOfItems?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup3.controls.noOfItems?.errors?.required">Number
                                                of Items Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 3</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup3.value.model != ''" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Item Model" maxlength="10"
                                            placeholder="Item/Model#" formControlName="model" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup3.value.serial != ''" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Serial" maxlength="10" placeholder="Serial#"
                                            formControlName="serial" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                            </div>
                            <div class="secondRow">
                                <div class="columns" style="margin-left: 100px;">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup3.controls.invoiceValue.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Invoice value" maxlength="10"
                                            placeholder="Invoice value" formControlName="invoiceValue"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup3.controls.invoiceValue?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup3.controls.invoiceValue?.errors?.required">Invoice
                                                value Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="columns">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup3.controls.claimedAmount.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="claimed Amount" maxlength="10"
                                            placeholder="Claimed Amount" formControlName="claimedAmount"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup3.controls.claimedAmount?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup3.controls.claimedAmount?.errors?.required">Claimed
                                                Amount Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <button id="DescriptionformGroup3" class="addButton" (click)="addItemform3($event)">
                                Add another item to this claim
                                <span><img class="addIcon" src="../../../../assets/icons/add_circle.png"
                                        alt="add Icon"></span>
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
        <div class="summary" *ngIf="descriptionSummary3">
            <div class="description-label">
                <h2 class="sectionCounter">Merchandise Description</h2>
                <div class="actionIcons">
                    <span><img id="editDetails3" (click)="edit($event)" class="edit-icon" src="/assets/icons/edit.png"
                            alt="edit-icon"></span>
                    <span> <mat-icon id="deleteDetails3" (click)="ondelete($event)"
                            class="delete-icon">delete_forever</mat-icon></span>
                </div>
            </div>
            <h3>Commodity Description: {{DescriptionformGroup3.value.description}}</h3>
            <h3>Number of items : {{DescriptionformGroup3.value.noOfItems}}</h3>
            <h3>Item/Model # : {{DescriptionformGroup3.value.model}}</h3>
            <h3>Serial # : {{DescriptionformGroup3.value.serial}}</h3>
            <h3>Invoice Value : {{DescriptionformGroup3.value.invoiceValue}}</h3>
            <h3>Claimed Amount: {{DescriptionformGroup3.value.claimedAmount}}</h3>

        </div>

        <!---------- desciption 4 ---------->
        <div *ngIf="showDescriptionform4">
            <form class="ups-form" [formGroup]="DescriptionformGroup4">
                <div class="form-body">
                    <div class="form-sections">
                        <div class="form-section">
                            <div class="description-label">
                                <h2 class="sectionCounter">Merchandise Description</h2>

                            </div>
                            <div>
                                <div class="details">
                                    <h2 class="content-header detail">Detailed Merchandise Description</h2>
                                    <span> <img class="question-circle" (click)="helpOpen($event)"
                                            src="../../../../assets/icons/question-circle.svg"
                                            alt="questioncircle" /></span>

                                </div>
                            </div>
                            <label class="commodityDescription">Commodity Description</label>
                            <span> <img *ngIf="DescriptionformGroup4.controls.description.valid" class="doneIcon"
                                    src="../../../../assets/icons/tick.png" alt="tick"></span>
                            <div class="description">
                                <textarea formControlName="description" matInput required rows="5" cols="70">
                        </textarea>
                            </div>
                            <span style="font-size: 9px;color:red"
                                *ngIf="DescriptionformGroup4.controls.description.touched && !DescriptionformGroup1.controls.description.valid">Commodity
                                Description Required</span>

                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup4.controls.noOfItems.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Number of Items " maxlength="3"
                                            placeholder="Number of Items " formControlName="noOfItems"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup4.controls.noOfItems?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup4.controls.noOfItems?.errors?.required">Number
                                                of Items Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 3</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup4.value.model != ''" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Item Model" maxlength="10"
                                            placeholder="Item/Model#" formControlName="model" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup4.value.serial != ''" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Serial" maxlength="10" placeholder="Serial#"
                                            formControlName="serial" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                            </div>
                            <div class="secondRow">
                                <div class="columns" style="margin-left: 100px;">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup4.controls.invoiceValue.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Invoice value" maxlength="10"
                                            placeholder="Invoice value" formControlName="invoiceValue"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup4.controls.invoiceValue?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup4.controls.invoiceValue?.errors?.required">Invoice
                                                value Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="columns">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup4.controls.claimedAmount.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="claimed Amount" maxlength="10"
                                            placeholder="Claimed Amount" formControlName="claimedAmount"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup4.controls.claimedAmount?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup4.controls.claimedAmount?.errors?.required">Claimed
                                                Amount Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <button id="DescriptionformGroup4" class="addButton" (click)="addItemform4($event)">
                                Add another item to this claim
                                <span><img class="addIcon" src="../../../../assets/icons/add_circle.png"
                                        alt="add Icon"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-footer">
                    <div class="form-actions">
                        <!-- <button mat-raised-button
                        class="form-action"
                        (click)="saveFormData()"
                        [disabled]="!formGroup.valid"
                      >
                    {{'Save'|translate}}
                </button>                   -->

                    </div>
                </div>
            </form>
        </div>
        <div class="summary" *ngIf="descriptionSummary4">
            <div class="description-label">
                <h2 class="sectionCounter">Merchandise Description</h2>
                <div class="actionIcons">
                    <span><img id="editDetails4" (click)="edit($event)" class="edit-icon" src="/assets/icons/edit.png"
                            alt="edit-icon"></span>
                    <span> <mat-icon id="deleteDetails4" (click)="ondelete($event)"
                            class="delete-icon">delete_forever</mat-icon></span>
                </div>
            </div>
            <h3>Commodity Description: {{DescriptionformGroup4.value.description}}</h3>
            <h3>Number of items : {{DescriptionformGroup4.value.noOfItems}}</h3>
            <h3>Item/Model # : {{DescriptionformGroup4.value.model}}</h3>
            <h3>Serial # : {{DescriptionformGroup4.value.serial}}</h3>
            <h3>Invoice Value : {{DescriptionformGroup4.value.invoiceValue}}</h3>
            <h3>Claimed Amount: {{DescriptionformGroup4.value.claimedAmount}}</h3>

        </div>
        <!---------- desciption 5 ---------->
        <div *ngIf="showDescriptionform5">
            <form class="ups-form" [formGroup]="DescriptionformGroup5">
                <div class="form-body">
                    <div class="form-sections">
                        <div class="form-section">
                            <div class="description-label">
                                <h2 class="sectionCounter">Merchandise Description</h2>

                            </div>
                            <div>
                                <div class="details">
                                    <h2 class="content-header detail">Detailed Merchandise Description</h2>
                                    <span> <img class="question-circle" (click)="helpOpen($event)"
                                            src="../../../../assets/icons/question-circle.svg"
                                            alt="questioncircle" /></span>

                                </div>
                            </div>
                            <label class="commodityDescription">Commodity Description</label>
                            <span> <img *ngIf="DescriptionformGroup5.controls.description.valid" class="doneIcon"
                                    src="../../../../assets/icons/tick.png" alt="tick"></span>
                            <div class="description">
                                <textarea formControlName="description" matInput required rows="5" cols="70">
                    </textarea>
                            </div>
                            <span style="font-size: 9px;color:red"
                                *ngIf="DescriptionformGroup5.controls.description.touched && !DescriptionformGroup1.controls.description.valid">Commodity
                                Description Required</span>

                            <div class="row">
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup5.controls.noOfItems.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Number of Items " maxlength="3"
                                            placeholder="Number of Items " formControlName="noOfItems"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup5.controls.noOfItems?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup5.controls.noOfItems?.errors?.required">Number
                                                of Items Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 3</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup5.value.model != ''" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Item Model" maxlength="10"
                                            placeholder="Item/Model#" formControlName="model" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                                <div class="column">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup5.value.serial != ''" class="doneIcon"
                                                src="../../../../assets/icons/tick.png" alt="tick"></span>
                                        <input matInput type="text" name="Serial" maxlength="10" placeholder="Serial#"
                                            formControlName="serial" autocomplete="off">

                                    </mat-form-field>
                                    <h5 class="maxCharcter">Max Charcter: 20</h5>
                                </div>
                            </div>
                            <div class="secondRow">
                                <div class="columns" style="margin-left: 100px;">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup5.controls.invoiceValue.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="Invoice value" maxlength="10"
                                            placeholder="Invoice value" formControlName="invoiceValue"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup5.controls.invoiceValue?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup5.controls.invoiceValue?.errors?.required">Invoice
                                                value Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="columns">
                                    <mat-form-field>
                                        <span> <img *ngIf="DescriptionformGroup5.controls.claimedAmount.valid"
                                                class="doneIcon" src="../../../../assets/icons/tick.png"
                                                alt="tick"></span>
                                        <input matInput required type="text" name="claimed Amount" maxlength="10"
                                            placeholder="Claimed Amount" formControlName="claimedAmount"
                                            autocomplete="off">
                                        <mat-error *ngIf="DescriptionformGroup5.controls.claimedAmount?.errors">
                                            <span
                                                *ngIf="DescriptionformGroup5.controls.claimedAmount?.errors?.required">Claimed
                                                Amount Required</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <button id="DescriptionformGroup5" class="addButton" (click)="addItemform5($event)">
                                Add another item to this claim
                                <span><img class="addIcon" src="../../../../assets/icons/add_circle.png"
                                        alt="add Icon"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-footer">
                    <div class="form-actions">
                        <!-- <button mat-raised-button
                    class="form-action"
                    (click)="saveFormData()"
                    [disabled]="!formGroup.valid"
                  >
                {{'Save'|translate}}
            </button>                   -->

                    </div>
                </div>
            </form>
        </div>
        <div class="summary" *ngIf="descriptionSummary5">
            <div class="description-label">
                <h2 class="sectionCounter">Merchandise Description</h2>
                <div class="actionIcons">
                    <span><img id="editDetails5" (click)="edit($event)" class="edit-icon" src="/assets/icons/edit.png"
                            alt="edit-icon"></span>
                    <span> <mat-icon id="deleteDetails5" (click)="ondelete($event)"
                            class="delete-icon">delete_forever</mat-icon></span>
                </div>
            </div>
            <h3>Commodity Description: {{DescriptionformGroup5.value.description}}</h3>
            <h3>Number of items : {{DescriptionformGroup5.value.noOfItems}}</h3>
            <h3>Item/Model # : {{DescriptionformGroup5.value.model}}</h3>
            <h3>Serial # : {{DescriptionformGroup5.value.serial}}</h3>
            <h3>Invoice Value : {{DescriptionformGroup5.value.invoiceValue}}</h3>
            <h3>Claimed Amount: {{DescriptionformGroup5.value.claimedAmount}}</h3>

        </div>
    </div>
</ng-template>