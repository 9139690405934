<div class="ups-dialog">
    <div class="dialog-header">
        <div class="title">
            <h2> Thank you for submitting your claim.</h2>
        </div>
      

        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>

        <div class="description">
            <div class="section1">
                For reference, your claim number is <span style="font-weight:700">000-00-000000</span>
            </div>

            <div class="section2">
               
                You will receive an email from Parcel Pro shortly, communicating your claim has been received, with
                a<br>
                copy of your claim details. Additionally, your assigned adjuster will email you their contact<br>
                information within 24 hours. Once received, if you need to make changes following this submission,<br>
                please update your adjuster accordingly.<br>
            </div>
            <div class="section3">
                Your adjuster will begin the process of evaluating your file. Please note the Claim Number for
                your<br>
                reference. To view the status of your claim, check the  <a routerLink="" (click)="clickMethod()">
                    Claim Status
                 </a> page. If you have any
                questions<br>
                about the claims process, visit  <a routerLink="/claim-faq">
                    Claim FAQ
                 </a>.<br>
            </div>
            <div class="section4">
                Or, if you would like to connect with us, we are available 7 am - 7 pm CST at 877-225-7625 or email<br>
                us
                at <a href="mailto:parcelproclaims@ups.com">parcelproclaims&#64;ups.com</a>
            </div>



        </div>

    </mat-dialog-content>
</div>