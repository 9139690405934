<div class="file-claim">
    <div class="file-claim-wrapper">

        <div class="content">
            <h1 class="page-title">Claims Dashboard</h1>
            <div class="content-Section">
                <span><img class="icon" src="../../../assets/icons/Claims Activities.png" alt="Activities"></span>
                <h4 class="sub-header">Claims</h4>
                <h5 class="sub-header">Manage your claims activities</h5>
            </div>
            <div class="claimsTabs">
                <div class="items">
                    <div class="itemsSection1">
                        <h3>All Claims </h3>
                        <div class="line-border"></div>
                    </div>

                    <div class="itemsSection2">
                        | <a class="faq" routerLink="/claim-faq">Claims FAQ</a> |
                        <div class="line-border"></div>
                    </div>

                </div>
                <div class="itemsSection3">
                    <a class="fileClaim" routerLink="/claim-faq"> File a Claim</a>
                    <div class="line-border"></div>
                </div>



            </div>
            <div class="statusInfo">
                <h3 class="statusContent">Please click the download icon to view your complete claims history</h3>
            </div>
            <div class="panel">
                <div class="searchLabel">
                    <h2 class="advanceSearch">Advance Search </h2>
                    <span><mat-icon (click)="showAdvanceSearch()"
                            class="advanceIcon">keyboard_arrow_down</mat-icon></span>
                </div>


                <div *ngIf="showAdvancePanel" class="fileClaimPanel">
                    <div class="row section">
                        <div class="col-md-3 amountPanel">
                            <div class="labelSection">
                                <div *ngIf="selectedRange" class="rangeVal">
                                    <div class="selectedRange">
                                        <div class="rangeValue">
                                            ${{amountRangeFormGroup.value.amountStart}} -
                                            ${{amountRangeFormGroup.value.amountEnd}}
                                            <span><mat-icon class="cancelIcon"
                                                    (click)="onAmountRangeClear()">cancel</mat-icon></span>
                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="selectedStatus" class="statusVal">
                                    <div class="selectedststus">
                                        <div class="statusValue">
                                            {{claimStatusFormGroup.value.claimStatus}}
                                            <span><mat-icon class="cancelIcon"
                                                    (click)="onStatusClear()">cancel</mat-icon></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="labelClaim" *ngIf="amountSubmitted == false">
                                <div class="amountLabel">
                                    Filed Claim Amount
                                </div>
                                <span><mat-icon (click)="openRangePanel()">keyboard_arrow_down</mat-icon></span>
                                <div class="underline">

                                </div>
                            </div>

                            <div class="rangePanel" *ngIf="showRangePanel">
                                <h1 class="header">Amount Range</h1>
                                <form [formGroup]="amountRangeFormGroup">
                                    <div class="form-section">
                                        <span class="amountStart">$</span>
                                        <mat-form-field>
                                            <input matInput required type="text" name="start"
                                                formControlName="amountStart" autocomplete="off" [(ngModel)]="Minimum"
                                                (ngModelChange)="onChange($event)">
                                            <mat-error *ngIf="amountRangeFormGroup.controls.amountStart?.errors">
                                                <span
                                                    *ngIf="amountRangeFormGroup.controls.amountStart?.errors?.required">Minimum
                                                    is
                                                    required</span>
                                            </mat-error>
                                        </mat-form-field>
                                        <span class="hypen">-</span>
                                        <span class="amountStart">$</span>
                                        <mat-form-field>
                                            <input matInput required type="text" name="end" formControlName="amountEnd"
                                                autocomplete="off" [(ngModel)]="Maximum"
                                                (ngModelChange)="onChange($event)">
                                            <mat-error *ngIf="amountRangeFormGroup.controls.amountEnd?.errors">
                                                <span
                                                    *ngIf="amountRangeFormGroup.controls.amountEnd?.errors?.required">Maximum
                                                    is
                                                    Required</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <h5 *ngIf="false">
                                        Minimum can’t be greater than the maximum</h5>
                                    <div class="actionPanel">
                                        <button type="submit" mat-raised-button class="submitButton"
                                            (click)="onAmountRangeSubmit($event, amountRangeFormGroup.value)">
                                            Done
                                        </button>
                                        <button type="button" mat-raised-button class="alternative-button cancelButton"
                                            (click)="onAmountRangeCancel($event)">{{'Cancel'|translate}}</button>

                                    </div>
                                </form>
                            </div>

                        </div>
                        <div class="col-md-3 claimStatusPanel">

                            <form *ngIf="showStatusPanel" [formGroup]="claimStatusFormGroup">
                                <mat-form-field class="claimSelect">
                                    <mat-label>Claim status</mat-label>
                                    <mat-select formControlName="claimStatus" panelClass="statuspanel" multiple>
                                        <mat-option *ngFor="let item of claimStatusList" [value]="item.valeur1">
                                            {{ item.valeur1 }}
                                        </mat-option>
                                        <mat-error *ngIf="statusError" style="margin-left:1em">
                                            <span>
                                                Please select at least one claim status
                                            </span>
                                        </mat-error>
                                        <div class="actionPanel" style="height: 34px;">
                                            <button type="submit" style="float: left; font-size: 12px;
                                        margin-left: 1.5em;width: 59.05px;height: 21.1px;  border-radius: 3px;"
                                                (click)="onStatusDone($event, claimStatusFormGroup.value)"
                                                mat-raised-button class="submitButton">
                                                Done
                                            </button>
                                            <button type="button" style="float: right;font-size: 12px;
                                        margin-right: 1em;width: 59.05px;height: 21.1px;  border-radius: 3px;"
                                                (click)="onStatusCancel($event)" mat-raised-button
                                                class="alternative-button cancelButton">{{'Cancel'|translate}}</button>
                                        </div>

                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="col-md-5 actionButtons">
                            <div class="actionPanel">
                                <button type="button" mat-raised-button class="searchButton" (click)="onSearch($event)">
                                    Search
                                </button>
                                <button type="button" mat-raised-button class="alternative-button resetButton"
                                    (click)="onReset($event)">Reset</button>

                            </div>
                        </div>
                    </div>



                    <!-- <div class="row row-bottom-3">
                        <div class="col-md-3 col-sm-4 col-xs-12 ">
                            <span class="show grey-font text-uppercase " style="font-size:14px;" > {{'Carrier'|translate}} </span>
                            <span style="color:#573616; font-size:16px;font-weight:normal;">  {{trackingShipmentDetail?.ReportedPackage?.Carrier}} </span>
                        </div> -->
                </div>

            </div>


            <div class="advancePanel content-actions">
                <div class="left-actions">
                    <form [formGroup]="dateRangeFormGroup" class="action">
                        <mat-form-field>
                            <mat-select placeholder="{{ 'View' | translate }}" formControlName="dayCount">
                                <mat-option [value]="dateRangeAll">{{'All'|translate}}</mat-option>
                                <mat-option *ngFor="let dateRange of predefinedDayRanges" [value]="dateRange">
                                    {{ 'lastX' | translate:{ x: dateRange } }}
                                    {{ (dateRange > 1 ? 'days' : 'day') | translate }}
                                </mat-option>
                                <mat-option [value]="dateRangeCustom">{{'Custom'|translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                    <form [formGroup]="customDateRangeFormGroup">
                        <mat-form-field [floatLabel]="'always'" class="date-input action">
                            <input matInput [matDatepicker]="pickerStart" placeholder="{{'From'|translate}}"
                                formControlName="startDate">
                            <span class="formatted-date" (click)="pickerStart.open()">{{ formattedStartDate }}</span>
                            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field [floatLabel]="'always'" class="date-input action">
                            <input matInput [matDatepicker]="pickerEnd" placeholder="{{'To'|translate}}"
                                formControlName="endDate">
                            <span class="formatted-date" (click)="pickerEnd.open()">{{ formattedEndDate }}</span>
                            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                        </mat-form-field>
                    </form>
                    <form [formGroup]="searchFormGroup" class="action">
                        <mat-form-field class="searchBy">
                            <mat-select placeholder="Search By" formControlName="searchBy">
                                <mat-option *ngFor="let search of searchByOptions" [value]="search">
                                    {{ search }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="searchText">
                            <input matInput type="text" placeholder="Search" formControlName="keyword">
                            <mat-icon matSuffix (click)="onSearchSubmit($event, searchFormGroup.value)"
                                class="search">search</mat-icon>
                        </mat-form-field>
                        <span><img class="downloadIcon" src="../../../assets/icons/downloadImage.png"
                                alt="downloadImage"></span>
                    </form>

                </div>
            </div>
            <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="stripe"
                    [hidden]="!displayedShipments">
                    <thead>
                        <tr>
                            <th class="all trigger-column"></th>
                            <th>
                                Claim Number
                            </th>
                            <th>
                                File date
                            </th>
                            <th>
                                Total Paid Amount
                            </th>
                            <th>{{'TrackingNumber'|translate}}</th>
                            <th>
                                Receiver
                            </th>
                            <th>
                                Claim Status
                            </th>
                            <th>
                                Add Document
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody #tb>
                        <tr *ngFor="let item of displayedShipments | paginate:paginate">
                            <td class="trigger-cell"></td>
                            <td> <a href="javascript:;">123-123-28282</a></td>
                            <td>{{item?.ShipDate | date : 'MM-dd-yyyy' }}</td>
                            <td>
                                <strong>{{ item?.InsuredValue | userLocaleNumber:user }} {{ userCurrencyCode }}</strong>
                            </td>
                            <td>
                                <span [class.is-commercial-invoice]="item.IsCommercialInvoice">{{ item?.TrackingNumber
                                    }}</span>
                            </td>
                            <td>Testing</td>
                            <td class="status">
                                <div class="statusLabel" [ngStyle]="{'background-color': getRowColor(item),
                                  'width': getWidth(item),'text-align': 'center', 'border-radius': '8px'}">
                                    <div class="Label" style="color:white">
                                        {{item?.Status | claimsStatus}}
                                    </div>
                                </div>
                            </td>
                            <td><img class="documentIcon" src="../../../assets/icons/add-document.png"
                                    alt="documentIcon" (click)="addDocument(item)"></td>
                            <td>
                                <div class="actions">
                                    <button type="button" mat-raised-button *ngIf="true">
                                        Withdraw Claim
                                    </button>
                                    <!--                                
                                <button type="button"
                                        mat-raised-button
                                        class="alternative-button"
                                        *ngIf="item?.Status==4 || item?.Status==10 || item?.Status==0 || item?.Status==1">
                                   Withdraw Claim
                                </button>  -->
                                </div>
                            </td>
                            <td><img class="messageIcon" src="../../../assets/icons/message.png" alt="messageIcon"></td>

                        </tr>
                    </tbody>
                </table>


            </div>

            <div *ngIf="uploadShow" id="uploadedFile" class="uploadFiles">
                <h3 class="uploadHeader">Upload Files</h3>
                <div class="separator"></div>
                <h5 class="label">You may upload up to 10 attachments. Each file cannot exceed 5MB. File types
                    such as jpg, pdf, docx, and xls are accepted. Don’t worry if <br> you don’t have all the
                    documents ready right now. Your adjuster will let you know if additional documents are
                    needed.</h5>
                <h5 class="documentLabel">Documents that are likely to be needed:
                </h5>

                <div class="file-selector-wrapper">
                    <span>
                        <h3 class="label">Original Invoice</h3>
                    </span>
                    <upsc-file-selector id="invoice" [label]="'Upload'" [clearFileControl]="true"
                        (fileSelectionChanged)="uploadFiles($event,'invoice')"></upsc-file-selector>
                    <div class="file-name form-action" *ngIf="uploadedFile1">{{ uploadedFile1 }}</div>

                    <span>
                        <img class="delete-icon" *ngIf="uploadedFile1" (click)="removeFile('invoice')"
                            src="../../../../assets/icons/delete_Icon.png" alt="FedEx">
                    </span>

                </div>
                <div class="file-selector-wrapper">

                    <span>
                        <h3 class="label">Photograph</h3>
                    </span>
                    <upsc-file-selector id="photograph" [label]="'Upload'" [clearFileControl]="true"
                        (fileSelectionChanged)="uploadFiles($event,'photograph')"></upsc-file-selector>
                    <div class="file-name form-action" *ngIf="uploadedFile2">{{ uploadedFile2 }}</div>

                    <span>
                        <img class="delete-icon" *ngIf="uploadedFile2" (click)="removeFile('photograph')"
                            src="../../../../assets/icons/delete_Icon.png" alt="FedEx">
                    </span>

                </div>

                <div class="file-selector-wrapper">
                    <span>
                        <h3 class="label">Repair Estimate</h3>
                    </span>
                    <upsc-file-selector id="estimate" [label]="'Upload'" [clearFileControl]="true"
                        (fileSelectionChanged)="uploadFiles($event,'estimate')"></upsc-file-selector>
                    <div class="file-name form-action" *ngIf="uploadedFile3">{{ uploadedFile3 }}</div>

                    <span>
                        <img class="delete-icon" *ngIf="uploadedFile3" (click)="removeFile('estimate')"
                            src="../../../../assets/icons/delete_Icon.png" alt="FedEx">
                    </span>
                </div>
                <div class="file-selector-wrapper">
                    <span>
                        <h3 class="label">Other</h3>
                    </span>
                    <upsc-file-selector id="other" [label]="'Upload'" [clearFileControl]="true"
                        (fileSelectionChanged)="uploadFiles($event,'other')"></upsc-file-selector>
                    <div class="file-name form-action" *ngIf="uploadedFile4">{{ uploadedFile4 }}</div>

                    <span>
                        <img class="delete-icon" *ngIf="uploadedFile4" (click)="removeFile('other')"
                            src="../../../../assets/icons/delete_Icon.png" alt="delete">
                    </span>
                </div>
                <h3 class="photoLabel">Take 6 photos of your package (as shown below)</h3>
                <div class="row">
                    <div class="col-sm-2.5 box">

                        <div class="image-upload" style="height: 44px;cursor: pointer;">
                            <label for="file-input1">
                                <img class="plus" *ngIf="!uploadedImage1"
                                    src="../../../../assets/icons/icons8-plus-20.png" alt="plus">
                            </label>
                            <img class="minus" *ngIf="uploadedImage1" (click)="removeFile('label')"
                                src="../../../../assets/icons/icons8-minus-20.png" alt="minus">
                            <input id="file-input1" type="file" #fileControl1 accept="{{ fileTypes }}"
                                (change)="onFileSelectionChanged($event, 'label')">
                        </div>

                        <img class="item" src="../../../../assets/icons/label_1.png" alt="label">
                        <div class="imageLabel">Shipping label with <br>tracking number<br> ( jpg or pdf)</div>
                        <span class="attached" style="margin-top: 43px;">{{uploadedImage1}}</span>
                    </div>

                    <div class="col-sm-2.5 box">
                        <div class="image-upload" style="height: 44px;cursor: pointer;">
                            <label for="file-input2">
                                <img class="plus" *ngIf="!uploadedImage2"
                                    src="../../../../assets/icons/icons8-plus-20.png" alt="plus">
                            </label>
                            <img class="minus" *ngIf="uploadedImage2" (click)="removeFile('sides')"
                                src="../../../../assets/icons/icons8-minus-20.png" alt="minus">
                            <input id="file-input2" type="file" #fileControl2 accept="{{ fileTypes }}"
                                (change)="onFileSelectionChanged($event, 'sides')">
                        </div>

                        <img class="item" src="../../../../assets/icons/Samll_Box.png" alt="Samll_Box">
                        <div class="imageLabel">Top and 2 sides of<br>
                            the box</div>
                        <span class="attached">{{uploadedImage2}}</span>
                    </div>

                    <div class="col-sm-2.5 box">
                        <div class="image-upload" style="height: 44px;cursor: pointer;">
                            <label for="file-input3">
                                <img class="plus" *ngIf="!uploadedImage3"
                                    src="../../../../assets/icons/icons8-plus-20.png" alt="plus">
                            </label>
                            <img class="minus" *ngIf="uploadedImage3" (click)="removeFile('Bottom')"
                                src="../../../../assets/icons/icons8-minus-20.png" alt="minus">
                            <input id="file-input3" type="file" #fileControl accept="{{ fileTypes }}"
                                (change)="onFileSelectionChanged($event, 'Bottom')">
                        </div>
                        <img class="item" src="../../../../assets/icons/Samll_Box.png" alt="Samll_Box">
                        <div class="imageLabel">Bottom and the opposite<br>
                            sides of the box</div>
                        <span class="attached">{{uploadedImage3}}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-2.5 box">
                        <div class="image-upload" style="height: 44px;cursor: pointer;">
                            <label for="file-input4">
                                <img class="plus" *ngIf="!uploadedImage4"
                                    src="../../../../assets/icons/icons8-plus-20.png" alt="plus">
                            </label>
                            <img class="minus" *ngIf="uploadedImage4" (click)="removeFile('Damaged')"
                                src="../../../../assets/icons/icons8-minus-20.png" alt="minus">
                            <input id="file-input4" type="file" #fileControl accept="{{ fileTypes }}"
                                (change)="onFileSelectionChanged($event, 'Damaged')">
                        </div>

                        <img class="item" src="../../../../assets/icons/Open_Box_Icon-60.png" alt="label">
                        <div class="imageLabel">Damaged item outside<br> of the box </div>
                        <span class="attached">{{uploadedImage4}}</span>
                    </div>

                    <div class="col-sm-2.5 box">
                        <div class="image-upload" style="height: 44px;cursor: pointer;">
                            <label for="file-input5">
                                <img class="plus" *ngIf="!uploadedImage5"
                                    src="../../../../assets/icons/icons8-plus-20.png" alt="plus">
                            </label>
                            <img class="minus" *ngIf="uploadedImage5" (click)="removeFile('outer')"
                                src="../../../../assets/icons/icons8-minus-20.png" alt="minus">
                            <input id="file-input5" type="file" #fileControl accept="{{ fileTypes }}"
                                (change)="onFileSelectionChanged($event, 'outer')">
                        </div>

                        <img class="item" src="../../../../assets/icons/Tape-resize.png" alt="Samll_Box">
                        <div class="imageLabel">Packing Materials <br>
                            OUTER box</div>
                        <span class="attached">{{uploadedImage5}}</span>
                    </div>

                    <div class="col-sm-2.5 box">
                        <div class="image-upload" style="height: 44px;cursor: pointer;">
                            <label for="file-input">
                                <img class="plus" *ngIf="!uploadedImage6"
                                    src="../../../../assets/icons/icons8-plus-20.png" alt="plus">
                            </label>
                            <img class="minus" *ngIf="uploadedImage6" (click)="removeFile('inner')"
                                src="../../../../assets/icons/icons8-minus-20.png" alt="minus">
                            <input id="file-input" type="file" #fileControl [attr.multiple]="isMultiple ? '' : null"
                                accept="{{ fileTypes }}" [disabled]="isDisabled ? '' : null"
                                (change)="onFileSelectionChanged($event, 'inner')">
                        </div>

                        <img class="item" src="../../../../assets/icons/Tape-resize.png" alt="Samll_Box">
                        <div class="imageLabel">Packing Materials<br>
                            INNER box</div>
                        <span class="attached">{{uploadedImage6}}</span>
                    </div>
                </div>
                <div class="note">
                    <h3 class="noteLabel">Please Note:</h3>
                    <div class="noteContent">Please allow at least two business days for the uploaded documents to
                        process.</div>
                </div>


            </div>
        </div>
    </div>