import { Component, OnInit, ViewChild, OnChanges, SimpleChange  } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ZipCodeService } from 'app/shared/services/zip-code/zip-code.service';
import { ValidationService } from 'app/shared/services/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { debounceTime } from 'rxjs/operators';
import { NotificationType } from '../../shared/models/notification-type';
import { ClaimsService } from '../../claims/services/claims.service'
@Component({
  selector: 'upsc-file-claim',
  templateUrl: './file-claim.component.html',
  styleUrls: ['./file-claim.component.scss']
})
export class FileClaimComponent implements  OnInit{

  searchFormGroup: UntypedFormGroup;
  public claimentformGroup: UntypedFormGroup;//shippersformGroup
  public shippersformGroup: UntypedFormGroup;
  public receiverformGroup: UntypedFormGroup;
  isOpen = false;
  showPopup = false;
  editable = false;
  deliveryStatus = 'Delivered on'
  TrackingNumber: string = '';
  numbers: string[] = ['1Z2E74F90292043777', '1Z2E74F90292043777', '1Z2E74F90292046722'];
  selectedForm: any;
  claimentFormValue: any;
  shippersFormValue: any;
  receiversFormValue: any;
  constructor(private formBuilder: UntypedFormBuilder, private zipcodeservice: ZipCodeService,
    private validationService: ValidationService,
    private translateService: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    private claimsService: ClaimsService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.searchFormGroup = this.formBuilder.group({
      keyword: [''],
    });
    this.initForm()

  }
  initForm() {//shippersformGroup
    this.claimentformGroup = this.formBuilder.group({
      accountNumber: ['831661'],
      companyName: ['PPI'],
      firstName: ['Saritha', Validators.required],
      lastName: ['Pulla', Validators.required],
      streetAddress: ['1867 Western Way', Validators.required],
      apartmentSuite: ['uu'],
      country: ['US', Validators.required],
      zip: ['90501'],
      city: ['Torrance', Validators.required],
      state: ['CA', Validators.required],
      telNum: ['3103288484', Validators.compose([Validators.required, this.validationService.isInteger()])],
      email: ['test@parcelpro.com', Validators.compose([Validators.required, this.validationService.emailFormatValidator()])],
    });//receiverformGroup,shippersformGroup
    this.shippersformGroup = this.formBuilder.group({
      accountNumber: [''],
      companyName: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      streetAddress: ['', Validators.required],
      apartmentSuite: [''],
      country: ['US', Validators.required],
      zip: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      telNum: ['', Validators.compose([Validators.required, this.validationService.isInteger()])],
      email: ['', Validators.compose([Validators.required, this.validationService.emailFormatValidator()])],
    });
    this.receiverformGroup = this.formBuilder.group({
      accountNumber: [''],
      companyName: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      streetAddress: ['', Validators.required],
      apartmentSuite: [''],
      country: ['US', Validators.required],
      zip: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      telNum: ['', Validators.compose([Validators.required, this.validationService.isInteger()])],
      email: ['', Validators.compose([Validators.required, this.validationService.emailFormatValidator()])],
    });


    this.onValueChanges();
    //this.notifyForm.emit(this.formGroup);

  }
  
  public formvalid() {
      
    var claimsFormvalid = this.shippersformGroup.valid && this.receiverformGroup.valid && this.claimentformGroup.valid && this.TrackingNumber != '';
   
    return claimsFormvalid;
  }
 
 
 

  onValueChanges() {

    this.claimentformGroup.controls.zip.valueChanges
      .pipe(debounceTime(2000)).subscribe((value) => {
        if (value)
          this.selectedForm = this.claimentformGroup;
        this.getAddressbyZipCode(value, this.selectedForm);
      });
    this.shippersformGroup.controls.zip.valueChanges
      .pipe(debounceTime(2000)).subscribe((value) => {
        if (value)
          this.selectedForm = this.shippersformGroup;
        this.getAddressbyZipCode(value, this.selectedForm);
      });
    this.receiverformGroup.controls.zip.valueChanges
      .pipe(debounceTime(2000)).subscribe((value) => {
        if (value)
          this.selectedForm = this.receiverformGroup;
        this.getAddressbyZipCode(value, this.selectedForm);
      });
    this.claimentformGroup.valueChanges.subscribe(() => {

      if (true) {
          this.claimentFormValue = this.claimentformGroup.value
         // this.formvalid();
        

      }
    })
    this.shippersformGroup.valueChanges.subscribe(() => {

      if (true) {     
          this.shippersFormValue = this.shippersformGroup.value
//          this.formvalid();
              
      
      }
    })
    this.receiverformGroup.valueChanges.subscribe(() => {

      if (true) {
          this.receiversFormValue = this.receiverformGroup.value
        //this.formvalid();
          
       

      }
    })
   
  }
  resetForms() {

    console.log('vivekkkk start!!!!!!!!!!!!!')
  
    this.claimentformGroup.reset();
    this.shippersformGroup.reset();
    this.receiverformGroup.reset();
    this.searchFormGroup.reset();
    this.TrackingNumber = '';
    console.log('vivekkkk end!!!!!!!!!!!!!');
  }



  getAddressbyZipCode(event, form) {
    if (!['US', 'CA'].includes(form?.controls?.country?.value)) {
      return;
    }

    this.zipcodeservice.getAddressByZipCode(event).subscribe(
      (address) => {
        if (address) {
          form.controls.city.setValue(address.City.trim());
          form.controls.state.setValue(address.State.trim());
        }
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('ErrorLoadingAddress_msg'),
          NotificationType.ERROR);
      });
  }

  public popup() {
    this.isOpen = !this.isOpen;
    this.showPopup = !this.showPopup;
  }
  public onSearchSubmit(event, form) {
    this.TrackingNumber = '';
    console.log('onSearchSubmit', event, form)
    console.log('onSearchSubmit', event, form.value)
    event.preventDefault();
    // const params = {};
    // if (pageIndex) {
    //   params['page'] = 1;
    // }

    // if (pageSize) {
    //   params['q'] = ;
    // }

    // const endpoint = SafeUrlAssembler()
    //   .template(this.apiEndpointService.getEndpoint('getAddressBookPage'))
    //   .query(params)
    //   .toString();
    // return this.httpClientService.get<IContact[]>(endpoint);
  

    //this.searchShipments(form.keyword, '1');
  }
  private searchShipments(keyword: string, index: string) {
    console.log('searchShipments', keyword, index)

    console.log('TrackignNumber length', this.TrackingNumber.length)
  }

}
